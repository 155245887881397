import { Page } from "../../types/page";
import { FileOrPage } from "../../types/fileorpage";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export const PagesCheckAllBox = ({
  pages,
  setFilesOrPagesEdit,
}: {
  pages: Page[];
  setFilesOrPagesEdit: React.Dispatch<React.SetStateAction<FileOrPage[]>>;
}) => {
  const { t } = useTranslation();

  // 全てのページをfilesOrPagesEditに追加する・削除する
  const onChangeAllCheckBox = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setFilesOrPagesEdit(pages.map((page) => page as FileOrPage));
      } else {
        setFilesOrPagesEdit([]);
      }
    },
    [pages, setFilesOrPagesEdit]
  );

  return (
    <div className="flex items-center align-middle hidden sm:hidden md:block">
      <input id="checkall" type="checkbox" className="checkbox checkbox-primary ml-4" onChange={onChangeAllCheckBox} />
      <label className="ml-2" htmlFor="checkall">
        {t("pagesearch.select_all")}
      </label>
    </div>
  );
};
