/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Project {
  name?: string;
  gid?: number;
  last_modify?: string;
  n_files?: number;
  owner?: string;
  pages?: number;
  size?: number;
  comment?: string;
}

export type ProjectList = Project[];

export interface File {
  id?: string;
  folder?: string;
  file?: string;
  pages?: number;
  size?: string;
  modified_at?: number;
  type?: string;
  status?: string;
  key?: string;
  comment?: string;
  tag?: string[];
}

export interface FileList {
  total?: number;
  files?: File[];
}

export type FileCheckResponse = boolean;

/** @format binary */
export type ImageFile = File;

export type FolderList = string[];

export interface TagList {
  tag?: string;
}

export interface SinglePage {
  url?: string;
}

export interface FileUploadResponse {
  url?: string;
  overlapped?: boolean;
}

export interface Page {
  content?: string;
  comment?: string;
  file?: string;
  folder?: string;
  id?: string;
  key?: string;
  modified_at?: string;
  p_num?: number;
  page?: string;
  thumb?: string;
  tag?: string[];
}

export interface PagesList {
  total_value?: number;
  pages?: Page[];
}

export interface PagesRecommendList {
  pages?: Page[];
}

export interface Chat {
  answer?: string;
  pages?: Page[];
}

export interface PostGeneralResponse {
  message: string;
  detail?: string;
  status?: number;
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "http://localhost:3020" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Essentia AI
 * @version 1.0
 * @baseUrl http://localhost:3020
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  projects = {
    /**
     * No description
     *
     * @tags projects
     * @name ProjectsFetch
     * @summary Fetch all projects
     * @request POST:/projects
     * @secure
     */
    projectsFetch: (params: RequestParams = {}) =>
      this.request<ProjectList | PostGeneralResponse, PostGeneralResponse>({
        path: `/projects`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags projects
     * @name ProjectsCreate
     * @summary Create a project
     * @request POST:/projects/create
     * @secure
     */
    projectsCreate: (
      data: {
        name: string;
        comment: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PostGeneralResponse, PostGeneralResponse>({
        path: `/projects/create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags projects
     * @name ProjectsEdit
     * @summary Edit a project
     * @request POST:/projects/edit
     * @secure
     */
    projectsEdit: (
      data: {
        project: {
          gid?: number;
        };
        name: string;
        comment: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PostGeneralResponse, PostGeneralResponse>({
        path: `/projects/edit`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  files = {
    /**
     * No description
     *
     * @tags files
     * @name FilesFetch
     * @summary Fetch files
     * @request POST:/files
     * @secure
     */
    filesFetch: (
      data: {
        gid: number;
        folder: string;
        sortbool: boolean;
        sortby: string;
        from: number;
        deleted: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<FileList | PostGeneralResponse, PostGeneralResponse>({
        path: `/files`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags files
     * @name FilesDelete
     * @summary Delete a file
     * @request DELETE:/files
     * @secure
     */
    filesDelete: (
      data: {
        gid: number;
        folders: string[];
        keys: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<PostGeneralResponse, PostGeneralResponse>({
        path: `/files`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags files
     * @name FilesRename
     * @summary Rename a file
     * @request POST:/files/rename
     * @secure
     */
    filesRename: (
      data: {
        gid: number;
        key: string;
        folder: string;
        file: string;
        newname: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PostGeneralResponse, PostGeneralResponse>({
        path: `/files/rename`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags files
     * @name FilesMove
     * @summary Move files
     * @request POST:/files/move
     * @secure
     */
    filesMove: (
      data: {
        gid: number;
        keys: string[];
        folders: string[];
        newfolder: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PostGeneralResponse, PostGeneralResponse>({
        path: `/files/move`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags files
     * @name FilesSearch
     * @summary Search files
     * @request POST:/files/search
     * @secure
     */
    filesSearch: (
      data: {
        gid: number;
        term: string;
        folder: string;
        sortbool: boolean;
        sortby: string;
        from: number;
        deleted: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<FileList | PostGeneralResponse, PostGeneralResponse>({
        path: `/files/search`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags files
     * @name FilesCheck
     * @summary Check files
     * @request POST:/files/check
     * @secure
     */
    filesCheck: (
      data: {
        gid: number;
        files: {
          file?: string;
          size?: number;
          type?: string;
        }[];
      },
      params: RequestParams = {},
    ) =>
      this.request<FileCheckResponse | PostGeneralResponse, PostGeneralResponse>({
        path: `/files/check`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags files
     * @name FilesUpload
     * @summary Upload a file
     * @request POST:/files/upload
     * @secure
     */
    filesUpload: (
      data: {
        gid: number;
        file: {
          file?: string;
          size?: number;
          type?: string;
        };
        replace: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<FileUploadResponse | PostGeneralResponse, PostGeneralResponse>({
        path: `/files/upload`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags files
     * @name FilesCommentsUpdate
     * @summary Update file comment
     * @request POST:/files/comments/update
     * @secure
     */
    filesCommentsUpdate: (
      data: {
        gid: number;
        ids: string[];
        comment: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PostGeneralResponse, PostGeneralResponse>({
        path: `/files/comments/update`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags files
     * @name FilesSinglePageFetch
     * @summary Fetch PDF URL
     * @request POST:/files/single_page
     * @secure
     */
    filesSinglePageFetch: (
      data: {
        key: string;
        page?: number;
        filetype?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SinglePage | PostGeneralResponse, PostGeneralResponse>({
        path: `/files/single_page`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags files
     * @name FilesHighlightImageFileFetch
     * @summary Fetch highlighted image file
     * @request POST:/files/highlight_image_file
     * @secure
     */
    filesHighlightImageFileFetch: (
      data: {
        gid: number;
        num: number;
        filetype?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ImageFile | PostGeneralResponse, PostGeneralResponse>({
        path: `/files/highlight_image_file`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "blob",
        ...params,
      }),
  };
  folders = {
    /**
     * No description
     *
     * @tags folders
     * @name FoldersFetch
     * @summary Fetch folders
     * @request POST:/folders
     * @secure
     */
    foldersFetch: (
      data: {
        gid: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<FolderList | PostGeneralResponse, PostGeneralResponse>({
        path: `/folders`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  tags = {
    /**
     * No description
     *
     * @tags tags
     * @name TagsCreate
     * @summary Create a tag
     * @request POST:/tags/create
     * @secure
     */
    tagsCreate: (
      data: {
        gid: number;
        tag: string;
        group?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<TagList | PostGeneralResponse, PostGeneralResponse>({
        path: `/tags/create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags tags
     * @name TagsFetch
     * @summary Fetch tags
     * @request POST:/tags
     * @secure
     */
    tagsFetch: (
      data: {
        gid: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<TagList | PostGeneralResponse, PostGeneralResponse>({
        path: `/tags`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags tags
     * @name TagsUpdate
     * @summary Update tags
     * @request POST:/tags/update
     * @secure
     */
    tagsUpdate: (
      data: {
        gid: number;
        tags_add?: string[];
        tags_remove?: string[];
        /** @example false */
        file_bool: boolean;
        ids?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<TagList | PostGeneralResponse, PostGeneralResponse>({
        path: `/tags/update`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  pages = {
    /**
     * No description
     *
     * @tags pages
     * @name PagesSearch
     * @summary Search pages
     * @request POST:/pages/search
     * @secure
     */
    pagesSearch: (
      data: {
        folder: string;
        from: number;
        fuzziness: boolean;
        gid: number;
        page_end: number;
        page_start: number;
        sortbool: boolean;
        sortby: string;
        sub: string;
        tags: string[];
        term: string;
        term_field: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PagesList | PostGeneralResponse, PostGeneralResponse>({
        path: `/pages/search`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags pages
     * @name PagesRecommend
     * @summary Recommend pages
     * @request POST:/pages/recommend
     * @secure
     */
    pagesRecommend: (
      data: {
        gid: number;
        review: boolean;
        subject: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PagesRecommendList | PostGeneralResponse, PostGeneralResponse>({
        path: `/pages/recommend`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags pages
     * @name PagesHighlight
     * @summary Highlight Pages
     * @request POST:/pages/highlight
     * @secure
     */
    pagesHighlight: (
      data: {
        gid: number;
        question: string;
        tags?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<PagesList | PostGeneralResponse, PostGeneralResponse>({
        path: `/pages/highlight`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  chat = {
    /**
     * No description
     *
     * @tags chat
     * @name ChatAsk
     * @summary Chat
     * @request POST:/chat
     * @secure
     */
    chatAsk: (
      data: {
        gid: number;
        question: string;
        lang?: string;
        tags?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<Chat | PostGeneralResponse, PostGeneralResponse>({
        path: `/chat`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
}
