import React from "react";
import { ChatMessage } from "../../types/aichat";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/cjs/styles/prism";
import { highlight } from "../../axios/highlight";
import { highlight_image } from "../../axios/highlight_image";
import { useCallback, useState } from "react";
import { Page } from "../../types/page";
import { Project } from "../../types/project";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { logAnalyticsEvent } from "../../lib/firebase";

export const EssaiChatAIFukidashi = ({
  isChatLoading,
  setIsChatLoading,
  setIsPageLoading,
  message,
  onSendMessage,
  setTotalPages,
  setPages,
  tagsSelected,
}: {
  isChatLoading: boolean;
  setIsChatLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsPageLoading: React.Dispatch<React.SetStateAction<boolean>>;
  message: ChatMessage;
  onSendMessage: (message: string) => void;
  setTotalPages: React.Dispatch<React.SetStateAction<number>>;
  setPages: React.Dispatch<React.SetStateAction<Page[]>>;
  tagsSelected: string[];
}) => {
  // AI側の吹き出し
  // isCandidateがtrueの場合は、クリックでユーザーメッセージとして送信する

  const [highlightedStr, setHighlightedStr] = useState<string>("");
  const curProject = useSelector<RootState, Project>((state: RootState) => state.currentProject);
  const gid = curProject.gid ? curProject.gid : "";

  const onHighlightClicked = useCallback(
    async (children: string) => {
      if (isChatLoading) return;

      console.log("Highlight clicked", children);
      setIsChatLoading(true);
      setIsPageLoading(true);
      setHighlightedStr(children);
      logAnalyticsEvent(gid, "highlighted", {
        highlighted_message: children,
      });

      const pages = await highlight(Number(gid), tagsSelected, children);

      if (pages.length === 0) {
        console.log("No highlight pages found");
        setIsChatLoading(false);
        setIsPageLoading(false);
        setHighlightedStr("");

        return;
      }
      console.log(pages);
      setTotalPages(pages.length);
      setPages(pages);
      for (let i = 0; i < pages.length; i++) {
        const url = await highlight_image(Number(gid), i + 1);
        // setUrl(url);
        console.log(i + 1, url);
        setPages((prev) => {
          const newPages = [...prev];
          newPages[i].thumb = url;
          newPages[i].highlight_num = i + 1;
          return newPages;
        });
      }
      setIsChatLoading(false);
      setIsPageLoading(false);
      // setHighlightedStr("");
    },
    [gid]
  );

  return (
    <div className="pb-4 pl-4 pr-10">
      <div
        className={
          message.isTag
            ? "p-2 w-fit max-w-full border main-theme-reverse-sub rounded-r-xl cursor-pointer"
            : "p-2 w-fit max-w-full border main-theme-reverse-border rounded-r-xl whitespace-pre-wrap"
        }
        onClick={() => {
          message.isTag && onSendMessage(message.message);
        }}
      >
        <Markdown
          className="max-w-full"
          remarkPlugins={[remarkGfm]}
          components={{
            code({ node, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || "");
              return match ? (
                <SyntaxHighlighter style={vscDarkPlus} language={match[1]} PreTag="div">
                  {String(children).replace(/\n$/, "")}
                </SyntaxHighlighter>
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              );
            },
            p({ node, children, ...props }) {
              return (
                <p
                  className={
                    message.isAnswer
                      ? highlightedStr === String(children)
                        ? "m-0 p-0 cursor-pointer bg-yellow-200"
                        : "m-0 p-0 cursor-pointer"
                      : "m-0 p-0"
                  }
                  onClick={() => message.isAnswer && onHighlightClicked(String(children))}
                >
                  {children}
                  {highlightedStr === String(children) ? (
                    isChatLoading ? (
                      <div className="bg-yellow-200 text-sm text-center animate-pulse">
                        ↑この回答の参照元ページをハイライト中です
                      </div>
                    ) : (
                      <div className="bg-yellow-200 text-sm text-center animate-none">
                        ↑この回答の参照元ページを表示しました
                      </div>
                    )
                  ) : null}
                </p>
              );
            },
            li({ node, children, ...props }) {
              return (
                <p
                  className={
                    message.isAnswer
                      ? highlightedStr === String(children)
                        ? "m-0 p-0 cursor-pointer bg-yellow-200"
                        : "m-0 p-0 cursor-pointer"
                      : "m-0 p-0"
                  }
                  onClick={() => message.isAnswer && onHighlightClicked(String(children))}
                >
                  {children}
                  {highlightedStr === String(children) ? (
                    isChatLoading ? (
                      <div className="bg-yellow-200 text-sm text-center animate-pulse">
                        ↑この回答の参照元ページをハイライト中です
                      </div>
                    ) : (
                      <div className="bg-yellow-200 text-sm text-center animate-none">
                        ↑この回答の参照元ページを表示しました
                      </div>
                    )
                  ) : null}
                </p>
              );
            },
            ol({ node, children, ...props }) {
              return <div>{children}</div>;
            },
          }}
        >
          {message.message}
        </Markdown>
        {/* {message.message} */}
      </div>
    </div>
  );
};
