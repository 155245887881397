import { api } from "./axios";

export const highlight_image = async (gid: number, num: number, filetype: string = "png"): Promise<string> => {
  const response = await api.files.filesHighlightImageFileFetch({
    gid: gid,
    num: num,
    filetype: filetype,
  });
  const blob = response.data as any;
  console.log("highlight_image api success. open file");
  const png_url = URL.createObjectURL(blob);
  console.log(png_url);
  return png_url;
};
