import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./store";
import { Project, setCurrentProject } from "./types/project";
import { Tags, setAllTags } from "./types/tag";
import { projectsFetch } from "./axios/projects_fetch";
import { tagsFetch } from "./axios/tags_fetch";
import MySidebar from "./components/routes/sidebar";
import MyProjects from "./components/routes/my_projects";
import Filesandfolders from "./components/routes/files_and_folders";
import PageSearch from "./components/routes/page_search";
import PdfViewer from "./components/routes/book_reader/pdfviewer";
import Trash from "./components/routes/trash";
import Books from "./components/routes/books";
import { POOL_ID, POOL_CLIENT_ID } from "./env";
import { components, formFields } from "./auth";
import { HiOutlineChevronDoubleRight } from "react-icons/hi";
import { setSidebar } from "./types/sidebar";
import { Helmet } from "react-helmet";
import useWindowDimensions from "./lib/window_dimensions";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: POOL_CLIENT_ID,
      userPoolId: POOL_ID,
    },
  },
});

export default function App() {
  const curProject = useSelector<RootState, Project>((state: RootState) => state.currentProject);
  let gid = curProject.gid;
  const sidebar = useSelector<RootState, boolean>((state: RootState) => state.sidebar);
  console.log("sidebar", sidebar);
  const { morethan640 } = useWindowDimensions();

  const dispatch = useDispatch();

  // allTagsの初回設定
  const fetchAllTags = useCallback(
    async (gid: string) => {
      try {
        const tags: Tags = await tagsFetch(gid);
        dispatch(setAllTags(tags));
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch]
  );

  // curProjの初回設定
  const fetchMyProjects = useCallback(async () => {
    try {
      const projects: Project[] = await projectsFetch();
      const projectGids = projects.map((project) => project.gid);
      console.log("curGID", gid, parseInt(gid));
      if (projects.length > 0 && parseInt(gid) < 10000 && !projectGids.includes(gid)) {
        console.log("setCurrentProject", projects[0]);
        dispatch(setCurrentProject(projects[0]));
        fetchAllTags(projects[0].gid);
      }
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, gid, fetchAllTags]);

  // 初回のみ実行
  useEffect(() => {
    fetchMyProjects();
  }, [fetchMyProjects]);

  const showSidebar = () => {
    console.log("showSidebar");
    dispatch(setSidebar({ sidebar: true }));
  };

  return (
    <Authenticator
      loginMechanisms={["username"]}
      formFields={formFields}
      signUpAttributes={["email"]}
      components={components}
    >
      {({ signOut, user }) => (
        <div className="m-0 p-0">
          <Helmet>
            <title>Essentia AI</title>
            {/* <meta name="theme-color" content="#ffffff"></meta> */}
            <meta name="description" content="Helmet application" />
          </Helmet>
          <Router>
            <div>
              {sidebar ? <MySidebar /> : ""}
              <div id="main-wrapper0" className={sidebar ? "lg:ml-64" : ""}>
                <div id="main-wrapper1" className="main-theme "></div>
                <div
                  id="main-wrapper2"
                  className={
                    morethan640 ? "main-theme-reverse px-6 pt-6 border-0" : "main-theme-reverse px-4 pt-2 border-0"
                  }
                >
                  <Routes>
                    <Route path="/" element={<MyProjects />} />
                    <Route path="/myprojects" element={<MyProjects />} />
                    <Route path="/filesandfolders" element={<Filesandfolders />} />
                    <Route path="/pagesearch" element={<PageSearch />} />
                    <Route path="/books" element={<Books />} />
                    <Route path="/pdfview" element={<PdfViewer />} />
                    <Route path="/trash" element={<Trash />} />
                  </Routes>
                </div>
              </div>
              {!sidebar ? (
                <div className="fixed left-2 bottom-2">
                  <button onClick={showSidebar}>
                    <HiOutlineChevronDoubleRight className="w-0 sm:w-6 sm:h-6 main-theme-reverse"></HiOutlineChevronDoubleRight>
                  </button>
                </div>
              ) : null}
            </div>
          </Router>
        </div>
      )}
    </Authenticator>
  );
}

// https://qiita.com/OzWay_jon/items/876a728cedaaf823f857

// custom object contextapi
// https://blog.logrocket.com/how-to-use-react-context-typescript/
