import { HiChevronDown, HiOutlineChevronRight, HiOutlineChevronLeft, HiOutlineX } from "react-icons/hi";
import { folderFetch } from "../../axios/folder_fetch";
import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

export const PagesFolderButton = ({
  gid,
  searchFolder,
  setSearchFolder,
}: {
  gid: string;
  searchFolder: string;
  setSearchFolder: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { t } = useTranslation();
  const [allFolders, setAllFolders] = useState<string[]>([]);
  const [nextFolders, setNextFolders] = useState<string[]>([]);
  const [curFolder, setCurFolder] = useState<string>("");
  const [checkedFolder, setCheckedFolder] = useState<string>("");

  useEffect(() => {
    const folderFetchGo = async (gid: string) => {
      const folders = await folderFetch(gid);
      setAllFolders(folders);
      console.log("folderFetched", folders);
    };
    folderFetchGo(gid);
  }, [gid]);

  useEffect(() => {
    if (curFolder === "") {
      const nextFolders = allFolders.filter((folder) => folder.split("/").length === 2);
      console.log("nextFolders:", nextFolders);
      setNextFolders(nextFolders);
    } else {
      const nextFolders = allFolders.filter(
        (folder) => folder.split("/").length === curFolder.split("/").length + 1 && folder.startsWith(curFolder)
      );
      console.log("nextFolders:", nextFolders);
      setNextFolders(nextFolders);
    }
  }, [allFolders, curFolder]);

  const onMoveFolder = useCallback(
    (folder: string) => {
      const hasSubfolders = allFolders.some((f) => f.startsWith(`${folder}`) && f !== `${folder}`);
      if (hasSubfolders) {
        setCurFolder(folder);
      }
    },
    [allFolders]
  );

  const onCheckFolder = useCallback(
    (folder: string) => {
      if (folder === checkedFolder) {
        setCheckedFolder("");
      } else {
        setCheckedFolder(folder);
      }
    },
    [checkedFolder]
  );

  const onApply = useCallback(() => {
    setSearchFolder(checkedFolder);
    document.querySelectorAll(".dropdown").forEach(function (dropdown) {
      const dropdownElement = dropdown as HTMLDetailsElement;
      dropdownElement.open = false;
    });
  }, [checkedFolder, setSearchFolder]);

  const onClickRemove = useCallback(() => {
    setSearchFolder("");
    setCheckedFolder("");
    setCurFolder("");
  }, [setSearchFolder, setCheckedFolder, setCurFolder]);

  const onClickBack = useCallback((folder: string) => {
    console.log("curFolder:", folder);
    let parentFolder = folder.split("/").slice(0, -2).join("/") + "/";
    parentFolder = parentFolder === "/" ? "" : parentFolder;
    console.log("parentFolder:", parentFolder);
    setCurFolder(parentFolder);
  }, []);

  return (
    <div className="hidden sm:hidden md:block">
      <details id="folder_button" className="dropdown w-40 mr-4">
        <summary className="btn w-full px-4 main-theme-reverse-sub font-normal">
          {searchFolder === "" ? (
            <>
              {t("pagesearch.folder")} <HiChevronDown></HiChevronDown>
            </>
          ) : (
            <button className="flex items-center align-middle w-full h-full justify-center" onClick={onClickRemove}>
              <HiOutlineX className="-ml-4 mr-2 w-4 h-4"></HiOutlineX>
              {searchFolder.split("/")[searchFolder.split("/").length - 2]}
            </button>
          )}
        </summary>
        <ul className="menu dropdown-content z-[1] w-60 main-theme-reverse border-2 ">
          {nextFolders.length > 0 ? (
            <div>
              {curFolder !== "" ? (
                <li className="hover:bg-white focus:bg-white active:bg-white bg-white" key="-1">
                  <div className="flex h-12">
                    <HiOutlineChevronLeft
                      onClick={() => {
                        onClickBack(curFolder);
                      }}
                    ></HiOutlineChevronLeft>
                    <span className="ml-2 text-xs">Back</span>
                  </div>
                </li>
              ) : null}
              {nextFolders.map((folder, index) => (
                <li key={index} className="hover:bg-white focus:bg-white active:bg-white bg-white">
                  <div className="flex h-12">
                    <input
                      type="checkbox"
                      className="checkbox checkbox-primary checkbox-sm"
                      onChange={() => {
                        onCheckFolder(folder);
                      }}
                      checked={folder === checkedFolder}
                    ></input>
                    <span className="ml-2 text-xs">{folder.split("/")[folder.split("/").length - 2]}</span>
                    <HiOutlineChevronRight
                      className="ml-auto"
                      onClick={() => {
                        onMoveFolder(folder);
                      }}
                    ></HiOutlineChevronRight>
                  </div>
                </li>
              ))}
              <li className="hover:bg-white focus:bg-white active:bg-white bg-white">
                <button className="text-normal w-full h-full main-theme text-justify justify-center" onClick={onApply}>
                  {t("pagesearch.apply")}
                </button>
              </li>
            </div>
          ) : (
            <div>{t("pagesearch.nofolder")}</div>
          )}
        </ul>
      </details>
    </div>
  );
};
