import { useTranslation } from "react-i18next";

export const PageLookForWithin = ({
  handleKeyDown,
  setTermField,
}: {
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  setTermField: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="flex m-2 p-2 align-middle items-center">
        <span className="font-bold main-theme-reverse whitespace-nowrap">{t("pagesearch.lookfor")}</span>
        <input
          id="search_input"
          type="text"
          placeholder="Type here"
          className="w-full max-w-xs ml-3 border-b-2 focus:border-none focus:outline-4 focus:outline-offset-8 focus:outline-gray-300"
          onKeyDown={handleKeyDown}
          autoFocus
        />
        <span className="ml-4 font-bold main-theme-reverse whitespace-nowrap">{t("pagesearch.within")}</span>
        <select
          className="ml-3 w-48 focus:border-none focus:outline-4 focus:outline-offset-8 focus:outline-gray-300"
          onChange={(e) => setTermField(e.target.value)}
        >
          <option>{t("pagesearch.within_exact_match")}</option>
          <option>{t("pagesearch.within_broad_match")}</option>
          <option>{t("pagesearch.within_filename")}</option>
          <option>{t("pagesearch.within_comment")}</option>
        </select>
      </div>
    </div>
  );
};
