import axios from "axios";
import { API_BASE_URL } from "../env";
import { UploadFile } from "../types/file";
import { getAuthInfo } from "../auth";
import { api } from "./axios";

export const fileUpload = async (file: UploadFile, gid: string, replace: boolean): Promise<[string, boolean]> => {
  console.log(file, gid, replace);
  const response = await api.files.filesUpload({
    gid: Number(gid),
    file: file,
    replace: replace,
  });
  const { url, overlapped } = response.data as { url: string; overlapped: boolean };
  console.log(overlapped, url);
  return [url, overlapped];
};
