import { BooksCell } from "./books_cell";
import { Page } from "../../types/page";
import { FileOrPage } from "../../types/fileorpage";

export const BooksGrid = ({
  pages,
  filesOrPagesEdit,
  setFilesOrPagesEdit,
  onTagSelected,
  tags,
  isZoomVisible,
  setIsZoomVisible,
  setZoomPage,
}: {
  pages: Page[];
  filesOrPagesEdit: FileOrPage[];
  setFilesOrPagesEdit: React.Dispatch<React.SetStateAction<FileOrPage[]>>;
  onTagSelected: (tag: string) => void;
  tags: string[];
  isZoomVisible: boolean;
  setIsZoomVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setZoomPage: React.Dispatch<React.SetStateAction<Page | null>>;
}) => {
  return (
    <div className="w-full grid grid-cols-2 sm:grid-cols-[repeat(auto-fill,16rem)] sm:justify-between gap-2">
      {pages.map((page, index) => (
        <BooksCell
          key={index}
          page={page}
          filesOrPagesEdit={filesOrPagesEdit}
          setFilesOrPagesEdit={setFilesOrPagesEdit}
          onTagSelected={onTagSelected}
          tags={tags}
          isZoomVisible={isZoomVisible}
          setIsZoomVisible={setIsZoomVisible}
          setZoomPage={setZoomPage}
        />
      ))}
    </div>
  );
};
