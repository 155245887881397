import React from "react";
import { ChatMessage } from "../../types/aichat";

export const EssaiChatMyFukidashi = ({ message }: { message: ChatMessage }) => {
  // ユーザー側の吹き出し
  return (
    <div className="flex mb-4 ml-10 mr-4">
      <div className="ml-auto p-2 w-fit border main-theme-reverse-border rounded-l-xl">{message.message}</div>
    </div>
  );
};
