import React from "react";
import { useState, useEffect, useRef } from "react";
import { ChatMessage } from "../../types/aichat";
import { EssaiChatHeader } from "./essai_chat_header";
import { EssaiChatAIFukidashi } from "./essai_chat_ai_fukidashi";
import { EssaiChatMyFukidashi } from "./essai_chat_my_fukidashi";
import { HiOutlinePaperAirplane } from "react-icons/hi";
import { Page } from "../../types/page";
import useWindowDimensions from "../../lib/window_dimensions";
import { useTranslation } from "react-i18next";

export const EssaiChat = ({
  isChatLoading,
  setIsChatLoading,
  setIsPageLoading,
  messages,
  onSendMessage,
  setMessages,
  setIsChatVisible,
  setTotalPages,
  setPages,
  setInitialMessage,
  tagsSelected,
}: {
  isChatLoading: boolean;
  setIsChatLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsPageLoading: React.Dispatch<React.SetStateAction<boolean>>;
  messages: ChatMessage[];
  onSendMessage: (message: string) => void;
  setMessages: React.Dispatch<React.SetStateAction<ChatMessage[]>>;
  setIsChatVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setTotalPages: React.Dispatch<React.SetStateAction<number>>;
  setPages: React.Dispatch<React.SetStateAction<Page[]>>;
  setInitialMessage: () => void;
  tagsSelected: string[];
}) => {
  const { t } = useTranslation();
  const { morethan640 } = useWindowDimensions();
  const [composing, setComposition] = useState(false);
  const startComposition = () => setComposition(true);
  const endComposition = () => setComposition(false);
  const divRef = useRef<HTMLDivElement>(null);

  const onClickAirplane = () => {
    const input = document.getElementById("chat-input") as HTMLInputElement;
    if (input) {
      onSendMessage(input.value);
      input.value = "";
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && !composing) {
      onClickAirplane();
    }
  };

  // メッセージが追加されたら一番下にスクロール
  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  return (
    <div className="h-full w-full m-0 p-0 border-l">
      <EssaiChatHeader
        isChatLoading={isChatLoading}
        setIsChatLoading={setIsChatLoading}
        setMessages={setMessages}
        setIsChatVisible={setIsChatVisible}
        setInitialMessage={setInitialMessage}
      />
      <div className="w-full h-full pb-20 bg-white">
        {/* <img src={url} className={url ? "w-20 h-20" : ""}></img> */}
        <div className="h-full overflow-y-auto pt-4">
          {messages.map((msg, index) =>
            msg.isOutgoing ? (
              <EssaiChatMyFukidashi key={index} message={msg} />
            ) : (
              <EssaiChatAIFukidashi
                key={index}
                isChatLoading={isChatLoading}
                setIsChatLoading={setIsChatLoading}
                setIsPageLoading={setIsPageLoading}
                message={msg}
                onSendMessage={onSendMessage}
                setTotalPages={setTotalPages}
                setPages={setPages}
                tagsSelected={tagsSelected}
              />
            )
          )}
          {isChatLoading && <span className="mt-2 ml-4 loading loading-ring loading-md"></span>}
          <div ref={divRef}></div>
        </div>
      </div>
      <div className="w-full m-0 p-0 absolute bottom-0 right-0">
        <div className="mb-2 flex w-full items-center align-middle">
          <input
            id="chat-input"
            className="ml-8 mr-2 pl-2 pr-4 min-h-8 w-full focus:outline-4 focus:outline-gray-300 "
            placeholder={t("books.chatplaceholder")}
            onKeyDown={handleKeyDown}
            onCompositionStart={startComposition}
            onCompositionEnd={endComposition}
            autoFocus={morethan640}
          />
          <HiOutlinePaperAirplane
            className="ml-auto mr-2 w-6 h-6"
            onClick={() => {
              onClickAirplane();
            }}
          />
        </div>
      </div>
    </div>
  );
};
