import { api } from "./axios";
import { UploadFile } from "../types/file";;

export const filesCheck = async (files: UploadFile[], gid: string): Promise<boolean> => {
  console.log(files);
  const response = await api.files.filesCheck({
    gid: Number(gid),
    files: files,
  });
  const overlapped = (response.data as boolean) ?? false;
  console.log("overlapped", overlapped);
  return overlapped;
};
