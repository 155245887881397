import { HiChevronDown } from "react-icons/hi";
import { FileOrPage } from "../../types/fileorpage";
import { filesDelete } from "../../axios/files_delete";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { Project } from "../../types/project";
import { useCallback } from "react";
import { t } from "i18next";

export const FilesActionButton = ({ filesOrPagesEdit }: { filesOrPagesEdit: FileOrPage[] }) => {
  const curProject = useSelector<RootState, Project>((state: RootState) => state.currentProject);
  const gid = curProject.gid ? curProject.gid : "";

  const showTagModal = () => {
    const dialogElement = document.getElementById("tag-modal") as HTMLDialogElement;
    if (dialogElement) {
      dialogElement.showModal();
    }
  };

  const showRenameModal = () => {
    const dialogElement = document.getElementById("rename-modal") as HTMLDialogElement;
    if (dialogElement) {
      dialogElement.showModal();
    }
  };

  const showMoveModal = () => {
    const dialogElement = document.getElementById("move-modal") as HTMLDialogElement;
    if (dialogElement) {
      dialogElement.showModal();
    }
  };



  const showConfirm = useCallback(async () => {
    const answer = window.confirm(t("files.delete_confirm"));
    if (answer) {
      if (!filesOrPagesEdit) return;

      const keys = filesOrPagesEdit.filter((fileOrPage) => fileOrPage.key).map((fileOrPage) => fileOrPage.key);
      const folders = filesOrPagesEdit
        .filter((fileOrPage) => fileOrPage.folder && !fileOrPage.file)
        .map((fileOrPage) => fileOrPage.folder);

      console.log(gid, keys, folders);
      await filesDelete(gid, keys, folders);
      window.location.reload();
    }
  }, [gid, filesOrPagesEdit]);

  const showComment = () => {
    const dialogElement = document.getElementById("comment-modal") as HTMLDialogElement;
    if (dialogElement) {
      dialogElement.showModal();
    }
  };

  return (
    <details className="dropdown w-40 pr-4">
      <summary className="m-1 btn w-full px-4 main-theme">
       {t("files.actions")} <HiChevronDown></HiChevronDown>
      </summary>
      <ul className="p-2 shadow menu dropdown-content z-[1] w-full main-theme-reverse">
        <li onClick={showRenameModal} className={filesOrPagesEdit.length !== 1 ? "pointer-events-none" : ""}>
          <span className={filesOrPagesEdit.length !== 1 ? "text-gray-400" : ""}>{t("files.actions_rename")}</span>
        </li>
        <li onClick={showMoveModal} className={filesOrPagesEdit.length === 0 ? "pointer-events-none" : ""}>
          <span className={filesOrPagesEdit.length === 0 ? "text-gray-400" : ""}>{t("files.actions_move")}</span>
        </li>
        <li>
          <span>{t("files.actions_download")}</span>
        </li>
        <li>
          <span onClick={showConfirm}>{t("files.actions_delete")}</span>
        </li>
        <li onClick={showTagModal}>
          <span>{t("files.actions_tag")}</span>
        </li>
        <li onClick={showComment} className={filesOrPagesEdit.length !== 1 ? "pointer-events-none" : ""}>
          <span className={filesOrPagesEdit.length !== 1 ? "text-gray-400" : ""}>{t("files.table_Note")}</span>
        </li>
      </ul>
    </details>
  );
};
