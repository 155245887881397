
// 外部クリックでドロップダウンを閉じる
export const closeDropdownByOutsideClick = () => {
  window.addEventListener("click", function (e) {
    document.querySelectorAll(".dropdown").forEach(function (dropdown) {
      if (!dropdown.contains(e.target as Node)) {
        const dropdownElement = dropdown as HTMLDetailsElement;
        dropdownElement.open = false;
      }
    });
  });
};

export const closeDropdown = async () => {
  document.querySelectorAll(".dropdown").forEach(function (dropdown) {
    const dropdownElement = dropdown as HTMLDetailsElement;
    dropdownElement.open = false;
  });
};
