// import axios from "axios";
import { api } from "./axios";
import { API_BASE_URL } from "../env";
import { Page } from "../types/page";
import { getAuthInfo } from "../auth";
import { Chat } from "./client";

export const chatAsk = async (
  gid: number,
  tags: string[],
  question: string,
  lang: string = "ja"
): Promise<[string, Page[]]> => {
  console.log("chatAsk: ", question);
  const response = await api.chat.chatAsk({
    gid: gid,
    tags: tags,
    question: question,
    lang: lang,
  });
  const answer: string = (response.data as Chat).answer ?? "";
  const pages: Page[] = (response.data as Chat).pages as any;
  console.log(answer);
  console.log(pages);
  return [answer, pages];
};
