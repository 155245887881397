import { api } from "./axios";
import { SinglePage } from "./client";

export const filesSinglePageFetch = async (key: string, page: number = 0, filetype="png"): Promise<string> => {
  const response = await api.files.filesSinglePageFetch({
    key: key,
    page: page,
    filetype: filetype,
  });
  const url: string = (response.data as SinglePage)?.url ?? "";
  return url;
};
