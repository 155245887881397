import { ChatMessage } from "../../types/aichat";
import { getChatMessage, getWithSentTimeMessages } from "./message_utils";


export const tagSelectedChat = (tag: string, tagIsSubject: boolean, gid: string, EssaiDataJson: string): ChatMessage[] => {
  const gidStr = `${gid}`;
  const json = JSON.parse(EssaiDataJson);
  if (gidStr in json) {
    const nodeData = json[gidStr];
      if (tag in nodeData) {
        const tsunagi = getChatMessage(tag);
        return [tsunagi, ...getWithSentTimeMessages(nodeData[tag].node)]; // 繋ぎ + 質問例
      } else if (tagIsSubject) {
        return getWithSentTimeMessages([nodeData.NONE_WORD]); // 該当なし表示
      } else {
        return [];
      }
  }
  return [];
};
