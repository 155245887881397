import { api } from "./axios";
import { PagesList } from "./client";
import { Page } from "../types/page";
import { getAuthInfo } from "../auth";

export const highlight = async (
  gid: number,
  tags: string[],
  question: string = "VBAを学ぶ最初のステップとして、マクロの記録機能を利用することができます。これにより、Excel上で 行った操作を自動的にVBAコードとして記録することができます。記録したマクロは、後で編集してカスタマイズすることも可能です。"
): Promise<Page[]> => {
  try {
    console.log("highlight: ", question);
    const response = await api.pages.pagesHighlight({
      gid: gid,
      tags: tags,
      question: question,
    });
    console.log("Highlight Response:", response);
    const pages: Page[] = (response.data as PagesList).pages as any;
    return pages;
  } catch (error) {
    console.error(error);
    return [];
  }
};
