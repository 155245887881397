import { useTranslation } from "react-i18next";

export const AIChatOpenButton = () => {
  const { t } = useTranslation();

  return (
    <button className="btn px-4 main-theme">
      <img src="chatbot_avatar_1.png" alt="chatbot_avatar_1" className="h-6 w-6 md:mr-2"/>
      <span className="hidden md:inline">{t("pagesearch.ai_chat")}</span>
    </button>
  );
};
