import { Page } from "../../types/page";

import { useCallback, useState, createRef, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { Tags } from "../../types/tag";
import { RootState } from "../../store";
import { MESSAGE_KEY } from "../routes/book_reader/pdfviewer";

export const BooksRecommendCell = ({ page, title }: { page: Page; title: string }) => {
  const allTags = useSelector<RootState, Tags>((state: RootState) => state.allTags);

  const thumb = page.thumb;
  const curPage = page.page.replace("page_", "").replace(".png", "");
  const tagBoxRef = createRef<HTMLDivElement>();
  const [numTagShow, setNumTagShow] = useState<number>(page.tag ? page.tag.length : 0);
  const [tagClientWidth, setTagClientWidth] = useState<number>(220);
  const [tagScrollWidth, setTagScrollWidth] = useState<number>(220);
  const [microTags, setMicroTags] = useState<string[]>([]);

  const onClickImage = useCallback(async () => {
    const curPage = page.page.replace("page_", "").replace(".png", "");
    const msgObj = {
      key: page.key,
      curPage
    };
    localStorage.setItem(MESSAGE_KEY, JSON.stringify(msgObj));
    window.open(`/pdfview`, "_blank");

  }, [page]);

  useLayoutEffect(() => {
    if (tagBoxRef.current) {
      if (tagBoxRef.current.clientWidth > 200 && tagBoxRef.current.scrollWidth > 200) {
        if (tagBoxRef.current.clientWidth !== tagClientWidth) {
          setTagClientWidth(tagBoxRef.current.clientWidth);
        }
        if (tagBoxRef.current.scrollWidth !== tagScrollWidth) {
          setTagScrollWidth(tagBoxRef.current.scrollWidth);
        }
      }
    }
  }, [tagBoxRef, tagScrollWidth, tagClientWidth]);

  useLayoutEffect(() => {
    if (tagClientWidth > 200 && tagScrollWidth > 200) {
      if (tagClientWidth + 1 < tagScrollWidth) {
        console.log("tagBoxRef.current.clientWidth", tagClientWidth);
        console.log("tagBoxRef.current.scrollWidth", tagScrollWidth);
        setNumTagShow((current) => (current > 0 ? current - 1 : current));
      }
    }
  }, [tagClientWidth, tagScrollWidth]);

  const checkTagGroup = useCallback(
    (tag: string) => {
      if (allTags[tag]) {
        console.log("checkTagGroup", tag, allTags[tag]);
        return !["series", "subject"].includes(allTags[tag]);
      } else {
        return true;
      }
    },
    [allTags]
  );

  useLayoutEffect(() => {
    if (page.tag) {
      const microTags = page.tag.filter((tag) => checkTagGroup(tag));
      console.log("microTags", microTags);
      setMicroTags(microTags);
    }
  }, [page.tag, checkTagGroup]);

  return (
    <div className="mx-2 mb-4 w-full h-fit bg-white border-2">
      <div
        className="py-2 flex cursor-pointer justify-center"
        onClick={async () => {
          onClickImage();
        }}
      >
        <img src={thumb} alt="thumbnail" className="justify-start aspect-auto w-30 h-40 object-contain" />
      </div>
      <div className="mx-4">
        <div className="text-sm my-2">
          {page.comment ? page.comment : "Pythonでモグラ叩きゲームを作りながら、リアルタイム処理を習得しよう！"}
        </div>
        {title.includes("オススメ") ? null : (
          <div className="flex">
            <div className="text-left text-sm mb-2 text-gray-500">レビュー 2024年9月20日</div>
            <div className="ml-auto text-right text-sm text-black mb-2">
              {" "}
              {page.comment.includes("B") ? "ミサキ" : "tktk"}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
