import { api } from "./axios";


export const filesMove = async (gid: string, keys: string[], folders: string[], newFolder: string): Promise<string> => {
  console.log(gid, keys, folders, newFolder);
  const response = await api.files.filesMove({
    gid: Number(gid),
    keys: keys,
    folders: folders,
    newfolder: newFolder,
  });
  const message = response.data.message as string;
  console.log(message);
  return message;
};
