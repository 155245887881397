import { useEffect, useState, useRef, useCallback } from "react";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import { filesSinglePageFetch } from "../../../axios/files_single_page";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi";
// ワーカースクリプトを手動で設定
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

export const MESSAGE_KEY = "param_msg";
export const PAGE_KEY = "page_key";
export const CURRENT_PAGE = "current_page";
export const COUNT_PAGING_NUM = "count_paging_num";
export const LIMIT_PAGING_NUM = 10;

export const PdfViewer = () => {
  let countPagingNum = Number(sessionStorage.getItem(COUNT_PAGING_NUM));
  // ページング数制限表示フラグ
  const [isShowPagingLimit, setIsShowPagingLimit] = useState(false);

  const [TotalPageNum, setTotalPageNum] = useState(3); // 全体ページ数
  const [currentPage, setCurrentPage] = useState(1);
  const [pdfKey, setPdfKey] = useState("");
  const canvasRefLeft = useRef<HTMLCanvasElement | null>(null);
  const canvasRefRight = useRef<HTMLCanvasElement | null>(null);

  // 描画中かどうかのフラグ
  const [isRendering, setIsRendering] = useState(false);
  //
  const [pageNumPending, setPageNumPending] = useState(null);
  // 前回のrender taskを追跡
  const renderTaskRef = useRef(null);
  // canvasの拡大率
  const [scale, setScale] = useState(1.5);
  // MapでPDF Blobをキャッシュ
  const [pdfBlobMap, setPdfBlobMap] = useState(new Map());
  // 表紙ページかどうかのフラグ
  const [isFirstPage, setIsFirstPage] = useState(true);
  // 単ページ表示かどうかのフラグ
  const [isSinglePage, setisSinglePage] = useState(false);
  // 描画フラグを使って再レンダリングを防ぐ
  const hasRenderedOnceLeft = useRef(false);
  const hasRenderedOnceRight = useRef(false);

  // windowサイズ変更時にcanvasのサイズを高さ基準/幅基準で切り替える
  const [switchHeightWidth, setSwitchHeightWidth] = useState(false);
  const handleResize = () => {
    setSwitchHeightWidth(window.innerWidth > 1280);
  };
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // PDFファイルを読み込んで表示
  useEffect(() => {
    // SessionStorageからパラメータメッセージ取得
    // 無ければLocalStorageから取得
    // (初回はLocalStorageで受取りSessionStorageに入替えてクリアする)
    let paramMsg = sessionStorage.getItem(MESSAGE_KEY);
    if (!paramMsg) {
      paramMsg = localStorage.getItem(MESSAGE_KEY);
      if (!paramMsg) {
        // パラメータメッセージ存在しない場合は表示なし
        console.error("PDFの読み込み中にエラーが発生しました: パラメータメッセージ受け渡しエラー");
        return;
      }
      sessionStorage.setItem(MESSAGE_KEY, paramMsg);
      localStorage.removeItem(MESSAGE_KEY);
    }

    const msgJson = JSON.parse(paramMsg);
    const key: string = msgJson.key;
    const keyOnStorage = sessionStorage.getItem(PAGE_KEY);
    if (!keyOnStorage) {
      sessionStorage.setItem(PAGE_KEY, key);
    }
    setPdfKey(key);

    const curPage = msgJson.curPage;
    const curPageOnStorage = sessionStorage.getItem(CURRENT_PAGE);
    if (!curPageOnStorage) {
      sessionStorage.setItem(CURRENT_PAGE, curPage);
      sessionStorage.setItem(COUNT_PAGING_NUM, "0");
    }
    let curPageNum = Number(sessionStorage.getItem(CURRENT_PAGE));
    setCurrentPage(curPageNum);
    setIsFirstPage(curPageNum === 1);

    setTotalPageNum(1000); // TODO: PDFページ数取得して設定

    countPagingNum = Number(sessionStorage.getItem(COUNT_PAGING_NUM));
    setIsShowPagingLimit(false);

    // PDF描画処理
    renderPages();
  }, [currentPage, isSinglePage, pdfKey]);

  /**
   * PDFファイルを取得してMapにキャッシュする
   * @param key
   * @param curPageNum
   * @returns
   */
  const fetchPagePdf = async (key: string, curPageNum: number) => {
    if (pdfBlobMap.has(curPageNum)) {
      // 既にキャッシュされている場合はAPIを呼ばずにキャッシュから取得
      return pdfBlobMap.get(curPageNum);
    }
    return await filesSinglePageFetch(key, curPageNum).then(async (result) => {
      const fetchPDF = async () => {
        try {
          // 日本語フォントが表示されないページ対応の為にcmaps読み込み必要
          const loadingTask = pdfjsLib.getDocument({
            url: result,
            cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjsLib.version}/cmaps/`,
            cMapPacked: true,
          });
          const pdf = await loadingTask.promise;
          const nextPdfBlobMap = new Map(pdfBlobMap);
          nextPdfBlobMap.set(curPageNum, pdf);
          setPdfBlobMap(nextPdfBlobMap);
          return pdf;
        } catch (error) {
          console.error("PDFの読み込み中にエラーが発生しました: ", error);
        }
      };
      return fetchPDF();
    });
  };

  /**
   * PDFページを描画する
   * @param pdf
   * @param pageNumber
   * @param canvas
   **/
  const renderPage = async (pdf: any, pageNumber: any, canvasRef: any) => {
    if (isRendering) {
      return; // 描画中なら新しい描画をスキップ
    }

    setIsRendering(true);

    const page = await pdf.getPage(1); // 1ページづつ取得しているため //pageNumber);
    const viewport = page.getViewport({ scale });
    const canvas = canvasRef.current;
    if (!canvas) {
      setIsRendering(false);
      return;
    }
    const context = canvas.getContext("2d");
    if (!context) {
      setIsRendering(false);
      return;
    }
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    // 前回のrenderタスクが存在する場合、キャンセル
    if (renderTaskRef.current) {
      renderTaskRef.current = null; //.cancel();
    }
    const PRINT_UNITS = 120;
    const renderContext = {
      canvasContext: context,
      viewport: viewport,
      trasform: [PRINT_UNITS, 0, 0, PRINT_UNITS, 0, 0],
    };

    const renderTask = page.render(renderContext);

    renderTask.promise.then(() => {
      if (pageNumPending !== null) {
        renderPage(pdf, pageNumPending, canvasRef);
        setPageNumPending(null);
      }
      setIsRendering(false);
      hasRenderedOnceLeft.current = true; // 描画が完了したことをフラグで記録
      hasRenderedOnceRight.current = true; // 描画が完了したことをフラグで記録
    });
  };

  /**
   * PDFページを取得してCanvasに描画する
   * 単位ページ・見開きページに応じて描画する
   */
  const renderPages = useCallback(() => {
    if (pdfKey) {
      let curPageNum = Number(currentPage);
      if (!isSinglePage && !isFirstPage) {
        // 見開き表示の場合 && 表紙ページより後の場合
        // 偶数ページ判定
        // 偶数ページの場合は左、奇数ページの場合は右に描画
        const isEven = curPageNum % 2 === 0;
        fetchPagePdf(pdfKey, curPageNum).then((pdf) => {
          renderPage(pdf, curPageNum, isEven ? canvasRefLeft : canvasRefRight);
        });
        curPageNum = isEven ? curPageNum + 1 : curPageNum - 1;
        fetchPagePdf(pdfKey, curPageNum).then((pdf) => {
          renderPage(pdf, curPageNum, isEven ? canvasRefRight : canvasRefLeft);
        });
      } else {
        // 単ページ表示の場合左側canvasのみに描画
        fetchPagePdf(pdfKey, curPageNum).then((pdf) => {
          renderPage(pdf, curPageNum, canvasRefLeft);
        });
      }
    }
  }, [currentPage, isSinglePage, pdfKey]);

  /**
   * 前のページに移動
   * @returns
   */
  const onClickPrevPage = () => {
    if ((!hasRenderedOnceLeft.current && !hasRenderedOnceRight.current) || currentPage <= 1) {
      return;
    }

    const minusNum = isSinglePage ? 1 : 2;

    // ページング数制限
    if (countPagingNum <= LIMIT_PAGING_NUM * -1) {
      setIsShowPagingLimit(true);
      setTimeout(() => {
        setIsShowPagingLimit(false);
      }, 3000);
      return;
    }
    countPagingNum = countPagingNum - minusNum;
    sessionStorage.setItem(COUNT_PAGING_NUM, `${countPagingNum}`);

    const index = currentPage - minusNum;
    const prevIndex = index < 1 ? 1 : index;
    setCurrentPage(prevIndex);
    setIsFirstPage(prevIndex === 1);
    sessionStorage.setItem(CURRENT_PAGE, `${prevIndex}`);
    hasRenderedOnceLeft.current = false;
    hasRenderedOnceRight.current = false;
  };

  /**
   * 次のページに移動
   */
  const onClickNextPage = () => {
    // TODO: ページ数取得するようになったら制御する
    // if (currentPage >= numPages) {
    //   return;
    // }

    if (!hasRenderedOnceLeft.current && !hasRenderedOnceRight.current) {
      return;
    }

    const plusNum = isSinglePage ? 1 : 2;

    // ページング数制限
    if (countPagingNum >= LIMIT_PAGING_NUM) {
      setIsShowPagingLimit(true);
      setTimeout(() => {
        setIsShowPagingLimit(false);
      }, 3000);
      return;
    }
    countPagingNum = countPagingNum + plusNum;
    sessionStorage.setItem(COUNT_PAGING_NUM, `${countPagingNum}`);

    const nextIndex = currentPage + plusNum;
    setCurrentPage(nextIndex);
    setIsFirstPage(nextIndex === 1);
    sessionStorage.setItem(CURRENT_PAGE, `${nextIndex}`);
    hasRenderedOnceLeft.current = false;
    hasRenderedOnceRight.current = false;
  };

  /**
   * 単ページ表示・見開き表示の切り替え
   */
  const onClickToggleDisp = useCallback(() => {
    hasRenderedOnceLeft.current = false;
    hasRenderedOnceRight.current = false;
    setisSinglePage((prev) => !prev);
  }, [isSinglePage]);

  const rootElement = document.getElementById("mysidebar");
  if (rootElement) {
    rootElement.style.visibility = "hidden";
    rootElement.className = "";
  }
  const mainWrappers = ["main-wrapper0", "main-wrapper1", "main-wrapper2"];

  for (const mainWrapper of mainWrappers) {
    const mainElement = document.getElementById(mainWrapper);
    if (mainElement) {
      mainElement.className = "";
    }
  }

  return (
    <div className="h-screen w-screen bg-slate-700">
      {isShowPagingLimit && (
        <div className="toast toast-top toast-center opacity-90">
          <div className="alert alert-warning p-8 text-lg">
            <span>閲覧可能な範囲は前後それぞれ{LIMIT_PAGING_NUM}ページまでです</span>
          </div>
        </div>
      )}
      {/* TODO: カルーセルにする場合ローディングマスク表示なくす */}
      {!hasRenderedOnceLeft.current && !hasRenderedOnceRight.current ? (
        <div className="w-full h-full fixed flex justify-center opacity-30 bg-black">
          <span className="loading loading-spinner loading-lg"></span>
        </div>
      ) : null}

      <div className="flex justify-end pr-2 pt-2">
        {hasRenderedOnceLeft.current && hasRenderedOnceRight.current ? (
          <button className="btn btn-sm opacity-80 w-36" onClick={onClickToggleDisp}>
            {!isSinglePage ? "単一ページ表示" : "見開き表示"}
          </button>
        ) : (
          <button className="btn btn-sm opacity-50 w-36">{!isSinglePage ? "単一ページ表示" : "見開き表示"}</button>
        )}
      </div>

      <div className="flex justify-center">
        <div className="content-center pr-5">
          <button
            className="btn btn-circle opacity-50"
            onClick={onClickPrevPage}
            disabled={(!hasRenderedOnceLeft.current && !hasRenderedOnceRight.current) || currentPage <= 1}
          >
            <HiOutlineChevronLeft className="w-6 h-6"></HiOutlineChevronLeft>
          </button>
        </div>

        {/* PDFを描画するcanvas */}
        <div className="flex pt-4 pb-4">
          <canvas
            ref={canvasRefLeft}
            style={switchHeightWidth ? { height: "calc(100vh - 80px)" } : {}}
            className={switchHeightWidth ? "" : "md:w-[calc(40vw)] w-[calc(35vw)]"}
          />
          {!isSinglePage && !isFirstPage ? (
            <canvas
              ref={canvasRefRight}
              style={switchHeightWidth ? { height: "calc(100vh - 80px)" } : {}}
              className={switchHeightWidth ? "max-w-prose" : "md:w-[calc(40vw)] w-[calc(35vw)]"}
            />
          ) : null}
        </div>

        <div className="content-center pl-5">
          <button
            className="btn btn-circle opacity-50"
            onClick={onClickNextPage}
            disabled={!hasRenderedOnceLeft.current && !hasRenderedOnceRight.current}
          >
            <HiOutlineChevronRight className="w-6 h-6"></HiOutlineChevronRight>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PdfViewer;
