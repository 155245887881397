import axios from "axios";
import { API_BASE_URL } from "../env";

export const uploadFileToS3 = async (file: File, url: string): Promise<void> => {
  const response = await axios({
    method: "PUT",
    headers: {
      "content-type": file.type,
    },
    data: file,
    timeout: 60000,
    url: url,
  });
  console.log(response);
};
