import React, { useCallback } from "react";
import { ChatMessage } from "../../types/aichat";
import { HiOutlineRefresh, HiOutlineX } from "react-icons/hi";
import useWindowDimensions from "../../lib/window_dimensions";
import { useTranslation } from "react-i18next";
import { Project } from "../../types/project";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { logAnalyticsEvent } from "../../lib/firebase";

export const EssaiChatHeader = ({
  isChatLoading,
  setIsChatLoading,
  setMessages,
  setIsChatVisible,
  setInitialMessage,
}: {
  isChatLoading: boolean;
  setIsChatLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setMessages: React.Dispatch<React.SetStateAction<ChatMessage[]>>;
  setIsChatVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setInitialMessage: () => void;
}) => {
  const { t } = useTranslation();
  const curProject = useSelector<RootState, Project>((state: RootState) => state.currentProject);
  const gid = curProject.gid ? curProject.gid : "";
  const { morethan640 } = useWindowDimensions();
  const showConfirm = useCallback(() => {
    const ans = window.confirm(t("books.chatremovewarning"));
    if (ans) {
      logAnalyticsEvent(gid, "chat_refresh", {});
      setIsChatLoading(false);
      setMessages([]);
      setInitialMessage();
    }
  }, [t, setIsChatLoading, setMessages, setInitialMessage]);

  return (
    <div className="m-0 p-0 h-12 sm:h-8 main-theme flex align-middle items-center w-full">
      {morethan640 ? (
        <div className="ml-4 flex align-middle items-center">
          <img
            src="chatbot_avatar_1.png"
            alt="chatbot_avatar_1"
            className={isChatLoading ? "h-4 w-4 mr-2 animate-bounce" : "h-4 w-4 mr-2"}
          />
          <span className="text-xs font-semibold">AI CHAT</span>
        </div>
      ) : (
        <div className="ml-4 flex align-middle items-center">
          <img
            src="essentia-ai-logo.png"
            alt="chatbot_avatar_1"
            className={isChatLoading ? "h-8 mr-2 animate-bounce" : "h-8 mr-2"}
          />
        </div>
      )}
      <div className="ml-auto flex align-middle items-center font-bold text-xs">
        <button
          onClick={() => {
            showConfirm();
          }}
        >
          <HiOutlineRefresh className="w-6 h-6 sm:w-5 sm:h-5 mr-4" />
        </button>
        <button
          onClick={() => {
            setIsChatVisible(false);
          }}
        >
          <HiOutlineX className="w-6 h-6 sm:w-6 sm:h-6 mr-4" />
        </button>
      </div>
    </div>
  );
};
