import React from "react";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
} from "@chatscope/chat-ui-kit-react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { AIComponentProps } from "../../types/aichat";
import { HiOutlineX } from "react-icons/hi";

export const AIChat = ({
  messages,
  setIsChatVisible,
  onSendMessage,
  onMessageClick,
}: AIComponentProps) => {
  return (
    <>
      <div className="h-8 main-theme flex align-middle items-center">
        <div className="ml-4 flex align-middle items-center">
          <img
            src="chatbot_avatar_1.png"
            alt="chatbot_avatar_1"
            className="h-4 w-4 mr-1"
          />
          <span className="text-xs font-semibold">AI CHAT</span>
        </div>
        <div className="ml-auto flex align-middle items-center font-bold text-xs">
          <button
            onClick={() => {
              setIsChatVisible(false);
            }}
          >
            <HiOutlineX className="w-6 h-6 mr-4" />
          </button>
        </div>
      </div>

      {/* <MainContainer>
        <ChatContainer>
          <MessageList>
            {messages.map((msg, index) => (
              <div
                key={index}
                onClick={() => msg.isCandidate && onMessageClick(msg.message)}
                className={`p-2 mb-2`}
              >
                <Message
                  className={`${
                    msg.isCandidate ? "cursor-pointer question" : ""
                  }`}
                  model={msg}
                />
              </div>
            ))}
          </MessageList>
          <MessageInput
            placeholder="Type message here"
            onSend={onSendMessage}
            attachButton={false}
          />
        </ChatContainer>
      </MainContainer> */}
    </>
  );
};
