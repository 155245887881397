import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { GOSHA_GIDs } from "../gids";

let analytics: any;

export default function setFirebase() {
  const firebaseConfig = {
    apiKey: "AIzaSyCI6g6EmNTngJVKVeWxbqd38E56TNi8Q9U",
    authDomain: "essai-a192f.firebaseapp.com",
    projectId: "essai-a192f",
    storageBucket: "essai-a192f.appspot.com",
    messagingSenderId: "941794971155",
    appId: "1:941794971155:web:b060e4b7155ea1247979e6",
    measurementId: "G-XZZEN08S6P",
  };
  const app = initializeApp(firebaseConfig);
  analytics = getAnalytics(app);
}

export function logAnalyticsEvent(gid: string, eventName: string, params: any) {
  if (GOSHA_GIDs.some((g) => g == gid)) {
    // console.log("Logging event", eventName, "with params", params);
    logEvent(analytics, eventName);
  }
}
