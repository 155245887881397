import { useState, useEffect, useCallback } from "react";
import { fileUpdateComment } from "../axios/file_update_comment";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { Project } from "../types/project";
import { FileOrPage } from "../types/fileorpage";
import { t } from "i18next";

export const CommentModal = ({
  filesOrPages,
}: {
  filesOrPages: FileOrPage[];
}) => {
  const curProject = useSelector<RootState, Project>(
    (state: RootState) => state.currentProject
  );
  const gid = curProject.gid ? curProject.gid : "";

  // ファイル変更反映にuseEffectも必要
  let initialComment = filesOrPages[0]?.comment ? filesOrPages[0].comment : "";
  initialComment = filesOrPages.length > 1 ? "" : initialComment;
  const [newComment, setNewComment] = useState<string>(initialComment);
  useEffect(() => {
    let initialComment = filesOrPages[0]?.comment
      ? filesOrPages[0].comment
      : "";
    initialComment = filesOrPages.length > 1 ? "" : initialComment;
    setNewComment(initialComment);
  }, [filesOrPages]);

  let title = filesOrPages[0]?.file ? filesOrPages[0].file : "";
  title = filesOrPages.length > 1 ? "Multiple Files" : title;

  const onUpload = useCallback(async () => {
    if (!filesOrPages) return;
    const ids = filesOrPages.map((f) => f.id);
    console.log(gid, ids, newComment);
    await fileUpdateComment(gid, ids, newComment);
    // dialogを閉じる
    const dialogElement = document.getElementById(
      "comment-modal"
    ) as HTMLDialogElement;
    if (dialogElement) {
      dialogElement.close();
      window.location.reload();
    }
  }, [gid, filesOrPages, newComment]);

  return (
    <dialog id="comment-modal" className="modal p-4 md:p-0">
      <div
        className="modal-box max-h-fit m-0 h-full md:h-auto w-full md:w-1/3"
        style={{ maxWidth: "none" }}
      >
        <div className="flex items-center mb-8">
          <button className="btn w-40 text-lg main-theme" onClick={onUpload}>
            {t("comment_modal.update")}
          </button>
          <div className="modal-action m-0 ml-auto">
            <form method="dialog">
              {/* if there is a button in form, it will close the modal */}
              <button className="btn">{t("comment_modal.close")}</button>
            </form>
          </div>
        </div>
        {title}
        <div className="flex mt-4">
          <label htmlFor="file_comment" className="p-0 w-20 align-bottom">
            {t("comment_modal.comment")}:
          </label>
          <textarea
            id="file_comment"
            placeholder="write here"
            className="border-b-2 mx-4 w-3/4 focus:outline-none focus:ring-0 focus:border-teal"
            onChange={(e) => {
              setNewComment(e.target.value);
            }}
            rows={3}
            value={newComment}
            maxLength={120}
          />
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
};
