import { Page } from "../../types/page";
import { FileOrPage } from "../../types/fileorpage";
import { HiOutlineTag, HiOutlineAnnotation, HiOutlineDocumentText, HiOutlineDownload } from "react-icons/hi";
import { filesSinglePageFetch } from "../../axios/files_single_page";
import { fileDownloadBlob } from "../../axios/file_download_blob";
import { useCallback, useState, useEffect, createRef, useLayoutEffect } from "react";
import { MESSAGE_KEY } from "../routes/book_reader/pdfviewer";

export const PageCell = ({
  page,
  filesOrPagesEdit,
  setFilesOrPagesEdit,
  setTags,
}: {
  page: Page;
  filesOrPagesEdit: FileOrPage[];
  setFilesOrPagesEdit: React.Dispatch<React.SetStateAction<FileOrPage[]>>;
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const thumb = page.thumb;
  const curPage = page.page.replace("page_", "").replace(".png", "");
  const [meEdit, setMeEdit] = useState<boolean>(false);
  const tagBoxRef = createRef<HTMLDivElement>();
  const [numTagShow, setNumTagShow] = useState<number>(page.tag ? page.tag.length : 0);
  const [tagClientWidth, setTagClientWidth] = useState<number>(220);
  const [tagScrollWidth, setTagScrollWidth] = useState<number>(220);

  // filesEditに自分が含まれているかどうかを判定
  useEffect(() => {
    const meEdit = filesOrPagesEdit.some((f) => f.id === page.id) ? true : false;
    setMeEdit(meEdit);
  }, [filesOrPagesEdit, page]);

  // チェックボックスクリック時の処理: filesEditに自分を追加/削除
  const onChangeCheckBox = useCallback(() => {
    if (meEdit) {
      setFilesOrPagesEdit(filesOrPagesEdit.filter((f) => f.id !== page.id));
    } else {
      setFilesOrPagesEdit([...filesOrPagesEdit, page as FileOrPage]);
    }
  }, [meEdit, page, filesOrPagesEdit, setFilesOrPagesEdit]);

  // タグクリック時の処理: filesOrPagesEditに自分を追加して、タグモーダルを表示
  const onTagClick = useCallback(() => {
    console.log("tag clicked", page.id);
    const dialogElement = document.getElementById("tag-modal") as HTMLDialogElement;
    if (dialogElement) {
      if (!meEdit) {
        setFilesOrPagesEdit([...filesOrPagesEdit, page as FileOrPage]);
      }
      dialogElement.showModal();
    }
  }, [page, meEdit, filesOrPagesEdit, setFilesOrPagesEdit]);

  // コメントクリック時の処理: filesOrPagesEditに自分を追加して、コメントモーダルを表示
  const onCommentClick = useCallback(() => {
    console.log("comment clicked", page.id);
    const dialogElement = document.getElementById("comment-modal") as HTMLDialogElement;
    if (dialogElement) {
      if (!meEdit) {
        setFilesOrPagesEdit([...filesOrPagesEdit, page as FileOrPage]);
      }
      dialogElement.showModal();
    }
  }, [page, meEdit, filesOrPagesEdit, setFilesOrPagesEdit]);

  const onContentClick = useCallback(() => {
    const dialogElement = document.getElementById("page-text-modal") as HTMLDialogElement;
    if (dialogElement) {
      if (!meEdit) {
        setFilesOrPagesEdit([...filesOrPagesEdit, page as FileOrPage]);
      }
      dialogElement.showModal();
      const modalBoxElement = document.getElementById("page-text-modal-box") as HTMLDivElement;
      if (modalBoxElement) {
        console.log("modalBoxElement", modalBoxElement);
        modalBoxElement.scrollTop = 0;
      }
    }
  }, [filesOrPagesEdit, meEdit, page, setFilesOrPagesEdit]);

  const onClickImage = async () => {
    // console.log(page);
    // console.log("pdfUrlFetch", page.key, page.page);
    // const result = await pdfUrlFetch(page.key);
    // console.log(result);
    // window.open(`/pdfview?curpage=${curPage}presigned=${result}`, "_blank");

    const msgObj = {
      key: page.key,
      curPage,
    };
    localStorage.setItem(MESSAGE_KEY, JSON.stringify(msgObj));
    window.open(`/pdfview`, "_blank");
  };

  const onClickDownload = async () => {
    console.log(page);
    console.log("api_url", page.key, page.page);
    const result = await filesSinglePageFetch(page.key);
    console.log(result);
    const blob = await fileDownloadBlob(result);
    const newUrl = window.URL.createObjectURL(blob); // CORS問題がなければ、blobを介する必要はない

    const link = document.createElement("a");
    link.download = "file.pdf";
    link.target = "_blank";
    link.href = newUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(newUrl);
  };

  const onSelectTag = useCallback(
    (tag: string) => {
      setTags((prevTags) => {
        if (prevTags.includes(tag)) {
          return prevTags;
        } else {
          return [...prevTags, tag];
        }
      });
    },
    [setTags]
  );

  useLayoutEffect(() => {
    if (tagBoxRef.current) {
      if (tagBoxRef.current.clientWidth > 200 && tagBoxRef.current.scrollWidth > 200) {
        if (tagBoxRef.current.clientWidth !== tagClientWidth) {
          setTagClientWidth(tagBoxRef.current.clientWidth);
        }
        if (tagBoxRef.current.scrollWidth !== tagScrollWidth) {
          setTagScrollWidth(tagBoxRef.current.scrollWidth);
        }
      }
    }
  }, [tagBoxRef, tagScrollWidth, tagClientWidth]);

  useLayoutEffect(() => {
    if (tagClientWidth > 200 && tagScrollWidth > 200) {
      if (tagClientWidth + 1 < tagScrollWidth) {
        console.log("tagBoxRef.current.clientWidth", tagClientWidth);
        console.log("tagBoxRef.current.scrollWidth", tagScrollWidth);
        setNumTagShow((current) => (current > 0 ? current - 1 : current));
      }
    }
  }, [tagClientWidth, tagScrollWidth]);

  return (
    <div className="mb-2 md:m-2 p-2 border-2 w-full sm:w-64">
      <div
        className="px-auto flex justify-center cursor-pointer"
        onClick={async () => {
          onClickImage();
        }}
      >
        <img
          src={thumb}
          alt="thumbnail"
          className="justify-center aspect-auto w-full h-auto md:h-64 sm:w-52 object-cover md:object-contain"
        />
      </div>
      <div className="mx-2">
        <div className="text-sm mb-4 truncate">{page.file}</div>
        <div className="flex">
          <input
            type="checkbox"
            className="checkbox checkbox-primary my-2"
            checked={meEdit}
            onChange={onChangeCheckBox}
          />
          <span className="text-gray-500 my-2 ml-2 text-base">p{curPage}</span>
          <div className="ml-auto flex">
            <button onClick={onTagClick} className="my-2 main-theme-reverse align-middle items-center">
              <HiOutlineTag className="p-0 m-0 main-theme-reverse align-middle, items-center h-6 w-6" />
            </button>
            <button onClick={onCommentClick}>
              <HiOutlineAnnotation className="text-gray-500 ml-2 h-6 w-6" />
            </button>
            <button onClick={onContentClick}>
              <HiOutlineDocumentText className="text-gray-500 ml-2 h-6 w-6" />
            </button>
            <button onClick={onClickDownload}>
              <HiOutlineDownload className="text-gray-500 ml-2 h-6 w-6" />
            </button>
          </div>
        </div>
        {page.tag ? (
          page.tag.length > 0 ? (
            <div className="relative group">
              <div className="mt-2 h-8 flex" ref={tagBoxRef}>
                {page.tag.map((tag, index) =>
                  index < numTagShow ? (
                    <button
                      key={index}
                      className="my-2 mr-2 px-2 bg-accent rounded-box inline-block whitespace-normal text-white h-6 text-sm font-bold"
                      onClick={() => onSelectTag(tag)}
                    >
                      {tag}
                    </button>
                  ) : null
                )}
                {numTagShow < page.tag.length ? (
                  <button className="my-2 mr-2 px-2 bg-accent rounded-box inline-block whitespace-normal text-white h-6 text-sm font-bold">
                    {"...+" + (page.tag.length - numTagShow)}{" "}
                  </button>
                ) : null}
              </div>
              {numTagShow < page.tag.length ? (
                <div className="absolute top-2 left-2 p-2 -m-4 bg-white border-2 border-gray-200 rounded-box invisible group-hover:visible">
                  {page.tag.map((tag, index) => (
                    <button
                      key={index}
                      className="my-2 mr-2 px-2 bg-accent rounded-box inline-block whitespace-normal text-white h-6 text-sm font-bold"
                      onClick={() => onSelectTag(tag)}
                    >
                      {tag}
                    </button>
                  ))}
                </div>
              ) : null}
            </div>
          ) : null
        ) : null}
      </div>
    </div>
  );
};
