import { api } from "./axios";
import { Project } from "../types/project";

export const projectCreate = async (name: string, comment: string): Promise<string> => {
  try {
    console.log(name, comment);
    const response = await api.projects.projectsCreate({
      name: name,
      comment: comment,
    });
    const message: string = response.data.message;
    console.log(message);
    return message;
  } catch (error) {
    console.log("ERROR!", error);
    if ((error as any).response) {
      console.log((error as any).response.data.message);
      return (error as any).response?.data?.message;
    } else {
      return error as string;
    }
  }
};

export const projectEdit = async (name: string, comment: string, project: Project): Promise<string> => {
  try {
    console.log(name, comment);
    const response = await api.projects.projectsEdit({
      name: name,
      comment: comment,
      project: {
        ...project,
        gid: Number(project.gid),
      },
    });
    const message: string = response.data.message;
    console.log(message);
    return message;
  } catch (error) {
    console.log("ERROR!", error);
    if ((error as any).response) {
      console.log((error as any).response.data.message);
      return (error as any).response?.data?.message;
    } else {
      return error as string;
    }
  }
};
