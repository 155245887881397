import { createSlice } from "@reduxjs/toolkit";

export type Project = {
  name: string;
  gid: string;
  owner: string;
  comment: string;
  last_modify: string;
  pages: number | null;
  size: number | null;
  n_files: number | null;
};

const initialProject: Project = {
  name: "",
  gid: "0",
  owner: "",
  comment: "",
  last_modify: "",
  pages: null,
  size: null,
  n_files: null,
};

export const currentProjectSlice = createSlice({
  name: "currentProject", //match with the name of the slice in store.ts
  initialState: initialProject,
  reducers: {
    setCurrentProject: (state, action) => {
      if (Number.isNaN(action.payload.gid)) return;
      return { ...action.payload };
    },
  },
});

export const { setCurrentProject } = currentProjectSlice.actions;
export const currentProject = currentProjectSlice.reducer;

// slice利用したaction, reducerの作成 actionCreator作れるのは良さそう
// https://zenn.dev/engstt/articles/293e7420c93a18#slice%E3%81%AE%E5%AE%9F%E8%A3%85

// export const currentProject = (state = initialProject, action: any) => {
//   console.log(action);
//   switch (action.type) {
//     case "SET":
//       return action.project;
//     default:
//       return state;
//   }
// };
