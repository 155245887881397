import { Page } from "../../types/page";
import { FileOrPage } from "../../types/fileorpage";
import { HiOutlineStar, HiOutlineAnnotation } from "react-icons/hi";
import { filesSinglePageFetch } from "../../axios/files_single_page";
import { highlight_image } from "../../axios/highlight_image";
import { useCallback, useState, useEffect, createRef, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { Tags } from "../../types/tag";
import { Project } from "../../types/project";
import { RootState } from "../../store";
import useWindowDimensions from "../../lib/window_dimensions";
import { Controlled as ControlledZoom } from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { useTranslation } from "react-i18next";
import { getAnalytics, logEvent } from "firebase/analytics";
import { MESSAGE_KEY } from "../routes/book_reader/pdfviewer";
import { DEKIRU_GIDs, GOSHA_GIDs } from "../../gids";
import { BooksLinkToImpress } from "./books_link_to_impress";

export const BooksCell = ({
  page,
  filesOrPagesEdit,
  setFilesOrPagesEdit,
  onTagSelected,
  tags,
  isZoomVisible,
  setIsZoomVisible,
  setZoomPage,
}: {
  page: Page;
  filesOrPagesEdit: FileOrPage[];
  setFilesOrPagesEdit: React.Dispatch<React.SetStateAction<FileOrPage[]>>;
  onTagSelected: (tag: string) => void;
  tags: string[];
  isZoomVisible: boolean;
  setIsZoomVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setZoomPage: React.Dispatch<React.SetStateAction<Page | null>>;
}) => {
  const { t } = useTranslation();
  const curProject = useSelector<RootState, Project>((state: RootState) => state.currentProject);
  const gid = curProject.gid ? curProject.gid : "";
  const allTags = useSelector<RootState, Tags>((state: RootState) => state.allTags);
  const thumb = page.thumb;
  const curPage = page.page.replace("page_", "").replace(".png", "");
  const [meEdit, setMeEdit] = useState<boolean>(false);
  const tagBoxRef = createRef<HTMLDivElement>();
  const [numTagShow, setNumTagShow] = useState<number>(page.tag ? page.tag.length : 0);
  const [tagClientWidth, setTagClientWidth] = useState<number>(-1);
  const [tagScrollWidth, setTagScrollWidth] = useState<number>(-1);
  const [microTags, setMicroTags] = useState<string[]>([]);
  const [subjectTags, setSubjectTags] = useState<string[]>([]);
  const { morethan640 } = useWindowDimensions();

  // filesEditに自分が含まれているかどうかを判定
  useEffect(() => {
    const meEdit = filesOrPagesEdit.some((f) => f.id === page.id) ? true : false;
    setMeEdit(meEdit);
  }, [filesOrPagesEdit, page]);

  // コメントクリック時の処理: filesOrPagesEditに自分を追加して、コメントモーダルを表示
  const onCommentClick = useCallback(() => {
    console.log("comment clicked", page.id);
    const dialogElement = document.getElementById("comment-modal") as HTMLDialogElement;
    if (dialogElement) {
      if (!meEdit) {
        setFilesOrPagesEdit([...filesOrPagesEdit, page as FileOrPage]);
      }
      dialogElement.showModal();
    }
  }, [page, meEdit, filesOrPagesEdit, setFilesOrPagesEdit]);

  const onClickImagePC = useCallback(async () => {
    const msgObj = {
      key: page.key,
      curPage,
    };
    if (page.thumb.includes("blob:") && page.highlight_num) {
      console.log("thumb is from blob. fetch highlight_image_url");
      const result = await highlight_image(Number(gid), page.highlight_num, "pdf");
      console.log(result);
      window.open(`/pdfview?curpage=${curPage}presigned=${result}`, "_blank");
    } else {
      localStorage.setItem(MESSAGE_KEY, JSON.stringify(msgObj));
      window.open(`/pdfview`, "_blank");
    }
  }, [page, curPage]);

  const handleZoomChange = useCallback((isZoomVisible: boolean) => {
    setIsZoomVisible(isZoomVisible);
    if (isZoomVisible) {
      setZoomPage(page);
    } else {
      setZoomPage(null);
    }
  }, []);

  const onSelectTag = useCallback((tag: string) => {
    onTagSelected(tag);
  }, []);

  useLayoutEffect(() => {
    if (tagBoxRef.current) {
      if (tagBoxRef.current.clientWidth !== tagClientWidth) {
        setTagClientWidth(tagBoxRef.current.clientWidth);
        console.log("Set tagBoxRef.current.clientWidth", tagBoxRef.current.clientWidth);
      }
      if (tagBoxRef.current.scrollWidth !== tagScrollWidth) {
        setTagScrollWidth(tagBoxRef.current.scrollWidth);
        console.log("Set tagBoxRef.current.scrollWidth", tagBoxRef.current.scrollWidth);
      }
    }
  }, [tagBoxRef, tagScrollWidth, tagClientWidth, microTags]);

  // scrollWidthがclientWidthより大きい場合、tagを1つ減らす
  useLayoutEffect(() => {
    if (tagClientWidth + 1 < tagScrollWidth) {
      console.log(
        "reduce numTagShow as tagBoxRef.current.clientWidth < tagBoxRef.current.scrollWidth",
        tagClientWidth,
        tagScrollWidth
      );
      console.log("numTagShow. reduce 1 from here", numTagShow);
      setNumTagShow((current) => (current > 0 ? current - 1 : current));
    }
  }, [tagClientWidth, tagScrollWidth, microTags]);

  // 表示するタグの条件
  const checkTagGroup = useCallback(
    (tag: string) => {
      if (allTags[tag]) {
        console.log("checkTagGroup", tag, allTags[tag]);
        return !["series"].includes(allTags[tag]) && !tags.includes(tag);
      } else {
        return true;
      }
    },
    [allTags, tags]
  );

  // 表示するタグを選択
  useLayoutEffect(() => {
    if (page.tag) {
      const microTags = page.tag.filter((tag) => checkTagGroup(tag));
      console.log("microTags", microTags);
      setMicroTags(microTags);
      setNumTagShow(microTags.length);
    }
  }, [page.tag, checkTagGroup]);

  // subjectTagのみを取得
  useEffect(() => {
    const subjectTags = Object.keys(allTags).filter((tag) => allTags[tag] === "subject");
    setSubjectTags(subjectTags);
    console.log("subjectTags:", subjectTags);
  }, [allTags]);

  return (
    <div className="sm:w-64 min-h-80 h-fit border-2 sm:mb-4">
      <div
        className="px-auto py-2 flex justify-center cursor-pointer"
        onClick={async () => {
          morethan640 && onClickImagePC();
        }}
      >
        {morethan640 ? (
          <img src={thumb} alt="thumbnail" className="justify-center aspect-auto w-full w-52 h-64 object-contain" />
        ) : (
          <img
            src={thumb}
            alt="thumbnail"
            className="justify-center aspect-auto w-full w-52 h-64 object-contain"
            onClick={() => handleZoomChange(!isZoomVisible)}
          />
        )}
      </div>
      <div className="mx-2 sm:mx-4">
        {/* <div className="text-sm my-2 truncate font-bold">{page.file}</div> */}
        <div className="text-xs sm:text-sm h-8 sm:h-10 line-clamp-2 mb-2 font-bold">
          {page.file.replace(".pdf", "")}
        </div>
        <div className="flex align-middle items-center mb-2">
          <div className="text-xs sm:text-sm text-gray-500 cursor-pointer" onClick={async () => onClickImagePC()}>
            {Number(curPage) === 1 ? t("books.cover") : `p${curPage}`}
          </div>
          <div className="ml-auto flex">
            {DEKIRU_GIDs.some((g) => g == gid) ? (
              <BooksLinkToImpress page={page}></BooksLinkToImpress>
            ) : (
              <>
                <button className="main-theme-reverse align-middle items-center">
                  <HiOutlineStar className="text-gray-500 ml-2 h-6 w-6" />
                </button>
                <button onClick={onCommentClick}>
                  <HiOutlineAnnotation className="text-gray-500 ml-2 h-6 w-6" />
                </button>
              </>
            )}
          </div>
        </div>
        {page.tag ? (
          microTags.length > 0 ? (
            <div className="relative group mb-1">
              <div className="h-8 flex w-full sm:mb-4" ref={tagBoxRef}>
                {microTags.map((tag, index) =>
                  index < numTagShow ? (
                    <button
                      key={index}
                      className={
                        subjectTags.includes(tag)
                          ? "w-fit my-1 sm:my-2 mr-2 sm:mr-4 px-2 text-xs sm:text-sm h-5 sm:h-6 bg-purple-400 inline-block whitespace-nowrap text-white font-bold"
                          : "w-fit my-1 sm:my-2 mr-2 sm:mr-4 px-2 text-xs sm:text-sm h-5 sm:h-6 bg-cyan-400 rounded-box inline-block whitespace-nowrap text-white font-bold"
                      }
                      onClick={() => onSelectTag(tag)}
                    >
                      {tag}
                    </button>
                  ) : null
                )}
                {numTagShow < microTags.length ? (
                  <button className="w-fit my-1 sm:my-2 mr-2 sm:mr-4 px-2 text-xs sm:text-sm h-5 sm:h-6 bg-cyan-400 rounded-box inline-block whitespace-nowrap text-white font-bold">
                    {" "}
                    {"...+" + (microTags.length - numTagShow)}{" "}
                  </button>
                ) : null}
              </div>
              {numTagShow < microTags.length ? (
                <div className="absolute top-2 left-2 w-fit p-2 -m-4  bg-white border-2 border-gray-200 rounded-box invisible group-hover:visible">
                  {microTags.map((tag, index) => (
                    <button
                      key={index}
                      className={
                        subjectTags.includes(tag)
                          ? "w-fit my-1 sm:my-2 mr-2 sm:mr-4 px-2 text-xs sm:text-sm h-5 sm:h-6 bg-purple-400 inline-block whitespace-nowrap text-white font-bold"
                          : "w-fit my-1 sm:my-2 mr-2 sm:mr-4 px-2 text-xs sm:text-sm h-5 sm:h-6 bg-cyan-400 rounded-box inline-block whitespace-nowrap text-white font-bold"
                      }
                      onClick={() => onSelectTag(tag)}
                    >
                      {tag}
                    </button>
                  ))}
                </div>
              ) : null}
            </div>
          ) : null
        ) : null}
      </div>
    </div>
  );
};
