// export const POOL_ID = "us-east-1_x9jmu3OOk";
// export const POOL_CLIENT_ID = "3j27g819oru1fjo3c7u608oqhe";

export const API_BASE_URL = "https://demoapi.essai-test.com/"; //all demo
// export const API_BASE_URL = "https://dekiruapi.essai-test.com/"; //dekiru demo
// export const API_BASE_URL = "http://34.201.82.243:3020/"; //開発instance
// export const API_BASE_URL = "http://54.205.49.26:3020/"; //node-express-gpt
// export const API_BASE_URL = "http://localhost:3020/";

// Dev
export const POOL_ID = "us-east-1_EnbkFmpGi";
export const POOL_CLIENT_ID = "akgbu45nnnei866n9t4htopra";
