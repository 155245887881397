import { api } from "./axios";
import { MyFile } from "../types/file";
import type { FileList, File } from "./client";

export const filesFetch = async (
  gid: string,
  sortBy: string,
  sortBool: boolean,
  from: number,
  folder: string,
  deleted: boolean
): Promise<[MyFile[], number]> => {
  const response = await api.files.filesFetch({
    gid: Number(gid),
    sortby: sortBy,
    sortbool: sortBool,
    from: from,
    folder: folder,
    deleted: deleted,
  });

  const files: File[] = (response.data as FileList)?.files ?? [];
  const total: number = (response.data as FileList)?.total ?? 0;
  console.log("filesSearch: total", total);

  const myFiles: MyFile[] = files.map((file: File) => {
    return {
      id: file.id ?? "0",
      folder: file.folder ?? "",
      file: file.file ?? "",
      pages: String(file.pages) ?? "0",
      page: "",
      modified_at: file.modified_at ?? 0,
      type: file.type ?? "",
      status: file.status ?? "",
      key: file.key ?? "",
      comment: file.comment ?? "",
      size: typeof file.size === 'string' ? parseFloat(file.size) : file.size ?? 0,
      content: "",
      tag: file.tag ?? [],
    };
  });

  return [myFiles, total];
};

export const filesSearch = async (
  gid: string,
  sortBy: string,
  sortBool: boolean,
  from: number,
  folder: string,
  term: string,
  deleted: boolean
): Promise<[MyFile[], number]> => {
  const response = await api.files.filesSearch({
    gid: Number(gid),
    sortby: sortBy,
    sortbool: sortBool,
    from: from,
    folder: folder,
    term: term,
    deleted: deleted,
  });
  const files: File[] = (response.data as FileList)?.files ?? [];
  const total: number = (response.data as FileList)?.total ?? 0;
  console.log("filesSearch: total", total);

  const myFiles: MyFile[] = files.map((file: File) => {
    return {
      id: file.id ?? "0",
      folder: file.folder ?? "",
      file: file.file ?? "",
      pages: String(file.pages) ?? "0",
      page: "",
      modified_at: file.modified_at ?? 0,
      type: file.type ?? "",
      status: file.status ?? "",
      key: file.key ?? "",
      comment: file.comment ?? "",
      size: typeof file.size === 'string' ? parseFloat(file.size) : file.size ?? 0,
      content: "",
      tag: file.tag ?? [],
    };
  });

  return [myFiles, total];
};
