import { UploadFile } from "../../types/file";
import { uploadFileToS3 } from "../../axios/uploadFileToS3";
import { fileUpload } from "../../axios/file_upload";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { Project } from "../../types/project";
import { HiOutlineCheck } from "react-icons/hi";
import { t } from "i18next";

export const FilesUploadModal = ({
  fileList,
  folderBool,
  replace,
  resetUploadCondition,
}: {
  fileList: FileList | null;
  folderBool: boolean;
  replace: boolean | null;
  resetUploadCondition: () => void;
}) => {
  const curProject = useSelector<RootState, Project>(
    (state: RootState) => state.currentProject
  );
  const gid = curProject.gid ? curProject.gid : "";
  const [progress, setProgress] = useState<number>(0);
  const [filesDoneList, setFilesDoneList] = useState<string[]>([]);

  const onUpload = useCallback(async () => {
    // UploadFile形式に変更して、signed urlを取得する
    let total_size = 0;
    if (!fileList) return;
    for (let i = 0; i < fileList.length; i++) {
      const _file = fileList[i];
      total_size += _file.size;
    }

    // signed URL API取得前の進捗が1
    let progress = 1;
    setProgress(progress);

    // signed urlにファイルをアップロードする
    let progress_size = 0;
    let newFilesDoneList: string[] = [];
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      const name = folderBool ? file.webkitRelativePath : file.name;
      const uploadFile: UploadFile = {
        file: name,
        size: file.size,
        type: file.type,
      };
      const [signedUrl, overlapped] = await fileUpload(
        uploadFile,
        gid,
        replace ? true : false
      );

      console.log("upload file to singedURL:", file, signedUrl, overlapped);
      await uploadFileToS3(file, signedUrl);
      newFilesDoneList = newFilesDoneList
        .concat([name])
        .filter((str) => str !== "");
      console.log("newFilesDoneList: ", newFilesDoneList);
      setFilesDoneList(newFilesDoneList);

      progress_size += file.size;
      progress = Math.min(
        Math.max(Math.floor((progress_size / total_size) * 100), progress + 1),
        100
      );
      console.log("progress: ", progress);
      setProgress(progress);
    }

    // dialogを閉じる
    setProgress(0);
    setFilesDoneList([]);
    resetUploadCondition();
    const dialogElement = document.getElementById(
      "upload-box"
    ) as HTMLDialogElement;
    if (dialogElement) {
      dialogElement.close();
    }
    window.location.reload();
  }, [fileList, resetUploadCondition, folderBool, gid, replace]);

  return (
    <dialog id="upload-box" className="modal">
      <div className="modal-box m-0 w-3/4" style={{ maxWidth: "none" }}>
        <div className="flex items-center mb-8">
          <button className="btn w-40 text-lg main-theme" onClick={onUpload}>
            ↑ {t("files.upload")}
          </button>
          <div className="modal-action m-0 ml-auto">
            <form method="dialog">
              {/* if there is a button in form, it will close the modal */}
              <button className="btn" onClick={resetUploadCondition}>
                {t("files.close")}
              </button>
            </form>
          </div>
        </div>
        {progress > 0 && (
          <div>
            <div className="w-full flex justify-center content-center h-8 text-2xl">
              <span className="loading loading-spinner loading-sm"></span>{" "}
              {t("upload_modal.wait")}
            </div>
            <div className="flex items-center align-middle justify-center justify-items-center">
              <span className="mr-4">{progress}%... </span>
              <progress
                className="progress w-3/4 h-4 progress-accent"
                value={progress}
                max="100"
              ></progress>
            </div>
          </div>
        )}

        <h3 className="font-bold text-lg main-theme-reverse">
          {t("upload_modal.filestoupload")}
        </h3>
        <table className="table w-full">
          <thead>
            <tr>
              <th>{t("upload_modal.File")}</th>
              <th>{t("upload_modal.Size")}</th>
              <th>{t("upload_modal.Type")}</th>
              <th>{t("upload_modal.Progress")}</th>
            </tr>
          </thead>
          <tbody>
            {fileList &&
              Array.from(fileList).map((file, index) => {
                return (
                  <tr key={index}>
                    <td>{folderBool ? file.webkitRelativePath : file.name}</td>
                    <td>{file.size}</td>
                    <td>{file.type}</td>
                    <td>
                      {filesDoneList.includes(file.webkitRelativePath) ||
                        filesDoneList.includes(file.name) ? (
                        <span>
                          <HiOutlineCheck className="main-theme-reverse h-6 w-6" />
                        </span>
                      ) : null}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button onClick={resetUploadCondition}>close</button>
      </form>
    </dialog>
  );
};
