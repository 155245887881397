import { FilesTableCell } from "./file_cell";
import { FilesTableHeader } from "./files_table_header";
import { MyFile } from "../../types/file";
import { SortCondition } from "../../types/sortcondition";
import { FileOrPage } from "../../types/fileorpage";

export const FilesTable = ({
  files,
  totalPagination,
  sortCondition,
  setSortCondition,
  curPagination,
  setCurPagination,
  folderList,
  setFolderList,
  filesOrPagesEdit,
  setFilesOrPagesEdit,
}: {
  files: MyFile[];
  totalPagination: number;
  sortCondition: SortCondition;
  setSortCondition: React.Dispatch<React.SetStateAction<SortCondition>>;
  curPagination: number;
  setCurPagination: React.Dispatch<React.SetStateAction<number>>;
  folderList: string[];
  setFolderList: React.Dispatch<React.SetStateAction<string[]>>;
  filesOrPagesEdit: FileOrPage[];
  setFilesOrPagesEdit: React.Dispatch<React.SetStateAction<FileOrPage[]>>;
}) => {
  const curPaginationClick = (curPagination: number) => {
    setCurPagination(curPagination);
  };

  return (
    <div>
      <table className="table">
        <FilesTableHeader
          files={files}
          sortCondition={sortCondition}
          setSortCondition={setSortCondition}
          setCurPagination={setCurPagination}
          setFilesOrPagesEdit={setFilesOrPagesEdit}
        ></FilesTableHeader>
        <tbody className="text-base">
          {files.map((file, index) => (
            <FilesTableCell
              key={index}
              file={file}
              folderList={folderList}
              filesOrPagesEdit={filesOrPagesEdit}
              setFolderList={setFolderList}
              setFilesOrPagesEdit={setFilesOrPagesEdit}
            />
          ))}
        </tbody>
      </table>
      {totalPagination > 1 ? (
        <div className="mt-8 join justify-center w-full">
          {Array.from({ length: totalPagination }, (_, index) => (
            <button
              key={index}
              className={index + 1 === curPagination ? "join-item btn btn-active" : "join-item btn"}
              onClick={() => curPaginationClick(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default FilesTable;

// thはpaddingで高さとっている
