import { createSlice } from "@reduxjs/toolkit";

export type Tags = {
  [key: string]: string;
};

const allTagsSlice = createSlice({
  name: "allTags",
  initialState: {} as Tags,
  reducers: {
    setAllTags: (state, action) => {
      return action.payload;
    },
  },
});

export const { setAllTags } = allTagsSlice.actions;
export const allTags = allTagsSlice.reducer;
