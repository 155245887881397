import { HiDotsVertical } from "react-icons/hi";
import { Project } from "../../types/project";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { setCurrentProject } from "../../types/project";
import { t } from "i18next";

const ProjectCard = ({
  project,
  setEditProject,
}: {
  project: Project;
  setEditProject: React.Dispatch<React.SetStateAction<Project | null>>;
}) => {
  const curProject = useSelector<RootState, Project>((state: RootState) => state.currentProject);
  console.log("curProject", curProject);
  const curProjectId = curProject.gid;
  const dispatch = useDispatch();

  const _setCurProject = (project: Project) => {
    dispatch(setCurrentProject(project));
  };

  const n_files_final = project.n_files ? project.n_files : "";
  const size_final = project.size ? Math.round(project.size / (1024 * 1024)) : "";
  const pages_final = project.pages ? project.pages : "";
  return (
    <div className="m-2 p-0 w-80 inline-block rounded-none border-2">
      <div
        className={
          project.gid === curProjectId
            ? "py-2 px-4 flex items-center align-middle main-theme"
            : "py-2 px-4 flex items-center align-middle main-theme-reverse"
        }
      >
        <div className="text-xl font-bold cursor-pointer" onClick={() => _setCurProject(project)}>
          {project.name}
        </div>
        <div className="ml-auto dropdown dropdown-bottom">
          <div
            tabIndex={0}
            role="button"
            className={project.gid === curProjectId ? "btn border-0 main-theme" : "btn border-0 main-theme-reverse"}
          >
            <HiDotsVertical className="ml-auto"></HiDotsVertical>
          </div>
          <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow rounded-box main-theme-reverse w-52">
            <li
              onClick={() => {
                setEditProject(project);
              }}
            >
              <a>{t("myprojects.edit")}</a>
            </li>
            <li>
              <a>{t("myprojects.setting")}</a>
            </li>
          </ul>
        </div>
      </div>
      <hr></hr>
      <div className="p-2 font-normal text-gray-700">
        <p className="py-2">gid: {project.gid}</p>
        <p className="py-2">{t("myprojects.card_filesuploaded")}: {n_files_final}</p>
        <p className="py-2">{t("myprojects.card_uploadedsize")}: {size_final} MB</p>
        <p className="py-2">{t("myprojects.card_pagesprocessed")}: {pages_final}</p>
        <p className="py-2">{t("myprojects.card_lastmodified")}: {project.last_modify.replace(/T/, " ").replace(/\..+/, "")}</p>
        <p className="py-2">{t("myprojects.card_privilege")}: Owner</p>
        <p className="py-2">{t("myprojects.card_comment")}: {project.comment}</p>
        <p className="py-2">{t("myprojects.card_sharedwith")}:</p>
      </div>
      <hr></hr>
      <div className="overflowhidden my-2 px-2">
        <span className="py-2">{t("myprojects.card_owner")}: {project.owner}</span>
      </div>
    </div>
  );
};
export default ProjectCard;
