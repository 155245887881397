import { api } from "./axios";
import { Page as ResPage, PagesRecommendList } from "./client";
import { Page } from "../types/page";

export const pagesRecommend = async (gid: string, subject: string = "Python", review: boolean): Promise<Page[]> => {
  const response = await api.pages.pagesRecommend({
    gid: Number(gid),
    subject: subject,
    review: review
  });
  const resPages: ResPage[] = (response.data as PagesRecommendList).pages ?? [];
  console.log(resPages);

  const pages: Page[] = resPages.map((resPage: ResPage) => {
    return {
      id: resPage.id ?? "0",
      folder: resPage.folder ?? "",
      file: resPage.file ?? "",
      modified_at: Number(resPage.modified_at) ?? 0,
      key: resPage.key ?? "",
      p_num: resPage.p_num ?? 0,
      page: resPage.page ?? "",
      content: resPage.content ?? "",
      comment: resPage.comment ?? "",
      thumb: resPage.thumb ?? "",
      tag: [],
    };
  });

  return pages;
};
