import { fetchAuthSession } from "aws-amplify/auth";

// authentication tokenをfetchAuthSessionから取得
export const getAuthInfo = async (): Promise<string> => {
  try {
    const session = await fetchAuthSession();
    // console.log(session);
    const authToken = session.tokens?.idToken?.toString()!;
    return authToken;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to get auth token. Might be not signed in.");
  }
};

// Athenticator component
export const components = {
  Header() {
    return (
      <div>
        <img src="essentia-ai-logo.png" className="px-16 py-4 justify-center" alt="essentia-ai-logo" />
      </div>
    );
  },
};

// Athenicator form fields
export const formFields = {
  signUp: {
    email: {
      label: "Email:",
      placeholder: "Enter your Email:",
      isRequired: true,
      order: 1,
    },
    username: {
      label: "Username:",
      placeholder: "Enter your Username:",
      isRequired: true,
      order: 2,
    },
    password: {
      label: "Password:",
      placeholder: "Enter your Password:",
      isRequired: true,
      order: 3,
    },
    confirm_password: {
      label: "Confirm Password:",
      isRequired: false,
      order: 4,
    },
  },
};

// https://docs.aws.amazon.com/cognito/latest/developerguide/cognito-scenarios.html#scenario-backend
// https://docs.aws.amazon.com/cognito/latest/developerguide/amazon-cognito-user-pools-using-the-id-token.html
// https://docs.amplify.aws/react/build-a-backend/auth/connect-your-frontend/manage-user-sessions/
// https://docs.amplify.aws/gen1/react/build-a-backend/restapi/customize-authz/
