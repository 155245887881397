import { filesSinglePageFetch } from "../axios/files_single_page";
import { Page } from "../types/page";
import { useEffect, useLayoutEffect, useState } from "react";
import { HiOutlineX, HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi";
import Glide from "@glidejs/glide";
import "../../node_modules/@glidejs/glide/dist/css/glide.core.min.css";
import "../../node_modules/@glidejs/glide/dist/css/glide.theme.min.css";

export const MobileBookViewer = ({
  zoomPage,
  setIsZoomVisible,
}: {
  zoomPage: Page | null;
  setIsZoomVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  console.log("MobileBookViewer launched", zoomPage);
  const [urls, setUrls] = useState<string[]>(["", "", "", "", ""]);
  const [curPage, setCurPage] = useState<number>(0);

  const handleClose = () => {
    console.log("MobileBookViewer closed");
    const modal = document.getElementById("mobile-book-viewer-modal") as HTMLDialogElement;
    if (modal) {
      setUrls(["", "", "", "", ""]);
      setIsZoomVisible(false);
      setCurPage(0);
      modal.close();
    }
  };

  useEffect(() => {
    if (curPage == 0) return;
    const startAt = curPage == 1 ? 0 : curPage == 2 ? 1 : 2;

    // glideを初期化
    const glide = new Glide(".glide", {
      startAt: startAt,
      perView: 1,
      type: "slider",
      focusAt: "center",
      gap: 0,
      rewind: false,
    });
    glide.mount();

    return () => {
      glide.destroy();
    };
  }, [curPage]);

  useEffect(() => {
    //　表示するページ番号を取得。どの場合も全5ページ
    const curPage = zoomPage?.p_num ? zoomPage.p_num : 1;
    const page_numbers =
      curPage == 1
        ? [1, 2, 3, 4, 5]
        : curPage == 2
        ? [curPage - 1, curPage, curPage + 1, curPage + 2, curPage + 3]
        : [curPage - 2, curPage - 1, curPage, curPage + 1, curPage + 2];

    const startAt = curPage == 1 ? 0 : curPage == 2 ? 1 : 2;
    const curPageThumb = zoomPage?.thumb ? zoomPage.thumb : "";
    const initialURLs = ["", "", "", "", ""];
    initialURLs[startAt] = curPageThumb;
    setUrls(initialURLs);
    setCurPage(curPage);

    // 画像のURLを取得
    const fetchUrls = async () => {
      const key = zoomPage?.key ? zoomPage.key : "";
      if (key === "") return;
      for (let i = 0; i < page_numbers.length; i++) {
        const p_num = page_numbers[i];
        const url = await filesSinglePageFetch(key, p_num, "png");
        setUrls((prev) => {
          const newUrls = [...prev];
          newUrls[i] = url;
          return newUrls;
        });
      }
    };
    fetchUrls();
  }, [zoomPage]);

  return (
    <dialog style={{ zIndex: 20 }} id="mobile-book-viewer-modal" className="modal p-4">
      <div className="fixed top-6 right-6 border-none">
        <button className="bg-white rounded-2xl border-none" onClick={() => handleClose()}>
          <HiOutlineX className="h-8 w-8" />
        </button>
      </div>
      <div className="modal-box m-0 p-0 w-full rounded-xl" style={{ maxWidth: "none" }}>
        <div className="glide">
          <div className="glide__track" data-glide-el="track">
            <ul className="glide__slides flex" style={{ touchAction: "auto" }}>
              {Array.from(Array(5), (v, k) => k).map((i) => (
                <li className="glide__slide slider" style={{ touchAction: "auto" }}>
                  <img
                    src={urls[i]}
                    className="bg-white w-full"
                    style={{ touchAction: "auto", pointerEvents: "none" }}
                  />
                </li>
              ))}
            </ul>
            <div className="glide__bullets" data-glide-el="controls[nav]">
              {Array.from(Array(5), (v, k) => k).map((i) => (
                <button className="glide__bullet" data-glide-dir={"=" + i}></button>
              ))}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center content-center">
          <p className="text-slate-500 text-sm">タップしたページの前後5ページまで閲覧できます</p>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button onClick={() => handleClose()}>close</button>
      </form>
    </dialog>
  );
};
