import { HiChevronDown, HiOutlineX } from "react-icons/hi";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export const PageStartEndButton = ({
  pageStartEnd,
  setPageStartEnd,
}: {
  pageStartEnd: number[];
  setPageStartEnd: React.Dispatch<React.SetStateAction<number[]>>;
}) => {
  const { t } = useTranslation();

  // ページ開始・終了条件を設定する
  const onPageApply = useCallback(() => {
    const pageStartElement = document.getElementById("page_start") as HTMLInputElement;
    const pageEndElement = document.getElementById("page_end") as HTMLInputElement;
    const pageStart = pageStartElement ? parseInt(pageStartElement.value) : 0;
    const pageEnd = pageEndElement ? parseInt(pageEndElement.value) : 0;
    setPageStartEnd([pageStart, pageEnd]);
    document.querySelectorAll(".dropdown").forEach(function (dropdown) {
      const dropdownElement = dropdown as HTMLDetailsElement;
      dropdownElement.open = false;
    });
  }, [setPageStartEnd]);

  const onResetPageStartEnd = useCallback(() => {
    setPageStartEnd([0, 0]);
    const pageStartElement = document.getElementById("page_start") as HTMLInputElement;
    const pageEndElement = document.getElementById("page_end") as HTMLInputElement;
    if (pageStartElement) {
      pageStartElement.value = "";
    }
    if (pageEndElement) {
      pageEndElement.value = "";
    }
  }, [setPageStartEnd]);

  return (
    <div className="hidden sm:hidden md:block">
      <details className="dropdown w-28 mr-4">
        <summary className="btn w-full px-4 main-theme-reverse-sub font-normal">
          {pageStartEnd[0] === 0 && pageStartEnd[1] === 0 ? (
            <>
              {t("pagesearch.Pages")} <HiChevronDown />
            </>
          ) : (
            <button
              className="flex items-center align-middle justify-center w-full h-full"
              onClick={onResetPageStartEnd}
            >
              <HiOutlineX className="-ml-4 mr-2 w-4 h-4" />
              {`${pageStartEnd[0]} - ${pageStartEnd[1]}`}{" "}
            </button>
          )}
        </summary>
        <ul className="menu dropdown-content z-[1] w-40 main-theme-reverse border-2 ">
          <li className="hover:bg-white focus:bg-white active:bg-white bg-white">
            <div className="h-12 flex items-center align-middle">
              <span className="text-xs"> {t("pagesearch.page_start")}</span>
              <input
                id="page_start"
                type="number"
                min={0}
                className="ml-auto w-10 text-sm border-none focus:border-none focus:outline-none"
              ></input>
            </div>
          </li>
          <li role="button" className=" focus:bg-white active:bg-white bg-white focus-visible:bg-white">
            <div
              role="button"
              className="h-12 flex items-center align-middle focus:bg-white active:bg-white bg-white focus-visible:bg-white"
            >
              <span className="text-xs">{t("pagesearch.page_end")}</span>
              <input
                id="page_end"
                type="number"
                min={0}
                className="ml-auto w-10 text-sm border-none focus:border-none focus:outline-none"
              ></input>
            </div>
          </li>
          <li className="hover:bg-white focus:bg-white active:bg-white bg-white">
            <button
              id="page_apply"
              className="text-normal w-full h-full main-theme text-justify justify-center"
              onClick={onPageApply}
            >
              {t("pagesearch.apply")}
            </button>
          </li>
        </ul>
      </details>
    </div>
  );
};
