import { t } from "i18next";
import { FileOrPage } from "../../types/fileorpage";

export const PageContentModal = ({
  filesOrPages,
}: {
  filesOrPages: FileOrPage[];
}) => {
  return (
    <dialog id="page-text-modal" className="modal p-4 md:p-0">
      <div
        id="page-text-modal-box"
        className="modal-box max-h-fit m-0 h-full md:h-auto w-full md:w-1/3"
        style={{ maxWidth: "none" }}
      >
        <div className="flex items-center">
          <h3 className="font-bold text-lg ">{t("pagecontent_modal.title")}</h3>
          <div className="modal-action m-0 ml-auto">
            <form method="dialog">
              {/* if there is a button in form, it will close the modal */}
              <button className="btn">{t("pagecontent_modal.close")}</button>
            </form>
          </div>
        </div>
        <div className="py-4 text-base">
          {filesOrPages.length > 0
            ? filesOrPages.map((page, index) => (
                <div key={index} className="mb-8">
                  <h4 className="font-bold">
                    {page.file} p
                    {page.page.replace("page_", "").replace(".png", "")}
                  </h4>
                  <p>{page.content}</p>
                </div>
              ))
            : null}
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
};
