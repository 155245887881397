import { HiChevronDown, HiChevronUp } from "react-icons/hi";

export const FilesTableHeaderColumn = ({
  title,
  me,
  order,
  sortBy,
  sortClick,
  className,
}: {
  title: string;
  me: string;
  order: boolean;
  sortBy: string;
  sortClick: Function;
  className: string;
}) => {
  return (
    <th className={className}>
      {title}
      <button className={order ? "hidden" : "w-12 h-12"} onClick={() => sortClick(me)}>
        <HiChevronDown className={sortBy === me ? "bg-blue-100" : ""}></HiChevronDown>
      </button>
      <button className={order ? "w-12 h-12" : "hidden"} onClick={() => sortClick(me)}>
        <HiChevronUp className={sortBy === me ? "bg-blue-100" : ""}></HiChevronUp>
      </button>
    </th>
  );
};
