import { useEffect, useState, useCallback } from "react";
import ProjectCard from "../projects/project_card";
import "../../App.css";
import { Project } from "../../types/project";
import { projectsFetch } from "../../axios/projects_fetch";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentProject } from "../../types/project";
import { RootState } from "../../store";
import { CreateProjectModal } from "../projects/project_create_modal";
import { t } from "i18next";

function MyProjects() {
  const curProject = useSelector<RootState, Project>((state: RootState) => state.currentProject);
  const gid = curProject.gid;
  console.log("curProject", curProject);
  const dispatch = useDispatch();
  const [projects, setProjects] = useState<Project[]>([]);
  const [editProject, setEditProject] = useState<Project | null>(null);

  const onClickCreateProject = () => {
    setEditProject(null);
    const dialogElement = document.getElementById("create-project-modal") as HTMLDialogElement;
    if (dialogElement) {
      dialogElement.showModal();
    }
  };

  const fetchData = useCallback(async () => {
    try {
      const projects = await projectsFetch();
      console.log(projects);
      setProjects(projects);

      const projectGids = projects.map((project) => project.gid);
      console.log("curGID", gid, parseInt(gid));
      if (projects.length > 0 && (!projectGids.includes(gid) || parseInt(gid) < 10000)) {
        console.log("setCurrentProject", projects[0]);
        dispatch(setCurrentProject(projects[0]));
      }
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, gid]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (editProject) {
      const dialogElement = document.getElementById("create-project-modal") as HTMLDialogElement;
      if (dialogElement) {
        dialogElement.showModal();
      }
    }
  }, [editProject]);

  return (
    <div>
      <h1 className="mb-8 font-bold main-theme-reverse text-2xl">My Project</h1>
      <button className="btn m-2 main-theme border-2" onClick={onClickCreateProject}>
        + {t("myprojects.new_project")}
      </button>
      <div className="card-folder">
        {projects.map((project) => (
          <ProjectCard key={project.gid} project={project} setEditProject={setEditProject} />
        ))}
      </div>
      <CreateProjectModal editProject={editProject}></CreateProjectModal>
    </div>
  );
}

export default MyProjects;

// function timeout(ms: number) {
//   return new Promise((resolve) => setTimeout(resolve, ms));
// }
