import { useTranslation } from "react-i18next";

export const PagesSortButton = ({ setSortBy }: { setSortBy: React.Dispatch<React.SetStateAction<string>> }) => {
  const { t } = useTranslation();
  return (
    <select
      className="select w-32 md:w-56 main-theme-reverse-sub"
      onChange={(e) => {
        setSortBy(e.target.value);
        // sort by Relevance, Date Modified, FileName↓, FileName↑
      }}
    >
      <option>{t("pagesearch.sort_date_modified")}</option>
      <option>{t("pagesearch.sort_relevance")}</option>
      <option>{t("pagesearch.sort_filename_desc")}</option>
      <option>{t("pagesearch.sort_filename_asc")}</option>
    </select>
  );
};
