import { HiOutlineX } from "react-icons/hi";
import { useCallback, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Tags } from "../../types/tag";
import { RootState } from "../../store";
import useWindowDimensions from "../../lib/window_dimensions";

export const BooksTagCondition = ({
  tags,
  setTags,
}: {
  tags: string[];
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const { morethan640 } = useWindowDimensions();
  const [tagsMicro, setTagsMicro] = useState<string[]>([]);
  const allTags = useSelector<RootState, Tags>((state: RootState) => state.allTags);

  // タグを外す
  const onUnSelectTag = useCallback(
    (tag: string) => {
      setTags((prevTags) => {
        return prevTags.filter((prevTag) => prevTag !== tag);
      });
    },
    [setTags]
  );

  useEffect(() => {
    let tagsMicro: string[] = [];
    if (morethan640) {
      tagsMicro = tags.filter((tag) => !["series", "subject"].includes(allTags[tag]));
    } else {
      tagsMicro = tags.filter((tag) => !["series"].includes(allTags[tag]));
    }
    setTagsMicro(tagsMicro);
  }, [tags, allTags]);

  return (
    <div className="flex">
      {tagsMicro.length > 0 ? (
        <div className="sm:bg-cyan-100 flex items-center align-middle mr-4 px-2">
          {tagsMicro.map((tag, index) => (
            <button
              key={index}
              className="my-1 sm:my-2 mx-1 text-xs sm:text-sm px-2 bg-inherent rounded-box text-[#325278] border border-[#325278] h-6 flex items-center align-middle truncate"
              onClick={() => onUnSelectTag(tag)}
            >
              <HiOutlineX className="x-6 h-6 mr-1"></HiOutlineX>
              {tag}
            </button>
          ))}
        </div>
      ) : null}
    </div>
  );
};
