import { ChatMessage } from "../../types/aichat";

export const getUserChatMessage = (messageContent: string): ChatMessage => {
  return {
    message: messageContent,
    sentTime: new Date().toLocaleTimeString(),
    isOutgoing: true,
  };
};

export const getChatMessage = (tag: string): ChatMessage => {
  return {
    message: tag + "ですね！関連する本の表紙を出してみました。",
    sentTime: new Date().toLocaleTimeString(),
  };
};

export const getWithSentTimeMessages = (node: ChatMessage[]): ChatMessage[] => {
  node.map((n) => {
    n.sentTime = new Date().toLocaleTimeString();
  })
  return node;
};
