import { Link } from "react-router-dom";
import {
  HiOutlineFolder,
  HiOutlineArchive,
  HiSearch,
  HiOutlineTrash,
  HiOutlineQuestionMarkCircle,
  HiOutlineUser,
  HiOutlineChevronDoubleLeft,
  HiLogout,
} from "react-icons/hi";
import { signOut } from "aws-amplify/auth";
import { Project } from "../../types/project";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { useTranslation } from "react-i18next";
import { setLang } from "../../types/lang";
import { setSidebar } from "../../types/sidebar";

function MySidebar() {
  const { t } = useTranslation();
  const curProject = useSelector<RootState, Project>((state: RootState) => state.currentProject);
  const myProjectTitle = curProject.name ? curProject.name : t("sidebar.projects");
  const dispatch = useDispatch();
  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  const onLogOut = async () => {
    await dispatch({ type: "USER_LOGOUT" });
    await sleep(1000);
    await signOut();
  };

  const onLangClick = (lang: string) => {
    dispatch(setLang({ lang: lang }));
  };

  const onHideSidebar = () => {
    console.log("onHideSidebar");
    dispatch(setSidebar({ sidebar: false }));
  };

  return (
    <div id="mysidebar" className="fixed top-0 left-0 bottom-0 drawer w-0 lg:w-64 lg:drawer-open">
      <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />

      <div className="drawer-side top-0 bottom-0 m-0">
        {/* <label htmlFor="my-drawer-2" aria-label="close sidebar" className="drawer-overlay"></label>  */}
        <div className="top-0 bottom-0 m-0 p-0 w-full min-h-full menu main-theme">
          <div className="mx-0 mb-0 p-0 w-64">
            <Link to="/myprojects">
              <img
                src="essentia-ai-logo.png"
                className="px-4 h-20 justify-center object-contain"
                alt="essentia-ai-logo"
              ></img>
            </Link>
          </div>

          <ul className="m-0 p-0 menu w-64 text-base [&_*:active]:text-white [&_*:focus]:text-white">
            <li>
              <Link to="/myprojects">
                <HiOutlineArchive className="ml-4 m-2 p-0 h-6 w-6" />
                {myProjectTitle}
              </Link>
            </li>
            <li>
              <Link to="/filesandfolders">
                <HiOutlineFolder className="ml-4 m-2 p-0 h-6 w-6" />
                {t("sidebar.files")}
              </Link>
            </li>
            <li>
              <Link to="/pagesearch">
                <HiSearch className="ml-4 m-2 p-0 h-6 w-6" />
                {t("sidebar.pages")}
              </Link>
            </li>
            <li>
              <Link to="/books">
                <HiSearch className="ml-4 m-2 p-0 h-6 w-6" />
                {"BOOKS"}
              </Link>
            </li>
            <li>
              <Link to="https://home.essentia.ai/home/essentia-ai-help/" target="_blank" rel="noopener noreferrer">
                <HiOutlineQuestionMarkCircle className="ml-4 m-2 p-0 h-6 w-6" />
                {t("sidebar.help")}
              </Link>
            </li>
            <div className="h-0.5 bg-slate-400 mx-4 my-4"></div>
            <li>
              <Link to="/trash">
                <HiOutlineTrash className="ml-4 m-2 p-0 h-6 w-6" />
                {t("sidebar.trash")}
              </Link>
            </li>
            <li>
              <button onClick={onLogOut}>
                <HiLogout className="ml-4 m-2 p-0 h-6 w-6" />
                {t("sidebar.signout")}
              </button>
            </li>
            <div className="h-0.5 bg-slate-400 mx-4 my-4"></div>
            <li>
              <div className="flex mx-4 gap-4">
                <button onClick={() => onLangClick("en")}>EN</button>
                <button onClick={() => onLangClick("ja")}>JP</button>

                <button className="ml-auto" onClick={() => onHideSidebar()}>
                  <HiOutlineChevronDoubleLeft></HiOutlineChevronDoubleLeft>
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default MySidebar;
