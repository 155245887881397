import { HiChevronDown, HiOutlineX, HiChevronRight } from "react-icons/hi";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { Tags } from "../../types/tag";
import { closeDropdown, closeDropdownByOutsideClick } from "../../lib/close_dropdown";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const groupTagsByCategory = (tags: { name: string; category: string }[]) => {
  return tags.reduce((acc, tag) => {
    if (!acc[tag.category]) {
      acc[tag.category] = [];
    }
    acc[tag.category].push(tag.name);
    return acc;
  }, {} as Record<string, string[]>);
};

export const BooksSubjectButton = ({
  tags,
  setTags,
}: {
  tags: string[];
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const { t } = useTranslation();
  const allTags = useSelector<RootState, Tags>((state: RootState) => state.allTags);
  console.log("allTags:", allTags);

  // 仮
  const subjectTags = [
    { name: "エクセル", category: "マイクロソフト" },
    { name: "ワード", category: "マイクロソフト" },
    { name: "Windows", category: "マイクロソフト" },
  ];
  console.log("subjectTags:", subjectTags);

  const normalTags = Object.keys(allTags).filter(
    (tag) => allTags[tag] === "subject" && !subjectTags.some((t) => t.name === tag)
  );

  // タグをカテゴリごとにグループ化
  const categorizedTags = groupTagsByCategory(subjectTags);

  const [subjectTagSelected, setSubjectTagSelected] = useState<string | null>(null);
  const [activeCategory, setActiveCategory] = useState<string | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const onClick = async (tag: string) => {
    setTags((prev) => {
      if (prev.includes(tag)) {
        return prev.filter((t) => t !== tag);
      }
      return [...prev, tag];
    });
    setSubjectTagSelected(tag);
    await closeDropdown();
  };

  const onClickRemove = async () => {
    setTags((prev) => prev.filter((t) => t !== subjectTagSelected));
    setSubjectTagSelected(null);
  };

  const toggleCategory = (category: string) => {
    setActiveCategory((prev) => (prev === category ? null : category));
  };

  const handleDropdownToggle = () => {
    setDropdownOpen((prev) => {
      const newState = !prev;
      if (!newState) {
        setActiveCategory(null);
      }
      return newState;
    });
  };

  useEffect(() => {
    const selectedTag = tags.find((tag) => subjectTags.some((t) => t.name === tag) || normalTags.includes(tag)) || null;
    setSubjectTagSelected(selectedTag);
    console.log("subjectTagSelected:", selectedTag);
  }, [tags, subjectTags, normalTags]);

  // 外部クリックでドロップダウンを閉じる
  closeDropdownByOutsideClick();

  return (
    <div className="relative">
      <details className="dropdown w-40 pr-4">
        <summary
          className={`btn w-full px-4 py-2 font-normal bg-purple-200 text-purple-900 flex items-center  cursor-pointer rounded-none`}
          onClick={handleDropdownToggle}
        >
          {!subjectTagSelected ? (
            <>
              {t("books.software")} <HiChevronDown />
            </>
          ) : (
            <button className={`flex items-center w-full h-full p-2 bg-inherit`} onClick={onClickRemove}>
              <HiOutlineX className="w-4 h-4" />
              <span className="ml-2">{subjectTagSelected}</span>
            </button>
          )}
        </summary>
        <ul className="menu dropdown-content z-10 w-40 main-theme-reverse border-2 border-gray-300 bg-white shadow-lg rounded-lg">
          {Object.keys(categorizedTags).map((category) => (
            <li key={category} className="relative">
              <button
                onClick={() => toggleCategory(category)}
                className="flex items-center justify-between w-full p-2 text-purple-900 hover:bg-purple-100 rounded-lg"
              >
                <span>{category}</span>
                <HiChevronRight
                  className={`transition-transform duration-300 ${activeCategory === category ? "rotate-90" : ""}`}
                />
              </button>
              {activeCategory === category && (
                <ul className="absolute left-[133px] top-0 mt-2 bg-white border border-gray-300 shadow-lg rounded-lg w-40 p-2">
                  {categorizedTags[category].map((tag) => (
                    <li
                      key={tag}
                      onClick={() => onClick(tag)}
                      className="p-2 hover:bg-gray-200 cursor-pointer rounded-lg"
                    >
                      {tag}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
          {normalTags.map((tag) => (
            <li key={tag} onClick={() => onClick(tag)} className="p-2 hover:bg-gray-200 cursor-pointer rounded-lg">
              {tag}
            </li>
          ))}
        </ul>
      </details>
    </div>
  );
};
