import { api } from "./axios";

export const tagsUpdate = async (
  gid: string,
  tags_add: string[],
  tags_remove: string[],
  ids: string[],
  file_bool: boolean
): Promise<{ [key: string]: string }> => {
  const response = await api.tags.tagsUpdate({
    gid: Number(gid),
    tags_add: tags_add,
    tags_remove: tags_remove,
    ids: ids,
    file_bool: file_bool,
  });
  const tags: { [key: string]: string } = response.data as { [key: string]: string };
  return tags;
};
