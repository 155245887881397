import { useTranslation } from "react-i18next";
import useWindowDimensions from "../../lib/window_dimensions";

export const BooksAIChatOpenButton = () => {
  const { t } = useTranslation();
  const { morethan640 } = useWindowDimensions();
  return (
    <button
      className={
        morethan640
          ? "btn px-0 py-0 main-theme h-8 text-sm flex items-center"
          : "fixed bottom-4 right-4 btn px-0 py-0 main-theme h-14 text-xs z-10"
      }
    >
      {morethan640 ? (
        <div className="flex items-center shine w-full h-full px-4">
          <img src="chatbot_avatar_1.png" alt="chatbot_avatar_1" className="h-6 w-6 sm:mr-2 shine" />
          {t("pagesearch.ai_chat")}
        </div>
      ) : (
        <div className="flex items-center shine w-full h-full px-4">
          <img src="chatbot_avatar_1.png" alt="chatbot_avatar_1" className="h-10 w-10 sm:mr-2" />
        </div>
      )}
    </button>
  );
};
