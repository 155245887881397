import { t } from "i18next";

export const FilesSearchBox = ({ setTerm }: { setTerm: React.Dispatch<React.SetStateAction<string>> }) => {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // inputでEnterが押されたとき、termをセットして、pagesを初期化してfetchDataを呼び出す
    if (e.key === "Enter") {
      const inputElement = document.getElementById("search_input") as HTMLInputElement;
      const term = inputElement.value;
      console.log("search term", term);
      setTerm(term);
    }
  };
  return (
    <div className="ml-auto w-1/4">
      <input
        id="search_input"
        type="text"
        placeholder={t("files.placeholder_searchinput")}
        className="input w-full"
        onKeyDown={handleKeyDown}
        autoFocus
      />
    </div>
  );
};
