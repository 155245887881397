import { fetchAuthSession } from "aws-amplify/auth";
import { useEffect, useState, useRef, useCallback } from "react";
import { pagesSearch } from "../../axios/pages_search";
import { Page } from "../../types/page";
import { FileOrPage } from "../../types/fileorpage";
import { useSelector, useDispatch } from "react-redux";
import { Project } from "../../types/project";
import { Tags, setAllTags } from "../../types/tag";
import { RootState } from "../../store";
import { CommentModal } from "../comment_modal";
import { TagModal } from "../tag_modal";
import { PageContentModal } from "../pages/pages_content_modal";
import { BooksLookForWithin } from "../books/books_look_for_within";
import { BooksGrid } from "../books/books_grid";
import { BooksTagsSuggestion } from "../books/books_tag_suggestion";
import { BooksTagCondition } from "../books/books_tag_condition";
import { BooksSeriesButton } from "../books/books_series_btn";
import { BooksSubjectButton } from "../books/books_subject_btn";
import { useTranslation } from "react-i18next";
import { tagsFetch } from "../../axios/tags_fetch";
import { BooksRecommend } from "../books/books_recommend";
import { chatAsk } from "../../axios/chat";
import { ChatMessage } from "../../types/aichat";
import { EssaiChat } from "../essaichat/essai_chat";
import { BooksAIChatOpenButton } from "../books/books_aichat_btn";
import { closeDropdownByOutsideClick } from "../../lib/close_dropdown";
import { getUserChatMessage } from "../essaichat/message_utils";
import { initialChat } from "../essaichat/logic_initial_chat";
import { tagSelectedChat } from "../essaichat/logic_tag_selected";
import useWindowDimensions from "../../lib/window_dimensions";
import { DEKIRU_GIDs, GOSHA_GIDs } from "../../gids";
import EssaiDataJson from "../essaichat/essai_data.json";
import { getAnalytics, logEvent } from "firebase/analytics";
import { MobileBookViewer } from "../mobile_book_viewer";

function Books() {
  const BOOKS_GIDS = [...DEKIRU_GIDs, ...GOSHA_GIDs];
  const { t } = useTranslation();
  const curProject = useSelector<RootState, Project>((state: RootState) => state.currentProject);
  const gid = curProject.gid ? curProject.gid : "";
  const allTags = useSelector<RootState, Tags>((state: RootState) => state.allTags);
  const dispatch = useDispatch();
  const bottom = useRef(null);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [observer, setObserver] = useState<IntersectionObserver | null>(null);
  const [pages, setPages] = useState<Page[]>([]);
  const [filesOrPagesEdit, setFilesOrPagesEdit] = useState<FileOrPage[]>([]);
  const [numPages, setNumPages] = useState<number>(0); // UIに何ページまで表示しているか
  const [totalPages, setTotalPages] = useState<number>(-1); // 現在の条件での全ページ数
  const [term, setTerm] = useState<string>("");
  const [termField, setTermField] = useState<string>("Contennt (Broad Match)");
  const [sortBy, setSortBy] = useState<string>("Sort by Date Modified");
  const [pageStartEnd, setPageStartEnd] = useState<number[]>([0, 0]);
  const [tagsSelected, setTagsSelected] = useState<string[]>([]);
  const [searchFolder, setSearchFolder] = useState<string>("");
  const [isChatVisible, setIsChatVisible] = useState<boolean>(false);
  const [isChatLoading, setIsChatLoading] = useState<boolean>(false);
  const [isZoomVisible, setIsZoomVisible] = useState<boolean>(false);
  const [zoomPage, setZoomPage] = useState<Page | null>(null);
  // const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const { morethan640, morethan1024 } = useWindowDimensions();

  // allTagsの設定
  const fetchAllTags = useCallback(
    async (gid: string) => {
      try {
        const tags: Tags = await tagsFetch(gid);
        dispatch(setAllTags(tags));
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch]
  );
  useEffect(() => {
    fetchAllTags(gid);
  }, [gid, fetchAllTags]);

  // isloading中又はnumPages == totalPagesの時、fetchDataを呼び出さない
  const pagesSearchGo = useCallback(async () => {
    if (isPageLoading) {
      console.log("fetchData called, but Loading already in progress");
      return;
    }
    if (numPages === totalPages) {
      console.log("fetchData called, but already numPages == totalPages");
      return;
    }
    console.log("fetchData called and go:", numPages, totalPages);
    setIsPageLoading(true);
    try {
      const session = await fetchAuthSession();
      const usersub = session.userSub ? session.userSub : "";
      console.log(usersub);
      console.log("numPages as From", numPages);
      let sortByInES = "tag";
      let sortOrder = sortBy.includes("↓") ? true : false;

      let termFiledInES = termField === "Filename" ? "file" : termField === "Comment" ? "comment" : "content";
      let fuzziness = termField === "Contennt (Broad Match)" ? true : false;

      // microタグなし、検索ワードなし、で表紙のみを表示する
      let pageStart = pageStartEnd[0];
      let pageEnd = pageStartEnd[1];
      const checkMicroTag = () => {
        for (const tag of tagsSelected) {
          if (!["series", "subject"].includes(allTags[tag])) {
            return true;
          }
        }
        return false;
      };
      if (!checkMicroTag() && !term) {
        pageStart = 1;
        pageEnd = 1;
      }

      console.log("fetchData:", usersub, gid, sortByInES, sortOrder, numPages, term, termFiledInES, fuzziness);
      const [total_value, newPages] = await pagesSearch(
        usersub,
        gid,
        sortByInES,
        sortOrder,
        numPages,
        term,
        termFiledInES,
        fuzziness,
        pageStart,
        pageEnd,
        tagsSelected,
        searchFolder
      );
      for (const page of newPages) {
        console.log(page.id);
      }
      setTotalPages(total_value);
      setPages((prevPages) => {
        const newPagesFiltered = newPages.filter((page) => {
          for (const prevPage of prevPages) {
            if (prevPage.id === page.id) {
              return false;
            }
          }
          return true;
        });
        return [...prevPages, ...newPagesFiltered];
      });
    } catch (error) {
      console.error(error);
      setIsPageLoading(false);
    }
  }, [
    numPages,
    totalPages,
    isPageLoading,
    gid,
    term,
    termField,
    sortBy,
    pageStartEnd,
    tagsSelected,
    searchFolder,
    allTags,
  ]);

  // pages更新完了を待って、numPagesを設定する
  useEffect(() => {
    console.log("page length:", pages.length);
    setNumPages(pages.length);
    setIsPageLoading(false);
  }, [pages]);

  // numPages == totalPagesの時、observerを解除する
  useEffect(() => {
    if (numPages === totalPages) {
      if (observer && bottom.current) {
        observer.unobserve(bottom.current);
      }
      setObserver(null);
      console.log("Observer removed");
    }
  }, [numPages, totalPages, observer]);

  // infinite scroll & fetchData
  // target=bottomが画面内に入ったらfetchDataを呼び出す
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        console.log("Loading more pages");
        pagesSearchGo();
      }
    });
    setObserver(observer);
    if (bottom.current) {
      observer.observe(bottom.current);
      console.log("Observer started");
    }
    return () => {
      if (bottom.current) {
        observer.unobserve(bottom.current);
      }
    };
  }, [pagesSearchGo]);

  // inputでEnterが押されたとき、termをセットして、pagesを初期化してfetchDataを呼び出す
  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const inputElement = document.getElementById("search_input") as HTMLInputElement;
      setTerm(inputElement.value);
      setPages([]);
      setNumPages(0);
      setTotalPages(-1);
    }
  }, []);

  // searchFieldが変更されたとき、termが空欄でなければ、pagesを初期化してfetchDataを呼び出す
  useEffect(() => {
    if (term) {
      setPages([]);
      setNumPages(0);
      setTotalPages(-1);
      handleKeyDown({ key: "Enter" } as React.KeyboardEvent<HTMLInputElement>);
    }
  }, [term, termField, handleKeyDown]);

  // sortBy, pageStartEnd, tags, searchFolderが変更されたとき、pagesを初期化してfetchDataを呼び出す
  useEffect(() => {
    setPages([]);
    setNumPages(0);
    setTotalPages(-1);
  }, [sortBy, pageStartEnd, tagsSelected, searchFolder]);

  // 外部クリックでドロップダウンを閉じる
  closeDropdownByOutsideClick();

  const sendMessage = useCallback(
    async (messageContent: string) => {
      // ユーザー側メッセージを表示する
      const newMessage = getUserChatMessage(messageContent);
      setMessages((prevMessages) => [...prevMessages, newMessage]);

      // メッセージがタグであれば、タグクリックと同様の処理をして、質問候補を出す。
      const messageIsTag = Object.keys(allTags).includes(messageContent);
      if (messageIsTag) {
        const newTag = messageContent;
        const subjectTagsSelected = tagsSelected.filter((tag) => allTags[tag] === "subject");
        const tagIsSubject: boolean = allTags[newTag] === "subject" ? true : false;
        onTagSelected(messageContent);
        const messages = tagSelectedChat(newTag, tagIsSubject, gid, JSON.stringify(EssaiDataJson));
        if (messages.length > 0) {
          setMessages((prevMessages) => [...prevMessages, ...messages]);
          return;
        }
      }

      // Tagクリックでない場合、AIに質問を送る
      setIsChatLoading(true);
      setIsPageLoading(true);
      const [answer, pages] = await chatAsk(Number(gid), tagsSelected, messageContent);
      const responseMessage: ChatMessage = {
        message: answer,
        sentTime: new Date().toLocaleTimeString(),
        isAnswer: true,
      };
      setIsChatLoading(false);
      setMessages((prevMessages) => [...prevMessages, responseMessage]);

      if (pages.length > 0) {
        console.log("Pages from chatAsk: ", pages.length, pages);
        setTotalPages(pages.length);
        setPages(pages);
      } else {
        setIsPageLoading(false);
      }
    },
    [gid, tagsSelected]
  );

  const setInitialMessage = useCallback(() => {
    const hasOutgoing = messages.some((msg) => msg.isOutgoing);
    if (hasOutgoing) return; // outgoingメッセージがあれば、初期メッセージセットはしない

    const subject = tagsSelected.find((tag) => allTags[tag] === "subject");
    const initialMessages = initialChat(subject || "", gid, JSON.stringify(EssaiDataJson));
    setMessages(initialMessages);
  }, [messages, tagsSelected, allTags, gid]);

  //チャットボックスが開いていない状態でのみ、初期メッセージをセットする
  useEffect(() => {
    if (isChatVisible) return;
    setInitialMessage();
  }, [isChatVisible, gid, tagsSelected]);

  // モバイルの場合、チャット又はZoomが開いていれば全体スクロールを禁止する
  useEffect(() => {
    if ((isChatVisible && !morethan640) || (isZoomVisible && !morethan640)) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [isChatVisible, isZoomVisible, morethan640]);

  // タグが選択された時の処理
  const onTagSelected = useCallback(
    (newTag: string) => {
      console.log("newTag:", newTag);
      if (!newTag || newTag == " " || newTag == "　") {
        return;
      }
      const subjectTagsSelected = tagsSelected.filter((tag) => allTags[tag] === "subject");
      const tagIsSubject = allTags[newTag] === "subject";
      setTagsSelected((prevTags) => {
        if (tagIsSubject && subjectTagsSelected.length > 0) {
          // すでにsubjectタグが選択されている場合、subjectタグを削除して新しいsubjectタグを追加する
          const prevTagsWithoutSubject = prevTags.filter((tag) => allTags[tag] !== "subject");
          return [...prevTagsWithoutSubject, newTag];
        } else if (prevTags.includes(newTag)) {
          // すでに選択されている場合は何もしない
          return prevTags;
        } else {
          // それ以外の場合は新しいタグを追加する
          logEvent(getAnalytics(), "tag_selected", { selected_tag: newTag });

          //親タグが選択されていない場合、親タグも同時に登録する
          const parentTag = allTags[newTag];
          console.log("parentTag:", parentTag, allTags);
          if (
            !prevTags.includes(parentTag) &&
            parentTag &&
            ["subject", "series", "function", "", " "].every((tag) => tag !== parentTag)
          ) {
            return [...prevTags, parentTag, newTag];
          } else {
            return [...prevTags, newTag];
          }
        }
      });
    },
    [setTagsSelected]
  );

  const showMobileBookViewerModal = () => {
    const dialogElement = document.getElementById("mobile-book-viewer-modal") as HTMLDialogElement;
    if (dialogElement) {
      dialogElement.showModal();
    }
  };

  useEffect(() => {
    if (zoomPage) {
      showMobileBookViewerModal();
    }
  }, [zoomPage]);

  return (
    <div className="flex bottom-0 overscroll-none">
      <div className={isChatVisible ? "w-3/5" : "w-full"}>
        <div className="flex">
          {/* <img src="impress_logo.png" className="h-8" alt="impress_logo"></img> */}
          {/* <h1 className="ml-2 font-bold main-theme-reverse text-2xl">できるシリーズ30周年記念</h1> */}
          {DEKIRU_GIDs.some((g) => g == gid) ? (
            <div className="flex items-end">
              <img src="dekiru-30th-logo.png" className="h-12" alt="essai_logo"></img>
              {/* <h1 className="h-8 font-bold text-2xl text-red-700">できるAI</h1> */}
              <span className="ml-1 mb-2 main-theme-reverse text-2xl">×</span>
              <img src="essentia-ai-logo.png" className="h-10 ml-1 mb-1" alt="essai_logo"></img>
            </div>
          ) : (
            <div className="flex">
              <img src="essentia-ai-logo.png" className="h-8" alt="essai_logo"></img>
              <h1 className="h-8 ml-2 font-bold main-theme-reverse text-2xl">Books</h1>
            </div>
          )}
          {!isChatVisible && (
            <div
              className="ml-auto sm:mr-4 h-8"
              onClick={() => {
                setIsChatVisible(true);
              }}
            >
              <BooksAIChatOpenButton />
            </div>
          )}
        </div>
        <div className="h-4 my-2 mb-4 font-bold text-sm main-theme-reverse">
          {DEKIRU_GIDs.some((g) => g == gid)
            ? "できるシリーズがAIでより便利に! 30周年の特別企画！"
            : t("books.subtitle")}
        </div>
        <div className="flex items-center align-middle">
          <BooksLookForWithin handleKeyDown={handleKeyDown} setTermField={setTermField}></BooksLookForWithin>
          {morethan640 && (
            <div className="ml-auto flex">
              <BooksTagCondition tags={tagsSelected} setTags={setTagsSelected}></BooksTagCondition>
              <BooksSubjectButton tags={tagsSelected} setTags={setTagsSelected}></BooksSubjectButton>
              <BooksSeriesButton tags={tagsSelected} setTags={setTagsSelected}></BooksSeriesButton>
            </div>
          )}
        </div>
        <div className="mt-4 flex items-center align-middle">
          <BooksTagsSuggestion tagsSelected={tagsSelected} onTagSelected={onTagSelected}></BooksTagsSuggestion>
        </div>
        {!morethan640 && (
          <div className="py-2 pl-8">
            <BooksTagCondition tags={tagsSelected} setTags={setTagsSelected}></BooksTagCondition>
          </div>
        )}
        <div className="flex mt-2 mx-4 items-center align-middle"></div>
        {isPageLoading ? (
          <div className="w-full flex justify-center content-center h-8">
            <span className="loading loading-spinner loading-sm"></span>
          </div>
        ) : null}
        <div className="flex w-full">
          <div
            className={isChatVisible || !BOOKS_GIDS.some((g) => g == gid) ? "w-full px-2" : "w-full sm:w-3/4 sm:px-2"}
          >
            <BooksGrid
              pages={pages}
              filesOrPagesEdit={filesOrPagesEdit}
              setFilesOrPagesEdit={setFilesOrPagesEdit}
              onTagSelected={onTagSelected}
              tags={tagsSelected}
              isZoomVisible={isZoomVisible}
              setIsZoomVisible={setIsZoomVisible}
              setZoomPage={setZoomPage}
            ></BooksGrid>
            <div ref={bottom} />
          </div>
          {
            // lg以上の画面サイズで、オススメを表示
            !isChatVisible && morethan1024 && BOOKS_GIDS.some((g) => g == gid) && (
              <div className="justify-center w-1/4">
                <div className="ml-auto mr-auto w-fit">
                  <BooksRecommend tags={tagsSelected} numPages={numPages}></BooksRecommend>
                </div>
              </div>
            )
          }
        </div>
        {isChatVisible && (
          <div className="fixed top-0 bottom-0 right-0 -left-1 md:left-auto md:w-2/5 md:pl-6 transition-all duration-500 ">
            <EssaiChat
              isChatLoading={isChatLoading}
              setIsChatLoading={setIsChatLoading}
              setIsPageLoading={setIsPageLoading}
              messages={messages}
              onSendMessage={sendMessage}
              setMessages={setMessages}
              setIsChatVisible={setIsChatVisible}
              setTotalPages={setTotalPages}
              setPages={setPages}
              setInitialMessage={setInitialMessage}
              tagsSelected={tagsSelected}
            />
          </div>
        )}
      </div>
      <CommentModal filesOrPages={filesOrPagesEdit}></CommentModal>
      <TagModal fileBool={false} filesOrPages={filesOrPagesEdit}></TagModal>
      <PageContentModal filesOrPages={filesOrPagesEdit}></PageContentModal>
      <MobileBookViewer zoomPage={zoomPage} setIsZoomVisible={setIsZoomVisible}></MobileBookViewer>
    </div>
  );
}

export default Books;
