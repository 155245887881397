import { t } from "i18next";
import { HiOutlineX } from "react-icons/hi";

export const FilesBreadCrumb = ({
  term,
  setTerm,
  folderList,
  setFolderList,
}: {
  term:string;
  setTerm: React.Dispatch<React.SetStateAction<string>>;
  folderList: string[];
  setFolderList: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const clickedBreadCrumb = (index: number) => {
    console.log("clickedBreadCrumb", index);
    let newFolderList = ["Home"];
    newFolderList = newFolderList.concat(folderList.slice(1, index + 1));
    setFolderList(newFolderList);
  };
  return (
    <div>
      {" "}
      {term ? (
        <button
          className="flex items-center align-middle"
          onClick={() => {
            setTerm("");
            const inputElement = document.getElementById("search_input") as HTMLInputElement;
            inputElement.value = "";
          }}
        >
          <HiOutlineX className="h-6 w-6 mr-2"></HiOutlineX>{t("files.search_result")}
        </button>
      ) : (
        <div className="breadcrumbs">
          <ul>
            {folderList.map((folder, index) => (
              <li key={index}>
                <button onClick={() => clickedBreadCrumb(index)}>{folder}</button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
