import { api } from "./axios";
import { Page as resPage, PagesList } from "./client";
import { Page } from "../types/page";

export const pagesSearch = async (
  usersub: string,
  gid: string,
  sortBy: string,
  sortBool: boolean,
  from: number,
  term: string,
  term_field: string,
  fuzziness: boolean,
  pageStart: number,
  pageEnd: number,
  tags: string[],
  folder: string
): Promise<[number, Page[]]> => {
  const response = await api.pages.pagesSearch({
    sub: usersub,
    gid: Number(gid),
    sortby: sortBy,
    sortbool: sortBool,
    from: from,
    term: term,
    term_field: term_field,
    fuzziness: fuzziness,
    page_start: pageStart,
    page_end: pageEnd,
    tags: tags,
    folder: folder,
  });
  console.log("from: ", from);
  const resPages: resPage[] = (response.data as PagesList).pages!;
  const total_value: number = (response.data as PagesList).total_value ?? 0;
  console.log(resPages);

  const pages: Page[] = resPages.map((resPage: resPage) => {
    return {
      id: resPage.id ?? "0",
      folder: resPage.folder ?? "",
      file: resPage.file ?? "",
      modified_at: Number(resPage.modified_at) ?? 0,
      key: resPage.key ?? "",
      p_num: resPage.p_num ?? 0,
      page: resPage.page ?? "",
      content: resPage.content ?? "",
      comment: "",
      thumb: resPage.thumb ?? "",
      tag: resPage.tag ?? [],
    };
  });

  return [total_value, pages];
};
