import { useState, useEffect, useCallback } from "react";
import { fileRename } from "../axios/file_rename";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { Project } from "../types/project";
import { FileOrPage } from "../types/fileorpage";
import { t } from "i18next";

export const RenameModal = ({
  filesOrPages,
}: {
  filesOrPages: FileOrPage[];
}) => {
  const curProject = useSelector<RootState, Project>(
    (state: RootState) => state.currentProject
  );
  const gid = curProject.gid ? curProject.gid : "";

  // ファイル変更反映にuseEffectも必要

  const createInitialName = (filesOrPages: FileOrPage[]) => {
    return filesOrPages[0]?.file
      ? filesOrPages[0]?.file
      : filesOrPages[0]?.folder
      ? filesOrPages[0]?.folder.split("/")[
          filesOrPages[0]?.folder.split("/").length - 2
        ]
      : "";
  };
  const initialName = createInitialName(filesOrPages);

  const [newName, setNewName] = useState<string>("");
  useEffect(() => {
    setNewName(createInitialName(filesOrPages));
  }, [filesOrPages]);

  const onUpload = useCallback(async () => {
    if (!filesOrPages) return;
    if (!newName) return;
    const key = filesOrPages[0].key;
    const file = filesOrPages[0].file;
    const folder = filesOrPages[0].folder;
    let newNameAdj = newName;
    //フォルダの場合
    if (!file && folder) {
      newNameAdj = `${folder
        .split("/")
        .slice(0, folder.split("/").length - 2)
        .join("/")}/${newName}`;
      newNameAdj = newNameAdj.endsWith("/") ? newNameAdj : `${newNameAdj}/`;
      newNameAdj = newNameAdj.startsWith("/")
        ? newNameAdj.substring(1)
        : newNameAdj;
    }
    console.log(gid, key, folder, file, newNameAdj);
    await fileRename(gid, key, folder, file, newNameAdj);
    // dialogを閉じる
    const dialogElement = document.getElementById(
      "rename-modal"
    ) as HTMLDialogElement;
    if (dialogElement) {
      dialogElement.close();
      window.location.reload();
    }
  }, [gid, filesOrPages, newName]);

  return (
    <dialog id="rename-modal" className="modal">
      <div className="modal-box m-0 w-1/3" style={{ maxWidth: "none" }}>
        <div className="flex items-center mb-8">
          <button className="btn w-40 text-lg main-theme" onClick={onUpload}>
            {t("rename_modal.update")}
          </button>
          <div className="modal-action m-0 ml-auto">
            <form method="dialog">
              {/* if there is a button in form, it will close the modal */}
              <button className="btn">{t("rename_modal.close")}</button>
            </form>
          </div>
        </div>
        {initialName}
        <div className="flex mt-4">
          <label htmlFor="file_rename" className="p-0 w-24 align-bottom">
            {t("rename_modal.newname")}:
          </label>
          <input
            id="file_rename"
            placeholder="write here"
            className="border-b-2 mx-4 w-3/4 focus:outline-none focus:ring-0 focus:border-teal"
            onChange={(e) => {
              setNewName(e.target.value);
            }}
            value={newName}
            maxLength={100}
          />
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
};
