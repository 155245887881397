import { useState, useEffect, useCallback } from "react";
import { filesMove } from "../axios/files_move";
import { folderFetch } from "../axios/folder_fetch";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { Project } from "../types/project";
import { FileOrPage } from "../types/fileorpage";
import { HiOutlineChevronRight, HiOutlineChevronLeft } from "react-icons/hi";
import { t } from "i18next";

export const MoveModal = ({ filesOrPages }: { filesOrPages: FileOrPage[] }) => {
  const curProject = useSelector<RootState, Project>(
    (state: RootState) => state.currentProject
  );
  const gid = curProject.gid ? curProject.gid : "";
  const [allFolders, setAllFolders] = useState<string[]>([]);
  const [nextFolders, setNextFolders] = useState<string[]>([]);
  const [curFolder, setCurFolder] = useState<string>("");
  const [newFolder, setNewFolder] = useState<string>("");

  const onUpload = useCallback(async () => {
    if (!filesOrPages) return;
    if (!newFolder) return;

    const keys = filesOrPages
      .filter((fileOrPage) => fileOrPage.key)
      .map((fileOrPage) => fileOrPage.key);
    const folders = filesOrPages
      .filter((fileOrPage) => fileOrPage.folder && !fileOrPage.file)
      .map((fileOrPage) => fileOrPage.folder);

    console.log(gid, keys, folders, newFolder);
    await filesMove(gid, keys, folders, newFolder);
    // dialogを閉じる
    const dialogElement = document.getElementById(
      "move-modal"
    ) as HTMLDialogElement;
    if (dialogElement) {
      dialogElement.close();
      window.location.reload();
    }
  }, [gid, filesOrPages, newFolder]);

  useEffect(() => {
    const folderFetchGo = async (gid: string) => {
      const folders = await folderFetch(gid);
      setAllFolders(folders);
      console.log("folderFetched", folders);
    };
    folderFetchGo(gid);
  }, [gid]);

  useEffect(() => {
    if (curFolder === "") {
      const nextFolders = allFolders.filter(
        (folder) => folder.split("/").length === 2
      );
      console.log("nextFolders:", nextFolders);
      setNextFolders(nextFolders);
    } else {
      const nextFolders = allFolders.filter(
        (folder) =>
          folder.split("/").length === curFolder.split("/").length + 1 &&
          folder.startsWith(curFolder)
      );
      console.log("nextFolders:", nextFolders);
      setNextFolders(nextFolders);
    }
  }, [allFolders, curFolder]);

  const onMoveFolder = useCallback(
    (folder: string) => {
      const hasSubfolders = allFolders.some(
        (f) => f.startsWith(`${folder}`) && f !== `${folder}`
      );
      if (hasSubfolders) {
        setCurFolder(folder);
      }
    },
    [allFolders]
  );

  const createInitialName = (filesOrPages: FileOrPage[]) => {
    return filesOrPages[0]?.file
      ? filesOrPages[0]?.file
      : filesOrPages[0]?.folder
      ? filesOrPages[0]?.folder.split("/")[
          filesOrPages[0]?.folder.split("/").length - 2
        ]
      : "";
  };
  const initialName = createInitialName(filesOrPages);

  useEffect(() => {
    setNewFolder(createInitialName(filesOrPages));
  }, [filesOrPages]);

  const onCheckFolder = useCallback(
    (folder: string) => {
      if (folder === newFolder) {
        setNewFolder("");
      } else {
        setNewFolder(folder);
      }
    },
    [newFolder]
  );

  const onClickBack = useCallback((folder: string) => {
    console.log("curFolder:", folder);
    let parentFolder = folder.split("/").slice(0, -2).join("/") + "/";
    parentFolder = parentFolder === "/" ? "" : parentFolder;
    console.log("parentFolder:", parentFolder);
    setCurFolder(parentFolder);
  }, []);

  return (
    <dialog id="move-modal" className="modal">
      <div className="modal-box m-0 w-1/3" style={{ maxWidth: "none" }}>
        <div className="flex items-center mb-8">
          <button className="btn w-40 text-lg main-theme" onClick={onUpload}>
            {t("move_modal.update")}
          </button>
          <div className="modal-action m-0 ml-auto">
            <form method="dialog">
              {/* if there is a button in form, it will close the modal */}
              <button className="btn">{t("move_modal.close")}</button>
            </form>
          </div>
        </div>
        {initialName}
        <ul className="menu main-theme-reverse border-2 ">
          {nextFolders.length > 0 ? (
            <div>
              {curFolder !== "" ? (
                <li
                  className="hover:bg-white focus:bg-white active:bg-white bg-white"
                  key="-1"
                >
                  <div className="flex h-12">
                    <HiOutlineChevronLeft
                      onClick={() => {
                        onClickBack(curFolder);
                      }}
                    ></HiOutlineChevronLeft>
                    <span className="ml-2 text-xs">Back</span>
                  </div>
                </li>
              ) : null}
              {nextFolders.map((folder, index) => (
                <li
                  key={index}
                  className="hover:bg-white focus:bg-white active:bg-white bg-white"
                >
                  <div className="flex h-12">
                    <input
                      type="checkbox"
                      className="checkbox checkbox-primary checkbox-sm"
                      onChange={() => {
                        onCheckFolder(folder);
                      }}
                      checked={folder === newFolder}
                    ></input>
                    <span className="ml-2 text-xs">
                      {folder.split("/")[folder.split("/").length - 2]}
                    </span>
                    <HiOutlineChevronRight
                      className="ml-auto"
                      onClick={() => {
                        onMoveFolder(folder);
                      }}
                    ></HiOutlineChevronRight>
                  </div>
                </li>
              ))}
            </div>
          ) : (
            <div>{t("move_modal.nofolder")}</div>
          )}
        </ul>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
};
