import { api } from "./axios";
import { Project } from "../types/project";
import { ProjectList } from "./client";

export const projectsFetch = async (): Promise<Project[]> => {
  const response = await api.projects.projectsFetch();
  console.log("axios response", response.data);
  const projectList: ProjectList = (response.data as ProjectList);

  const projects: Project[] = projectList.map((projectList) => ({
    gid: String(projectList.gid) as string ?? '',
    name: projectList.name as string ?? '',
    owner: projectList.owner as string ?? '',
    comment: projectList.comment as string ?? '',
    last_modify: String(projectList.last_modify) as string ?? '0',
    pages: projectList.pages as number ?? 0,
    size: projectList.size as number ?? 0,
    n_files: projectList.n_files as number ?? 0,
  }));

  return projects;
};
