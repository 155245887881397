import axios from "axios";
import { API_BASE_URL } from "../env";
import { getAuthInfo } from "../auth";
import { api } from "./axios";

export const fileUpdateComment = async (gid: string, ids: string[], comment: string): Promise<string> => {
  console.log(gid, ids, comment);
  const response = await api.files.filesCommentsUpdate({
    gid: Number(gid),
    ids: ids,
    comment: comment,
  });
  const message = response.data.message as string;
  console.log(message);
  return message;
};
