import axios from "axios";

export const fileDownloadBlob = async (url: string): Promise<any> => {
  const response = await axios({
    method: "GET",
    headers: {
      "content-type": "application/pdf",
      "Access-Control-Allow-Origin": "*",
    },
    withCredentials: false,
    responseType: "blob",
    timeout: 10000,
    url: url,
  });
  const blob = response.data;
  return blob;
};
