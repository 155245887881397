import { HiChevronDown, HiOutlineX } from "react-icons/hi";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { Tags } from "../../types/tag";
import { closeDropdown } from "../../lib/close_dropdown";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const BooksSeriesButton = ({
  tags,
  setTags,
}: {
  tags: string[];
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const { t } = useTranslation();
  const allTags = useSelector<RootState, Tags>((state: RootState) => state.allTags);
  console.log("allTags:", allTags);
  const seriesTags = Object.keys(allTags).filter((tag) => allTags[tag] === "series");
  console.log("seriesTags:", seriesTags);
  const [seriesTagSelected, setSeriesTagSelected] = useState<string>("");

  const onClick = async (tag: string) => {
    console.log("tag clicked:", tag);
    setTags((prev) => {
      if (prev.includes(tag)) {
        return prev.filter((t) => t !== tag);
      }
      return [...prev, tag];
    });
    await closeDropdown();
  };

  const onClickRemove = async () => {
    setTags((prev) => {
      return prev.filter((t) => t !== seriesTagSelected);
    });
    setSeriesTagSelected("");
  };

  useEffect(() => {
    for (const tag of tags) {
      if (seriesTags.includes(tag)) {
        setSeriesTagSelected(tag);
        console.log("seriesTagSelected:", tag);
        break;
      }
    }
    console.log("seriesTagSelected: not set");
  }, [tags, seriesTags]);

  return (
    <div>
      <details className="dropdown w-40 pr-4">
        <summary className="btn w-full px-4 main-theme-reverse-sub font-normal rounded-none">
          {!seriesTagSelected ? (
            <>
              {t("books.series")}
              <HiChevronDown></HiChevronDown>
            </>
          ) : (
            <button
              className="flex items-center align-middle w-full h-full justify-center text-xs"
              onClick={onClickRemove}
            >
              <HiOutlineX className="-ml-4 mr-2 w-4 h-4"></HiOutlineX>
              {seriesTagSelected}
            </button>
          )}
        </summary>
        <ul className="menu dropdown-content z-[1] w-48 main-theme-reverse border-2">
          {seriesTags.map((tag) => {
            return (
              <li key={tag} onClick={() => onClick(tag)}>
                <span>{tag}</span>
              </li>
            );
          })}
        </ul>
      </details>
    </div>
  );
};
