import { useTranslation } from "react-i18next";
import useWindowDimensions from "../../lib/window_dimensions";

export const BooksLookForWithin = ({
  handleKeyDown,
  setTermField,
}: {
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  setTermField: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { t } = useTranslation();
  const { morethan640 } = useWindowDimensions();
  return (
    <div className="flex sm:mx-4 align-middle items-center text-sm sm:text-base">
      <span className="font-bold main-theme-reverse w-fit whitespace-nowrap mr-2">{t("books.lookfor")}</span>
      <input
        id="search_input"
        type="text"
        placeholder="Type here"
        className="w-64 sm:w-80 sm:ml-2 border-b-2 focus:border-none focus:outline-4 focus:outline-offset-8 focus:outline-gray-300"
        onKeyDown={handleKeyDown}
        autoFocus={morethan640} // モバイルの場合はフォーカスしない
      />
      {/* <span className="ml-4 font-bold main-theme-reverse ">{t("pagesearch.within")}</span> */}
      {/* <select
          className="ml-3 w-48 focus:border-none focus:outline-4 focus:outline-offset-8 focus:outline-gray-300"
          onChange={(e) => setTermField(e.target.value)}
        >
          <option>{t("pagesearch.within_exact_match")}</option>
          <option>{t("pagesearch.within_broad_match")}</option>
          <option>{t("pagesearch.within_filename")}</option>
          <option>{t("pagesearch.within_comment")}</option>
        </select> */}
    </div>
  );
};
