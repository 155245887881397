import { HiOutlineX } from "react-icons/hi";
import { useCallback } from "react";

export const PageTagCondition = ({
  tags,
  setTags,
}: {
  tags: string[];
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  // タグを外す
  const onUnSelectTag = useCallback(
    (tag: string) => {
      setTags((prevTags) => {
        return prevTags.filter((prevTag) => prevTag !== tag);
      });
    },
    [setTags]
  );

  return (
    <div className="flex">
      {tags.length > 0 ? (
        <div className="main-theme-reverse-sub flex items-center align-middle mr-4 rounded-lg px-2">
          {tags.map((tag, index) => (
            <button
              key={index}
              className="my-2 mx-1 px-2 bg-inherent rounded-box text-[#325278] border border-[#325278] h-6 text-sm flex items-center align-middle"
              onClick={() => onUnSelectTag(tag)}
            >
              <HiOutlineX className="x-6 h-6 mr-1"></HiOutlineX>
              {tag}
            </button>
          ))}
        </div>
      ) : null}
    </div>
  );
};
