import { api } from "./axios";

export const tagsCreate = async (gid: string, tag: string): Promise<{ [key: string]: string }> => {
  const response = await api.tags.tagsCreate({
    gid: Number(gid),
    tag: tag,
  });
  const projects: { [key: string]: string } = response.data as { [key: string]: string };
  return projects;
};
