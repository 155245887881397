import { Page } from "../../types/page";
import { HiOutlineExternalLink } from "react-icons/hi";

export const BooksLinkToImpress = ({ page }: { page: Page }) => {
  const getIdFromFileName = () => {
    const fileName = page.file;
    if (fileName.includes("できるExcel 2021 Office 2021 & Microsoft 365両対応")) {
      return "1121101083";
    } else if (fileName.includes("できるExcel パーフェクトブック 困った！＆便利ワザ大全 Office")) {
      return "1122101030";
    } else if (fileName.includes("できるExcelピボットテーブル Office 2021/2019/2016")) {
      return "1122101056";
    } else if (fileName.includes("できるExcel関数 Office 2021/2019/2016&Microsoft")) {
      return "1122101036";
    } else if (fileName.includes("できるExcelグラフ")) {
      return "1122101171";
    } else if (fileName.includes("できるExcelマクロ&VBA")) {
      return "1122101176";
    } else {
      return "1121101083";
    }
  };

  const openImpress = () => {
    const bookid = getIdFromFileName();
    const url = `https://book.impress.co.jp/books/${bookid}`;
    window.open(url, "_blank");
  };

  return (
    <button onClick={() => openImpress()}>
      <HiOutlineExternalLink className="text-gray-500 ml-2 h-6 w-6"></HiOutlineExternalLink>
    </button>
  );
};
