import { api } from "./axios";


export const filesDelete = async (gid: string, keys: string[], folders: string[]): Promise<string> => {
  console.log(gid, keys, folders);
  const response = await api.files.filesDelete({
    gid: Number(gid),
    keys: keys,
    folders: folders
  });
  const message = response.data.message as string;
  console.log(message);
  return message;
};
