import { ChatMessage } from "../../types/aichat";
import { getWithSentTimeMessages } from "./message_utils";

export const initialChat = (subject: string, gid: string, EssaiDataJson: string): ChatMessage[] => {
  const gidStr = `${gid}`;
  const json = JSON.parse(EssaiDataJson);
  if (gidStr in json) {
    const nodeData = json[gidStr];
    if (!subject) {
      const value = nodeData['CHAT_NOSUBJECT'];
      return [value, ...getWithSentTimeMessages(value.node)];
    } else if (subject in nodeData) {
      return [...getWithSentTimeMessages(nodeData[subject].node)];
    } else {
      return getWithSentTimeMessages([nodeData.NONE_WORD]);
    }
  }
  return [];
};
