import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import { thunk } from "redux-thunk";
import { currentProject } from "./types/project";
import { lang } from "./types/lang";
import { allTags } from "./types/tag";
import { sidebar } from "./types/sidebar";

const appReducer = combineReducers({ currentProject: currentProject, lang: lang, allTags: allTags, sidebar: sidebar });

const rootReducer = (state: any, action: { type: string }) => {
  if (action.type === "USER_LOGOUT") {
    storage.removeItem("persist:root");
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

// export const store = configureStore({ reducer: { currentProject: currentProject } });

export type RootState = ReturnType<typeof store.getState>; // 結合したreducerのstateTree全体の型を取得する
export type AppDispatch = typeof store.dispatch;

// https://stackoverflow.com/questions/63761763/how-to-configure-redux-persist-with-redux-toolkit
