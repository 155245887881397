import { createSlice } from "@reduxjs/toolkit";
import i18n from "i18next";

export const langSlice = createSlice({
  name: "lang", //match with the name of the slice in store.ts
  initialState: "en",
  reducers: {
    setLang: (state, action) => {
      if (!["en", "ja"].includes(action.payload.lang)) return;
      console.log("setLang", action.payload);
      i18n.changeLanguage(action.payload.lang);
      return { ...action.payload };
    },
  },
});

export const { setLang } = langSlice.actions;
export const lang = langSlice.reducer;
