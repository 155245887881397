import { useCallback, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Tags } from "../../types/tag";
import { RootState } from "../../store";
import { useTranslation } from "react-i18next";
import { getAnalytics, logEvent } from "firebase/analytics";

export const BooksTagsSuggestion = ({
  tagsSelected,
  onTagSelected,
}: {
  tagsSelected: string[];
  onTagSelected: (tag: string) => void;
}) => {
  const { t } = useTranslation();
  const allTags = useSelector<RootState, Tags>((state: RootState) => state.allTags);
  const [tagsRecommend, setTagsRecommend] = useState<string[]>([]);
  const [subjectTags, setSubjectTags] = useState<string[]>([]);

  // subjectタグのみを取得
  useEffect(() => {
    const subjectTags = Object.keys(allTags).filter((tag) => allTags[tag] === "subject");
    setSubjectTags(subjectTags);
    console.log("subjectTags:", subjectTags);
  }, [allTags]);

  // タグの選択状態によって、おすすめのタグを表示する
  useEffect(() => {
    console.log("Renew tags to recommend:", tagsSelected);
    const subjectTagsSelected = tagsSelected.filter((tag) => allTags[tag] === "subject");
    const exSeriesSubjectTags = tagsSelected.filter((tag) => !["series", "subject"].includes(allTags[tag]));
    const checkIfPythonTag = tagsSelected.includes("Python");
    const checkIfExcelTag = tagsSelected.includes("エクセル");

    let tagsRecommend: string[] = [];
    if (exSeriesSubjectTags.length > 0) {
      // Series以外のタグがある場合、その子タグを表示する
      tagsRecommend = Object.keys(allTags).filter((tag) => exSeriesSubjectTags.includes(allTags[tag]));
    } else if (subjectTagsSelected.length > 0) {
      // Subjectタグがある場合、その子タグを表示する

      tagsRecommend = checkIfPythonTag
        ? ["スクレイピング", "ゲーム制作", "統計分析", "機械学習", "データ分析", "初心者向け"]
        : checkIfExcelTag
        ? ["データ分析", "業務自動化"]
        : [];
      tagsRecommend = tagsRecommend.concat(
        Object.keys(allTags).filter((tag) => subjectTagsSelected.includes(allTags[tag]))
      );
    } else if (exSeriesSubjectTags.length === 0) {
      // Series以外のタグがない場合。Subjectタグを表示してあげる
      tagsRecommend = subjectTags;
      tagsRecommend = tagsRecommend.concat(Object.keys(allTags).filter((tag) => allTags[tag] === "function"));
    }
    setTagsRecommend(tagsRecommend);
  }, [tagsSelected, allTags, subjectTags]);

  // タグを選択した時の処理。selectedTagsに追加する
  const onSelectTag = useCallback((newTag: string) => {
    onTagSelected(newTag);
  }, []);

  return (
    <div className="flex sm:mx-4">
      <span className="w-12 my-2 font-bold main-theme-reverse text-sm sm:text-base whitespace-nowrap">
        {t("books.tag")}
      </span>
      <div className="w-full sm:w-3/5">
        {tagsRecommend.map((tag, index) => (
          <button
            key={index}
            className={
              subjectTags.includes(tag)
                ? "w-fit my-1 sm:my-2 mr-2 sm:mr-4 px-2 text-xs sm:text-sm h-5 sm:h-6 font-bold bg-purple-400 inline-block whitespace-nowrap text-white"
                : "w-fit my-1 sm:my-2 mr-2 sm:mr-4 px-2 text-xs sm:text-sm h-5 sm:h-6 font-bold bg-cyan-400 rounded-box inline-block whitespace-nowrap text-white"
            }
            onClick={() => onSelectTag(tag)}
          >
            {tag}
          </button>
        ))}
      </div>
    </div>
  );
};
