import { Api } from "./client";
import { API_BASE_URL } from "../env";
import { getAuthInfo } from "../auth";

export const api = new Api({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const axiosInstance = api.instance;
axiosInstance.interceptors.request.use(
  async (config) => {
    const authToken = await getAuthInfo();
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export { axiosInstance };
