import { PageCell } from "../pages/page_cell";
import { Page } from "../../types/page";
import { FileOrPage } from "../../types/fileorpage";

export const PagesGrid = ({
  pages,
  filesOrPagesEdit,
  setFilesOrPagesEdit,
  setTags,
}: {
  pages: Page[];
  filesOrPagesEdit: FileOrPage[];
  setFilesOrPagesEdit: React.Dispatch<React.SetStateAction<FileOrPage[]>>;
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  return (
    <div className="pt-3 md:pl-3 pb-3 pr-auto grid xs:justify-items-center sm:justify-items-center xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
      {pages.map((page, index) => (
        <PageCell
          key={index}
          page={page}
          filesOrPagesEdit={filesOrPagesEdit}
          setFilesOrPagesEdit={setFilesOrPagesEdit}
          setTags={setTags}
        />
      ))}
    </div>
  );
};
