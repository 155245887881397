import { api } from "./axios";

export const fileRename = async (
  gid: string,
  key: string,
  folder: string,
  file: string,
  newName: string
): Promise<string> => {
  console.log(gid, key, file);
  const response = await api.files.filesRename({
    gid: Number(gid),
    key: key,
    folder: folder,
    file: file,
    newname: newName,
  });
  const message = response.data.message as string;
  console.log(message);
  return message;
};
