import { useState, useEffect, useCallback } from "react";
import { HiOutlineX } from "react-icons/hi";
import { Project } from "../types/project";
import { Tags, setAllTags } from "../types/tag";
import { RootState } from "../store";
import { useSelector, useDispatch } from "react-redux";
import { tagsFetch } from "../axios/tags_fetch";
import { tagsCreate } from "../axios/tag_create";
import { tagsUpdate } from "../axios/tags_update";
import { FileOrPage } from "../types/fileorpage";
import { t } from "i18next";

export const TagModal = ({
  fileBool,
  filesOrPages,
}: {
  fileBool: boolean;
  filesOrPages: FileOrPage[];
}) => {
  const curProject = useSelector<RootState, Project>(
    (state: RootState) => state.currentProject
  );
  const gid = curProject.gid ? curProject.gid : "";
  const allTags = useSelector<RootState, Tags>(
    (state: RootState) => state.allTags
  );
  console.log("allTags:", allTags);
  const dispatch = useDispatch();

  const [newTag, setNewTag] = useState<string>("");
  const [tags, setTags] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]); // 全てのfileOrPageに適用されているタグ
  const [conflictTags, setConflictTags] = useState<string[]>([]); // 一部のfileOrPageに適用されているタグ
  const [warning, setWarning] = useState<string>("");

  let title =
    filesOrPages.length > 0 ? filesOrPages[0].file : t("tag_modal.nofile");
  title = filesOrPages.length > 1 ? `...${filesOrPages.length} files` : title;

  // タグ作成処理
  const onCreate = useCallback(async () => {
    if (newTag === "") {
      console.log("newTag is empty");
      return;
    }
    if (tags.includes(newTag)) {
      console.log("tag already exists");
      setWarning(`Tag: ${newTag} already exists`);
      return;
    }
    console.log("create a newTag:", newTag);
    const newTagsDict = await tagsCreate(gid, newTag);
    const newTags = Object.keys(newTagsDict);
    dispatch(setAllTags(newTagsDict));
    setTags(newTags);
    setNewTag("");
  }, [gid, newTag, tags, dispatch]);

  // タグ選択処理
  const onSelectTag = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const tag = e.currentTarget.textContent;
      if (tag) {
        // selectedTagsに追加又は削除する
        if (selectedTags.includes(tag)) {
          setSelectedTags(selectedTags.filter((t) => t !== tag));
        } else {
          setSelectedTags([...selectedTags, tag]);
        }
        // conflictTagsから削除する
        if (conflictTags.includes(tag)) {
          setConflictTags(conflictTags.filter((t) => t !== tag));
        }
      }
    },
    [selectedTags, conflictTags]
  );

  // タグ更新処理
  const onSave = useCallback(async () => {
    console.log("selectedTags:", selectedTags);
    console.log("conflictTags:", conflictTags);

    // selectedTags及びconflictTagsに含まれないタグは削除する
    let tagsRemove = tags.filter((tag) => !selectedTags.includes(tag));
    tagsRemove = tagsRemove.filter((tag) => !conflictTags.includes(tag));
    const ids = filesOrPages.map((f) => f.id);
    await tagsUpdate(gid, selectedTags, tagsRemove, ids, fileBool);
    // dialogを閉じる
    const dialogElement = document.getElementById(
      "tag-modal"
    ) as HTMLDialogElement;
    if (dialogElement) {
      dialogElement.close();
      window.location.reload();
    }
  }, [filesOrPages, gid, selectedTags, tags, conflictTags, fileBool]);

  // 初回のtagsをAPI取得して設定
  useEffect(() => {
    async function fetchData() {
      console.log("fetch tags", gid);
      const tagsDict = await tagsFetch(gid);
      const tags = Object.keys(tagsDict);
      setTags(tags);
      dispatch(setAllTags(tagsDict));
      console.log("tags:", tags);
    }
    fetchData();
  }, [gid, dispatch]);

  // selectedTags, conflictTagsを設定。初回とfilesOrPagesが変更された時に実行
  useEffect(() => {
    if (filesOrPages.length > 0) {
      //全てのfileOrPageに含まれるtagを返す。tagsから開始して、fileOrPageのtagを含むものだけに絞り込む
      const selectedTags = filesOrPages.reduce((tags: string[], fileOrPage) => {
        const fileOrPageTags = fileOrPage.tag || [];
        return tags.filter((tag) => fileOrPageTags.includes(tag));
      }, tags);
      setSelectedTags(selectedTags);

      // alLeastOnceSelectedTags: 一つ以上のfileOrPageに含まれるtagを返す
      // conflictTags: alLeastOnceSelectedTagsに含まれるが、selectedTagsに含まれないtag
      let atLeastOnceSelectedTags = filesOrPages.reduce(
        (tags: string[], fileOrPage) => {
          const fileOrPageTags = fileOrPage.tag || [];
          return tags.concat(fileOrPageTags);
        },
        []
      );
      atLeastOnceSelectedTags = Array.from(new Set(atLeastOnceSelectedTags));
      const conflictTags = atLeastOnceSelectedTags.filter(
        (tag) => !selectedTags.includes(tag)
      );
      setConflictTags(conflictTags);
    } else {
      setSelectedTags([]);
      setConflictTags([]);
    }
  }, [filesOrPages, tags]);

  return (
    <dialog id="tag-modal" className="modal p-4 md:p-0">
      <div
        className="modal-box max-h-fit m-0 h-full md:h-auto w-full md:w-1/3"
        style={{ maxWidth: "none" }}
      >
        <div className="flex items-center align-middle mb-8 ">
          <div className="text-xl">{title}</div>
          <div className="modal-action m-0 ml-auto bg-inherit">
            <form method="dialog">
              {/* if there is a button in form, it will close the modal */}
              <button>
                <HiOutlineX className="m-0 p-0 h-6 w-6 text-gray-400"></HiOutlineX>
              </button>
            </form>
          </div>
        </div>
        <div className="flex items-center mb-8">
          <div className="text-l font-bold">{t("tag_modal.newtag")}</div>
        </div>
        <div className="flex items-center mb-8">
          <input
            type="text"
            id="create_project_name"
            placeholder={t("tag_modal.placeholder")}
            className="border-b-2 w-3/4 focus:outline-none focus:ring-0 focus:border-teal"
            value={newTag}
            onChange={(e) => {
              setWarning("");
              setNewTag(e.target.value);
            }}
          />
          <button className="btn main-theme ml-auto w-20" onClick={onCreate}>
            {t("tag_modal.create")}
          </button>
        </div>
        {warning && <div className="text-red-500">{warning}</div>}
        <div className="flex items-center mb-8">
          <div className="text-l font-bold">{t("tag_modal.taglist")}</div>
        </div>
        <div className="h-80">
          {tags.length > 0 ? (
            tags.map((tag, index) => (
              <button
                key={index}
                className={
                  selectedTags.includes(tag)
                    ? "px-2 py-1 m-2 border-2 rounded-box border-accent text-white bg-accent"
                    : conflictTags.includes(tag)
                    ? "px-2 py-1 m-2 border-2 rounded-box border-red-400 text-red-400"
                    : "px-2 py-1 m-2 border-2 rounded-box border-gray-400 text-gray-400"
                }
                onClick={onSelectTag}
              >
                {tag}
              </button>
            ))
          ) : (
            <div>{t("tag_modal.notag")}</div>
          )}
        </div>
        {filesOrPages.length > 0 ? (
          <div className="flex">
            <button className="btn main-theme ml-auto w-20" onClick={onSave}>
              {t("tag_modal.save")}
            </button>
          </div>
        ) : null}
      </div>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
};
