import { HiChevronDown } from "react-icons/hi";
import { useTranslation } from "react-i18next";

export const PageActoinButton = () => {
  const { t } = useTranslation();
  const showTagModal = () => {
    const dialogElement = document.getElementById("tag-modal") as HTMLDialogElement;
    if (dialogElement) {
      dialogElement.showModal();
    }
  };

  return (
    <div className="hidden sm:hidden md:block">
      <details className="dropdown w-40 pr-4">
        <summary className="btn w-full px-4 main-theme">
          {t("pagesearch.actions")}
          <HiChevronDown></HiChevronDown>
        </summary>
        <ul className="p-2 shadow menu dropdown-content z-[1] w-full main-theme-reverse">
          <li>
            <span>{t("pagesearch.similar_pages")}</span>
          </li>
          <li onClick={showTagModal}>
            <span>{t("pagesearch.tag")}</span>
          </li>
          <li>
            <span>{t("pagesearch.download")}</span>
          </li>
        </ul>
      </details>
    </div>
  );
};
