import { t } from "i18next";
import { useCallback } from "react";

export const FilesOverlappedModal = ({
  setReplace,
  resetUploadCondition,
}: {
  setReplace: React.Dispatch<React.SetStateAction<boolean | null>>;
  resetUploadCondition: () => void;
}) => {
  // 自分自身のダイアログを閉じる
  const closeDialog = useCallback(async () => {
    const dialogElement = document.getElementById(
      "files-overlapped-modal"
    ) as HTMLDialogElement;
    if (dialogElement) {
      dialogElement.close();
    }
  }, []);

  // 重複はkeepで
  const onKeep = useCallback(async () => {
    setReplace(false);
    closeDialog();
  }, [setReplace, closeDialog]);

  // 重複はreplaceで
  const onReplace = useCallback(async () => {
    setReplace(true);
    closeDialog();
  }, [setReplace, closeDialog]);

  return (
    <dialog id="files-overlapped-modal" className="modal">
      <div className="modal-box m-0 w-1/3" style={{ maxWidth: "none" }}>
        <h2 className="text-lg font-bold mb-8">{t("upload_modal.options")}</h2>
        <div className="flex items-center mb-8 whitespace-pre-wrap">
          {t("upload_modal.warning")}
        </div>
        <div className="flex">
          <button className="btn ml-auto main-theme" onClick={onKeep}>
            {t("upload_modal.keep")}
          </button>
          <button className="btn ml-4 main-theme" onClick={onReplace}>
            {t("upload_modal.replace")}
          </button>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button onClick={resetUploadCondition}>close</button>
      </form>
    </dialog>
  );
};
