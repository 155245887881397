import { useState, useCallback } from "react";
import { projectCreate, projectEdit } from "../../axios/project_create";
import { Project } from "../../types/project";
import { t } from "i18next";

export const CreateProjectModal = ({ editProject }: { editProject: Project | null }) => {
  const [newName, setNewName] = useState<string>("");
  const [newComment, setNewComment] = useState<string>("");

  const onUpload = useCallback(async () => {
    if (editProject) {
      await projectEdit(newName, newComment, editProject);
    } else {
      await projectCreate(newName, newComment);
    }
    // dialogを閉じる
    const dialogElement = document.getElementById("create-project-modal") as HTMLDialogElement;
    if (dialogElement) {
      dialogElement.close();
      window.location.reload();
    }
  }, [newName, newComment, editProject]);

  return (
    <dialog id="create-project-modal" className="modal">
      <div className="modal-box m-0 w-1/3" style={{ maxWidth: "none" }}>
        <div className="flex items-center mb-8">
          <button className="btn w-40 text-lg main-theme" onClick={onUpload}>
            {editProject ? t("myprojects.Edit") : t("myprojects.Create")}
          </button>
          <div className="modal-action m-0 ml-auto">
            <form method="dialog">
              {/* if there is a button in form, it will close the modal */}
              <button className="btn">{t("myprojects.close")}</button>
            </form>
          </div>
        </div>
        <div className="flex">
          <label htmlFor="name" className="p-0 w-20 align-bottom">
           {t("myprojects.name")}:
          </label>
          <input
            type="text"
            id="create_project_name"
            placeholder={editProject ? editProject.name : ""}
            className="border-b-2 mx-4 w-3/4 focus:outline-none focus:ring-0 focus:border-teal"
            onChange={(e) => {
              setNewName(e.target.value);
            }}
          />
        </div>
        <div className="flex mt-4">
          <label htmlFor="comment" className="p-0 w-20 align-bottom">
            {t("myprojects.comment")}:
          </label>
          <input
            type="text"
            id="create_project_comment"
            placeholder={editProject ? editProject.comment : ""}
            className="border-b-2 mx-4 w-3/4 focus:outline-none focus:ring-0 focus:border-teal"
            onChange={(e) => {
              setNewComment(e.target.value);
            }}
          />
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button>{t("myprojects.close")}:</button>
      </form>
    </dialog>
  );
};
