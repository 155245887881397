import { FileOrPage } from "../../types/fileorpage";
import { SortCondition } from "../../types/sortcondition";
import { MyFile } from "../../types/file";
import { FilesTableHeaderColumn } from "./files_table_header_column";
import { t } from "i18next";

export const FilesTableHeader = ({
  files,
  sortCondition,
  setSortCondition,
  setCurPagination,
  setFilesOrPagesEdit,
}: {
  files: MyFile[];
  sortCondition: SortCondition;
  setSortCondition: React.Dispatch<React.SetStateAction<SortCondition>>;
  setCurPagination: React.Dispatch<React.SetStateAction<number>>;
  setFilesOrPagesEdit: React.Dispatch<React.SetStateAction<FileOrPage[]>>;
}) => {
  // ファイルを全て選択する
  const onChangeAllCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setFilesOrPagesEdit(files.map((file) => file as FileOrPage));
    } else {
      setFilesOrPagesEdit([]);
    }
  };

  //　並べ替え条件
  const sortClick = (sortBy: string) => {
    console.log("sortClick", sortBy);
    if (sortBy === "name") {
      setSortCondition({ ...sortCondition, sortBy: sortBy, nameOrder: !sortCondition.nameOrder });
    } else if (sortBy === "size") {
      setSortCondition({ ...sortCondition, sortBy: sortBy, sizeOrder: !sortCondition.sizeOrder });
    } else if (sortBy === "pages") {
      setSortCondition({ ...sortCondition, sortBy: sortBy, pageOrder: !sortCondition.pageOrder });
    } else if (sortBy === "modified_at") {
      setSortCondition({ ...sortCondition, sortBy: sortBy, modifiedOrder: !sortCondition.modifiedOrder });
    }
    setCurPagination(1);
  };

  return (
    <thead className="text-base">
      <tr className="[&_*]:items-center [&>th]:h-12 [&_*]:align-middle">
        <th>
          <input type="checkbox" className="checkbox checkbox-primary" onChange={onChangeAllCheckBox} />
        </th>
        <FilesTableHeaderColumn
          title={t("files.table_Name")}
          me="name"
          order={sortCondition.nameOrder}
          sortBy={sortCondition.sortBy}
          sortClick={sortClick}
          className="w-min-18"
        ></FilesTableHeaderColumn>
        <th className="w-min-12">{t("files.table_Tags")}</th>
        <th className="w-min-12">{t("files.table_Note")}</th>
        <FilesTableHeaderColumn
          title={t("files.table_Size")}
          me="size"
          order={sortCondition.nameOrder}
          sortBy={sortCondition.sortBy}
          sortClick={sortClick}
          className="w-12"
        ></FilesTableHeaderColumn>
        <FilesTableHeaderColumn
          title={t("files.table_Pages")}
          me="pages"
          order={sortCondition.nameOrder}
          sortBy={sortCondition.sortBy}
          sortClick={sortClick}
          className="w-12"
        ></FilesTableHeaderColumn>
        <FilesTableHeaderColumn
          title={t("files.table_ModifiedAt")}
          me="modified_at"
          order={sortCondition.nameOrder}
          sortBy={sortCondition.sortBy}
          sortClick={sortClick}
          className="w-12"
        ></FilesTableHeaderColumn>
        <th className="w-16">{t("files.table_Status")}</th>
      </tr>
    </thead>
  );
};
