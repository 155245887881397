import { MyFile } from "../../types/file";
import { FileOrPage } from "../../types/fileorpage";
import { HiOutlineTag } from "react-icons/hi";
import { useState, useEffect, useCallback } from "react";
import { MESSAGE_KEY } from "../routes/book_reader/pdfviewer";

export const FilesTableCell = ({
  file,
  folderList,
  filesOrPagesEdit,
  setFolderList,
  setFilesOrPagesEdit,
}: {
  file: MyFile;
  folderList: string[];
  filesOrPagesEdit: FileOrPage[];
  setFolderList: React.Dispatch<React.SetStateAction<string[]>>;
  setFilesOrPagesEdit: React.Dispatch<React.SetStateAction<FileOrPage[]>>;
}) => {
  // 表示するファイル情報を用意
  let name = file.file ? file.file : file.folder;
  if (!file.file && folderList.length > 1) {
    const folderSplitted = file.folder.split("/");
    name = folderSplitted[folderSplitted.length - 2] + "/";
  }
  const comment = file.comment ? file.comment : "";
  const size = file.size === 0 ? "" : Math.round((file.size / 1048576) * 10) / 10 + " MB";
  const pages = file.pages === "" ? "" : file.pages;
  const datetime = new Date(file.modified_at * 1000).toLocaleDateString();
  const tags = file.tag ? file.tag : [];
  const [meEdit, setMeEdit] = useState<boolean>(false);

  // filesOrPagesEditに自分が含まれているかどうかを判定
  useEffect(() => {
    const meEdit = filesOrPagesEdit.some((f) => f.id === file.id) ? true : false;
    setMeEdit(meEdit);
  }, [filesOrPagesEdit, file]);

  // チェックボックスクリック時の処理: filesOrPagesEditに自分を追加/削除
  const onChangeCheckBox = useCallback(() => {
    if (meEdit) {
      setFilesOrPagesEdit(filesOrPagesEdit.filter((f) => f.id !== file.id));
    } else {
      setFilesOrPagesEdit([...filesOrPagesEdit, file as FileOrPage]);
    }
  }, [meEdit, file, filesOrPagesEdit, setFilesOrPagesEdit]);

  // ファイル名クリック時の処理:  フォルダの場合はフォルダリストを更新してフォルダ内に入る
  const onNameClick = useCallback(async () => {
    if (file.file) {
      const msgObj = {
        key: file.key,
        curPage: 1
      };
      localStorage.setItem(MESSAGE_KEY, JSON.stringify(msgObj));
      window.open(`/pdfview`, "_blank");

    } else if (file.folder) {
      console.log("folder clicked", file.folder);
      let foldersList = ["Home"];
      foldersList = foldersList.concat(file.folder.split("/"));
      setFolderList(foldersList);
    }
  }, [file, setFolderList]);

  // コメントクリック時の処理: filesOrPagesEditに自分を追加して、コメントモーダルを表示
  const onCommentClick = useCallback(() => {
    console.log("comment clicked", file.id);
    const dialogElement = document.getElementById("comment-modal") as HTMLDialogElement;
    if (dialogElement) {
      if (!meEdit) {
        setFilesOrPagesEdit([...filesOrPagesEdit, file as FileOrPage]);
      }
      dialogElement.showModal();
    }
  }, [file, meEdit, filesOrPagesEdit, setFilesOrPagesEdit]);

  // タグクリック時の処理: filesOrPagesEditに自分を追加して、タグモーダルを表示
  const onTagClick = useCallback(() => {
    console.log("tag clicked", file.id);
    const dialogElement = document.getElementById("tag-modal") as HTMLDialogElement;
    if (dialogElement) {
      if (!meEdit) {
        setFilesOrPagesEdit([...filesOrPagesEdit, file as FileOrPage]);
      }
      dialogElement.showModal();
    }
  }, [file, meEdit, filesOrPagesEdit, setFilesOrPagesEdit]);

  // ステータ進捗バー
  const statusProgressBar = () => {
    if (!file.file) {
      return null;
    } else if (file.status === "processed") {
      return <progress className="progress w-16 h-4 progress-accent" value="100" max="100"></progress>;
    } else if (file.status === "processing") {
      return <progress className="progress w-16 h-4 progress-accent" value="40" max="100"></progress>;
    } else if (!file.status) {
      return <progress className="progress w-16 h-4 progress-accent" value="10" max="100"></progress>;
    }
  };

  // console.log("file.id", file.id);
  return (
    <tr>
      <td className="w-16">
        <input type="checkbox" className="checkbox checkbox-primary" checked={meEdit} onChange={onChangeCheckBox} />
      </td>
      <td>
        <button className="flex items-center gap-3 w-min-18  w-max-md" onClick={onNameClick}>
          {file.file ? <img src="pdf.png" alt="Avatar Tailwind CSS Component" className="w-6 h-6" /> : null}
          <div className="text-left">{name}</div>
        </button>
      </td>
      <td className="flex align-middle, items-center w-min-12  w-max-md">
        {!file.file ? null : tags.length > 0 ? (
          tags.map((tag, index) => (
            <button
              key={index}
              className="mx-2 my-1 px-2 py-0 border-2 rounded-box border-accent text-white bg-accent"
              onClick={onTagClick}
            >
              {tag}
            </button>
          ))
        ) : (
          <button onClick={onTagClick} className="p-0 m-0 align-middle, items-center">
            <HiOutlineTag className="p-0 m-0 main-theme-reverse align-middle, items-center h-6 w-6"></HiOutlineTag>
          </button>
        )}
      </td>
      <td className="w-min-12 w-max-md" onClick={onCommentClick}>
        {comment}
      </td>
      <td className="w-12">{size}</td>
      <td className="w-12">{pages}</td>
      <td className="w-12">{datetime}</td>
      <td className="w-16">{statusProgressBar()}</td>
    </tr>
  );
};
