import { FilesTable } from "../files/files_table";
import { FilesUploadButton } from "../files/files_upload_btn";
import { FilesActionButton } from "../files/files_action_btn";
import { FilesSearchBox } from "../files/files_search_box";
import { FilesBreadCrumb } from "../files/files_bread_crumb";
import { useState, useCallback, useEffect } from "react";
import { FileOrPage } from "../../types/fileorpage";
import { SortCondition } from "../../types/sortcondition";
import { MyFile } from "../../types/file";
import { Project } from "../../types/project";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { filesFetch, filesSearch } from "../../axios/files_fetch";
import { CommentModal } from "../comment_modal";
import { TagModal } from "../tag_modal";
import { RenameModal } from "../rename_modal";
import { MoveModal } from "../move_modal";
import { t } from "i18next";

function Filesandfolders() {
  const curProject = useSelector<RootState, Project>((state: RootState) => state.currentProject);
  const gid = curProject.gid ? curProject.gid : "";

  const [filesOrPagesEdit, setFilesOrPagesEdit] = useState<FileOrPage[]>([]);
  const initialSortCondition: SortCondition = {
    sortBy: "name",
    nameOrder: false, // true: ascending, false: descending
    sizeOrder: false,
    pageOrder: false,
    modifiedOrder: false,
  };
  const [files, setFiles] = useState<MyFile[]>([]);
  const [folderList, setFolderList] = useState<string[]>(["Home"]);
  const [sortCondition, setSortCondition] = useState<SortCondition>(initialSortCondition);
  const [curPagination, setCurPagination] = useState<number>(1);
  const [totalPagination, setTotalPagination] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [term, setTerm] = useState<string>("");

  const fetchData = useCallback(async () => {
    if (!gid) {
      console.log("no gid set yet");
      return;
    }
    try {
      setIsLoading(true);
      let sortBool = false;
      if (sortCondition.sortBy === "name") {
        sortBool = sortCondition.nameOrder;
      } else if (sortCondition.sortBy === "size") {
        sortBool = sortCondition.sizeOrder;
      } else if (sortCondition.sortBy === "pages") {
        sortBool = sortCondition.pageOrder;
      } else if (sortCondition.sortBy === "modified_at") {
        sortBool = sortCondition.modifiedOrder;
      }
      console.log("new sort conditions:", sortCondition.sortBy, sortBool, "for gid", gid);
      const from = 10 * (curPagination - 1);
      let folder = folderList.slice(1).join("/");
      if (folder && !folder.endsWith("/")) {
        folder = folder + "/";
      }
      console.log("fetching files from", from, "folder", folder);
      const [files, total] = term
        ? await filesSearch(gid, sortCondition.sortBy, sortBool, from, folder, term, false)
        : await filesFetch(gid, sortCondition.sortBy, sortBool, from, folder, false);
      setFiles(files);
      setTotalPagination(Math.ceil(total / 10));
      console.log("totalPagination", Math.ceil(total / 10), total / 10);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }, [sortCondition, curPagination, gid, folderList, term]);

  useEffect(() => {
    fetchData();
  }, [sortCondition, curPagination, gid, folderList, term, fetchData]);

  // 外部クリックでドロップダウンを閉じる
  window.addEventListener("click", function (e) {
    document.querySelectorAll(".dropdown").forEach(function (dropdown) {
      if (!dropdown.contains(e.target as Node)) {
        const dropdownElement = dropdown as HTMLDetailsElement;
        dropdownElement.open = false;
      }
    });
  });
  return (
    <div>
      <div className="mb-8 font-bold main-theme-reverse text-2xl">{t("files.title")}</div>
      <div className="flex mb-8">
        <FilesActionButton filesOrPagesEdit={filesOrPagesEdit}></FilesActionButton>
        <FilesUploadButton></FilesUploadButton>
      </div>
      <div className="flex">
        <FilesBreadCrumb
          term={term}
          setTerm={setTerm}
          folderList={folderList}
          setFolderList={setFolderList}
        ></FilesBreadCrumb>
        <FilesSearchBox setTerm={setTerm}></FilesSearchBox>
      </div>
      {isLoading ? (
        <div className="w-full flex justify-center content-center h-8">
          <span className="loading loading-spinner loading-sm"></span>
        </div>
      ) : null}
      <FilesTable
        files={files}
        totalPagination={totalPagination}
        sortCondition={sortCondition}
        setSortCondition={setSortCondition}
        curPagination={curPagination}
        setCurPagination={setCurPagination}
        folderList={folderList}
        setFolderList={setFolderList}
        filesOrPagesEdit={filesOrPagesEdit}
        setFilesOrPagesEdit={setFilesOrPagesEdit}
      ></FilesTable>
      <RenameModal filesOrPages={filesOrPagesEdit}></RenameModal>
      <CommentModal filesOrPages={filesOrPagesEdit}></CommentModal>
      <TagModal fileBool={true} filesOrPages={filesOrPagesEdit}></TagModal>
      <MoveModal filesOrPages={filesOrPagesEdit}></MoveModal>
    </div>
  );
}

export default Filesandfolders;
