import { fetchAuthSession } from "aws-amplify/auth";
import { useEffect, useState, useRef, useCallback } from "react";
import { pagesSearch } from "../../axios/pages_search";
import { Page } from "../../types/page";
import { FileOrPage } from "../../types/fileorpage";
import { useSelector, useDispatch } from "react-redux";
import { Project } from "../../types/project";
import { Tags, setAllTags } from "../../types/tag";
import { RootState } from "../../store";
import { CommentModal } from "../comment_modal";
import { TagModal } from "../tag_modal";
import { PageContentModal } from "../pages/pages_content_modal";
import { PageStartEndButton } from "../pages/pages_start_end_btn";
import { PageLookForWithin } from "../pages/pages_look_for_within";
import { PageActoinButton } from "../pages/pages_action_btn";
import { PagesGrid } from "../pages/pages_grid";
import { PagesCheckAllBox } from "../pages/pages_check_all_box";
import { PageTagCondition } from "../pages/pages_tag_condition";
import { PagesFolderButton } from "../pages/pages_folder_btn";
import { PagesSortButton } from "../pages/pages_sort_btn";
import { AIChatOpenButton } from "../aichat/aichat_open_btn";
import { AIChat } from "../aichat/aichat";
// import { Chatbot } from "../chatbot/chatbot";
import { useTranslation } from "react-i18next";
import { tagsFetch } from "../../axios/tags_fetch";
import { ChatMessage } from "../../types/aichat";
// import "../bot.css";

function PageSearch() {
  const { t } = useTranslation();
  const curProject = useSelector<RootState, Project>((state: RootState) => state.currentProject);
  const gid = curProject.gid ? curProject.gid : "";
  const dispatch = useDispatch();
  const bottom = useRef(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [observer, setObserver] = useState<IntersectionObserver | null>(null);
  const [pages, setPages] = useState<Page[]>([]);
  const [filesOrPagesEdit, setFilesOrPagesEdit] = useState<FileOrPage[]>([]);
  const [numPages, setNumPages] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(-1);
  const [term, setTerm] = useState<string>("");
  const [termField, setTermField] = useState<string>("");
  const [sortBy, setSortBy] = useState<string>("Sort by Date Modified");
  const [pageStartEnd, setPageStartEnd] = useState<number[]>([0, 0]);
  const [tags, setTags] = useState<string[]>([]);
  const [searchFolder, setSearchFolder] = useState<string>("");
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [messages, setMessages] = useState<ChatMessage[]>([
    {
      message: "以下の質問に興味があればクリックしてください",
      sentTime: new Date().toLocaleTimeString(),
    },
    {
      message: "pythonで簡単な回帰分析",
      sentTime: new Date().toLocaleTimeString(),
      isTag: true,
    },
    {
      message: "pythonでスクレイピングの仕方",
      sentTime: new Date().toLocaleTimeString(),
      isTag: true,
    },
  ]);

  // allTagsの設定
  const fetchAllTags = useCallback(
    async (gid: string) => {
      try {
        const tags: Tags = await tagsFetch(gid);
        dispatch(setAllTags(tags));
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch]
  );
  useEffect(() => {
    fetchAllTags(gid);
  }, [gid, fetchAllTags]);

  const createResponseMessage = (): ChatMessage => {
    return {
      message: "なるほど",
      sentTime: new Date().toLocaleTimeString(),
      
    };
  };

  const handleSendMessage = (messageContent: string) => {
    const newMessage: ChatMessage = {
      message: messageContent,
      sentTime: new Date().toLocaleTimeString(),
      isOutgoing: true,
    };

    setMessages((prevMessages) => [...prevMessages, newMessage, createResponseMessage()]);
  };

  const handleMessageClick = (message: string) => {
    const userMessage: ChatMessage = {
      message: message,
      sentTime: new Date().toLocaleTimeString(),
      isOutgoing: true,
    };

    setMessages((prevMessages) => [...prevMessages, userMessage, createResponseMessage()]);
  };

  // isloading中又はnumPages == totalPagesの時、fetchDataを呼び出さない
  const pagesSearchGo = useCallback(async () => {
    if (isLoading) {
      console.log("fetchData called, but Loading already in progress");
      return;
    }
    if (numPages === totalPages) {
      console.log("fetchData called, but already numPages == totalPages");
      return;
    }

    setIsLoading(true);
    try {
      const session = await fetchAuthSession();
      const usersub = session.userSub ? session.userSub : "";
      console.log(usersub);
      console.log("numPages as From", numPages);
      let sortByInES = sortBy.includes("Relevance")
        ? "relevance"
        : sortBy.includes("Date Modified")
        ? "modified_at"
        : "file";
      let sortOrder = sortBy.includes("↓") ? true : false;

      let termFiledInES = termField === "Filename" ? "file" : termField === "Comment" ? "comment" : "content";
      let fuzziness = termField === "Context (Broad Match)" ? true : false;

      console.log("fetchData:", usersub, gid, sortByInES, sortOrder, numPages, term, termFiledInES, fuzziness);
      const [total_value, newPages] = await pagesSearch(
        usersub,
        gid,
        sortByInES,
        sortOrder,
        numPages,
        term,
        termFiledInES,
        fuzziness,
        pageStartEnd[0],
        pageStartEnd[1],
        tags,
        searchFolder
      );
      for (const page of newPages) {
        console.log(page.id);
      }
      setTotalPages(total_value);
      setPages((prevPages) => [...prevPages, ...newPages]);
    } catch (error) {
      console.error(error);
    }
  }, [numPages, totalPages, isLoading, gid, term, termField, sortBy, pageStartEnd, tags, searchFolder]);

  // pages更新完了を待っている
  useEffect(() => {
    console.log("page length:", pages.length);
    setNumPages(pages.length);
    setIsLoading(false);
  }, [pages]);

  // numPages == totalPagesの時、observerを解除する
  useEffect(() => {
    if (numPages === totalPages) {
      if (observer && bottom.current) {
        observer.unobserve(bottom.current);
      }
      setObserver(null);
      console.log("Observer removed");
    }
  }, [numPages, totalPages, observer]);

  // infinite scroll & fetchData
  // target=bottomが画面内に入ったらfetchDataを呼び出す
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        console.log("Loading more pages");
        pagesSearchGo();
      }
    });
    setObserver(observer);
    if (bottom.current) {
      observer.observe(bottom.current);
      console.log("Observer started");
    }
    return () => {
      if (bottom.current) {
        observer.unobserve(bottom.current);
      }
    };
  }, [pagesSearchGo]);

  // inputでEnterが押されたとき、termをセットして、pagesを初期化してfetchDataを呼び出す
  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const inputElement = document.getElementById("search_input") as HTMLInputElement;
      setTerm(inputElement.value);
      setPages([]);
      setNumPages(0);
      setTotalPages(-1);
    }
  }, []);

  // searchFieldが変更されたとき、termが空欄でなければ、pagesを初期化してfetchDataを呼び出す
  useEffect(() => {
    if (term) {
      setPages([]);
      setNumPages(0);
      setTotalPages(-1);
      handleKeyDown({ key: "Enter" } as React.KeyboardEvent<HTMLInputElement>);
    }
  }, [term, termField, handleKeyDown]);

  // sortBy, pageStartEnd, tags, searchFolderが変更されたとき、pagesを初期化してfetchDataを呼び出す
  useEffect(() => {
    setPages([]);
    setNumPages(0);
    setTotalPages(-1);
  }, [sortBy, pageStartEnd, tags, searchFolder]);

  // 外部クリックでドロップダウンを閉じる
  window.addEventListener("click", function (e) {
    document.querySelectorAll(".dropdown").forEach(function (dropdown) {
      if (!dropdown.contains(e.target as Node)) {
        const dropdownElement = dropdown as HTMLDetailsElement;
        dropdownElement.open = false;
      }
    });
  });

  useEffect(() => {
    const updateChatVisibility = () => {
      if (window.innerWidth <= 640) {
        setIsChatVisible(true);
      }
    };

    // 初期状態を画面サイズに応じて設定
    const initializeChatVisibility = () => {
      if (window.innerWidth <= 640) {
        setIsChatVisible(true);
      }
      // PCの場合、isChatVisibleの状態を保持する
    };

    initializeChatVisibility();
    window.addEventListener("resize", updateChatVisibility);

    return () => window.removeEventListener("resize", updateChatVisibility);
  }, []);

  return (
    <div className="flex h-screen bottom-0 overscroll-none">
      <div className="w-full p-4 transition-all duration-200">
        {/* <script type="text/javascript" src="https://chatbot.auriq.com/assets/modules/channel-web/inject.js"></script> */}
        {/* <Chatbot></Chatbot> */}
        <div className="flex justify-between">
          <h1 className="mb-8 font-bold main-theme-reverse text-base md:text-2xl">{t("pagesearch.title")}</h1>
          <div onClick={() => setIsChatVisible(!isChatVisible)}>
            <AIChatOpenButton />
          </div>
        </div>
        <PageLookForWithin handleKeyDown={handleKeyDown} setTermField={setTermField}></PageLookForWithin>
        <div className="mt-6 mx-4 flex items-center align-middle">
          <div className="font-bold text-base whitespace-nowrap">
            {totalPages === -1 ? "" : numPages + " / " + totalPages + " " + t("pagesearch.pages")}
          </div>
          <div className="ml-auto flex">
            <PageTagCondition tags={tags} setTags={setTags}></PageTagCondition>
            <PagesFolderButton
              gid={gid}
              searchFolder={searchFolder}
              setSearchFolder={setSearchFolder}
            ></PagesFolderButton>
            <PageStartEndButton pageStartEnd={pageStartEnd} setPageStartEnd={setPageStartEnd}></PageStartEndButton>
            <PagesSortButton setSortBy={setSortBy}></PagesSortButton>
          </div>
        </div>
        <div className="flex mt-6 mx-4 items-center align-middle">
          <PageActoinButton></PageActoinButton>
          <PagesCheckAllBox pages={pages} setFilesOrPagesEdit={setFilesOrPagesEdit}></PagesCheckAllBox>
          {filesOrPagesEdit.length > 0 ? (
            <div className="ml-4">
              {t("pagesearch.selected")}
              <span className="font-bold"> {filesOrPagesEdit.length}</span>
            </div>
          ) : null}
        </div>
        {isLoading ? (
          <div className="w-full flex justify-center content-center h-8">
            <span className="loading loading-spinner loading-sm"></span>
          </div>
        ) : null}
        <PagesGrid
          pages={pages}
          filesOrPagesEdit={filesOrPagesEdit}
          setFilesOrPagesEdit={setFilesOrPagesEdit}
          setTags={setTags}
        />
        <CommentModal filesOrPages={filesOrPagesEdit}></CommentModal>
        <TagModal fileBool={false} filesOrPages={filesOrPagesEdit}></TagModal>
        <PageContentModal filesOrPages={filesOrPagesEdit}></PageContentModal>
      </div>
      <div ref={bottom} />
      {isChatVisible && (
        <div className="fixed top-0 right-0 bottom-0 w-full md:w-1/3 bg-white border-l border-gray-300 shadow-lg z-50 transition-transform transform translate-x-0">
          <AIChat
            messages={messages}
            setIsChatVisible={setIsChatVisible}
            onSendMessage={handleSendMessage}
            onMessageClick={handleMessageClick}
          />
        </div>
      )}
    </div>
  );
}

export default PageSearch;
