import { createSlice } from "@reduxjs/toolkit";

export const sidebarSlice = createSlice({
  name: "lang", //match with the name of the slice in store.ts
  initialState: true,
  reducers: {
    setSidebar: (state, action) => {
      console.log("sidebar", action.payload);
      return action.payload.sidebar;
    },
  },
});

export const { setSidebar } = sidebarSlice.actions;
export const sidebar = sidebarSlice.reducer;
