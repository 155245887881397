import { HiChevronDown } from "react-icons/hi";
import { useRef, useCallback, useState, useEffect } from "react";
import { UploadFile } from "../../types/file";
import { filesCheck } from "../../axios/files_check";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { Project } from "../../types/project";
import { FilesUploadModal } from "../files/files_upload_modal";
import { FilesOverlappedModal } from "../files/files_overlapped_modal";
import { t } from "i18next";

export const FilesUploadButton = () => {
  const curProject = useSelector<RootState, Project>(
    (state: RootState) => state.currentProject
  );
  const gid = curProject.gid ? curProject.gid : "";
  const [fileList, setFileList] = useState<FileList | null>(null);
  const [folderBool, setFolderBool] = useState<boolean>(false);
  const [replace, setReplace] = useState<boolean | null>(null);
  const inputFile = useRef<HTMLInputElement>(null);
  const inputFolder = useRef<HTMLInputElement>(null);

  // ファイルアップロード条件(fileList, replace)をリセット
  const resetUploadCondition = () => {
    console.log("resetUploadCondition");
    setFileList(null);
    setReplace(null);
  };

  // ファイルアップロードモーダルを表示
  const showUploadModal = async () => {
    const dialogElement = document.getElementById(
      "upload-box"
    ) as HTMLDialogElement;
    if (dialogElement) {
      dialogElement.showModal();
    }
  };

  // 表示していないinputFileをクリック > ファイル選択ダイアログを表示
  const onInputFileClick = () => {
    console.log("onInputFileClick");
    if (inputFile.current) {
      setFolderBool(false);
      inputFile.current?.click();
    }
  };
  // 表示していないinputFolderをクリック > フォルダ選択ダイアログを表示
  const onInputFolderClick = () => {
    console.log("onInputFolderClick");
    if (inputFolder.current) {
      setFolderBool(true);
      inputFolder.current?.click();
    }
  };

  // ファイル選択時の処理
  const onFileOrFolderChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      console.log("onFileChange");
      if (!e.target.files) return;
      const fileList: FileList = e.target.files;

      // FileListから、UploadFilesに変換
      const uploadFiles: UploadFile[] = [];
      if (!fileList) return;
      for (let i = 0; i < fileList.length; i++) {
        const _file = fileList[i];
        const name = folderBool ? _file.webkitRelativePath : _file.name;
        const file: UploadFile = {
          file: name,
          size: _file.size,
          type: _file.type,
        };
        uploadFiles.push(file);
      }

      setFileList(fileList);
      // console.log(fileList);

      const overlapped = await filesCheck(uploadFiles, gid);
      if (overlapped) {
        // ファイルに重複があれば、モダルを表示
        const dialogElement = document.getElementById(
          "files-overlapped-modal"
        ) as HTMLDialogElement;
        if (dialogElement) {
          dialogElement.showModal();
        }
      } else {
        // upload_files_modalを表示
        showUploadModal();
      }
    },
    [setFileList, folderBool, gid]
  );

  // replaceが変更されたら、upload_files_modalを表示
  useEffect(() => {
    if (replace !== null) {
      showUploadModal();
    }
  }, [replace]);

  // inputFolderにdirectory属性を追加。直接記載できないため、ueEffectで追加
  useEffect(() => {
    if (inputFolder.current !== null) {
      inputFolder.current.setAttribute("directory", "");
      inputFolder.current.setAttribute("webkitdirectory", "");
    }
  }, [inputFolder]);

  return (
    <div>
      <details className="dropdown w-40 pr-4">
        <summary className="m-1 btn w-full px-4 main-theme">
          {t("files.upload")} <HiChevronDown></HiChevronDown>
        </summary>
        <ul className="p-2 shadow menu dropdown-content z-[1] w-full main-theme-reverse">
          <li onClick={onInputFileClick}>
            <a>{t("files.upload_files")}</a>
          </li>
          <li onClick={onInputFolderClick}>
            <a>{t("files.upload_folder")}</a>
          </li>
        </ul>
      </details>
      <form className="uploader" encType="multipart/form-data">
        <input
          type="file"
          id="file"
          ref={inputFile}
          style={{ display: "none" }}
          multiple
          onChange={onFileOrFolderChange}
          onClick={(event) => {
            (event.target as HTMLInputElement).value = ""; // 選択済みを  空にするため
          }}
        />
      </form>
      <form className="uploader" encType="multipart/form-data">
        <input
          type="file"
          id="folder"
          ref={inputFolder}
          style={{ display: "none" }}
          multiple
          onChange={onFileOrFolderChange}
          onClick={(event) => {
            (event.target as HTMLInputElement).value = ""; // 選択済みを  空にするため
          }}
        />
      </form>
      <FilesUploadModal
        fileList={fileList}
        folderBool={folderBool}
        replace={replace}
        resetUploadCondition={resetUploadCondition}
      ></FilesUploadModal>
      <FilesOverlappedModal
        setReplace={setReplace}
        resetUploadCondition={resetUploadCondition}
      ></FilesOverlappedModal>
    </div>
  );
};
