import { BooksRecommendCell } from "./books_recommend_cell";
import { HiChevronDown } from "react-icons/hi";
import { Page } from "../../types/page";
import { pagesRecommend } from "../../axios/pages_recommend";
import { closeDropdown } from "../../lib/close_dropdown";
import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { Project } from "../../types/project";

const TITLE_RECOMMEND = "オススメ本の中身";
const TITLE_REVIEW = "ユーザー「中身」レビュー";

export const BooksRecommend = ({ tags, numPages }: { tags: string[]; numPages: number }) => {
  const curProject = useSelector<RootState, Project>((state: RootState) => state.currentProject);
  const gid = curProject.gid ? curProject.gid : "";
  const [pages, setPages] = useState<Page[]>([]);
  const [title, setTitle] = useState<string>(TITLE_RECOMMEND);

  const fetchPagesRecommend = useCallback(async () => {
    setPages([]);
    const pagesFetched = await pagesRecommend(gid, "Python", !(title === TITLE_RECOMMEND));
    setPages(pagesFetched);
  }, [gid, title]);

  useEffect(() => {
    if (numPages > 0) {
      fetchPagesRecommend();
    } else {
      setPages([]);
    }
  }, [fetchPagesRecommend, numPages]);

  return (
    <div className="w-64">
      {numPages === 0 ? null : (
        <details className="dropdown mx-2 w-full">
          <summary className="btn w-full px-4 font-bold bg-white border-none shadow-none">
            <>
              {title}
              <HiChevronDown></HiChevronDown>
            </>
          </summary>
          <ul className="menu dropdown-content z-[1] w-full main-theme-reverse border-2">
            <li
              key="1"
              onClick={() => {
                setTitle(TITLE_RECOMMEND);
                closeDropdown();
              }}
            >
              <span>{TITLE_RECOMMEND}</span>
            </li>
            <li
              key="2"
              onClick={() => {
                setTitle(TITLE_REVIEW);
                closeDropdown();
              }}
            >
              <span>{TITLE_REVIEW}</span>
            </li>
          </ul>
        </details>
      )}
      <div className="mt-4 w-full">
        {pages.map((page, index) => (
          <BooksRecommendCell key={index} page={page} title={title} />
        ))}
      </div>
    </div>
  );
};
